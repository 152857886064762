
//PAGES
export const PAGES = {
    HOMEPAGE: '/dashboardv1',
    LOGIN: '/login',
    CONTROLPAGE: '/dashboardv2',
    USERSPAGE: '/dashboardv3',
    TAGESWERT_REPORT: '/dashboardv4',
    WP_LIST: '/dashboardv5',
    DOCUMENTSERVER: '/dashboardv7',
    TRENDS: '/dashboardv8',
    ADMINISTRATION: '/dashboardv9',
    HOWTO: '/dashboardv10',
    PRODUCT_MANAGER: '/dashboardv6',
    PORTAL_EMAIL: '/dashboardv11',
    REPORT_MANAGER: '/dashboardv12',
}

