import * as React from 'react';
import { connect } from 'react-redux';
import * as FirmaActions from '../../store/actions/firma.actions';
import * as UsersActions from '../../store/actions/users.actions';
import CardWithHeader from '../Common/CardWithHeader';
import FirmaRow from './FirmaRow';
import { MAIN_CONTENT_TYPES, BREADCRUMB_LEVELS, CONTROLBUTTON_TYPES, USERGROUP, TRANS,  DISPLAY} from '../../store/actions/general.actions';
import { MEMBERSHIP_TYPE, PORTAL_ACTIONS } from '../../store/identity/identity';
import ScrollUpButton from "react-scroll-up-button"
import { Fade } from 'reactstrap';
import '../../styles/trans.css';
import { AllFirmaTable_Props, AllFirmaTable_State, } from '../PortalTypes/PortalTypes';
import { FirmaObj, } from '../PortalTypes/PortalObjs';

//to use
//props.AllFirmaTable
class AllFirmaTable extends React.Component<AllFirmaTable_Props, AllFirmaTable_State> {

    constructor(props) {
        super(props); 
    }

    async componentDidMount() {
        //3 cases:  
        //(1) Super Admin => all companies
        //(2) Berater => all related companies 
        //(3) other: should not see a list of companies, but directly to list of plants
        if (this.props.user && this.props.user.isSuperAdmin) {
            await this.props.fetchAllFirma();
        }
        else if (this.props.user && this.props.user.SpecialRole === USERGROUP.IDs.CONSULTANT_USERGROUP_ID) {
            await this.props.fetchAllFirmaByUserID(this.props.user.UserID);
        } else {
            //should not see anything 
        }

        await this.props.getRelatedUserCount(this.props.user.UserID);
        console.log("all frima", this.props);

        this.setState({
            FirmaList: this.props.FirmaList,
            UsersCount: this.props.UsersCount
        });
    }

    componentWillMount() {
        console.log("alll firma", sessionStorage.getItem("eee"));
        if (sessionStorage.getItem(DISPLAY.BHKW.KEY) === null)
            sessionStorage.setItem(DISPLAY.BHKW.KEY, ''+DISPLAY.BHKW.OPEN);
        if (sessionStorage.getItem(DISPLAY.MODE.KEY) === null)
            sessionStorage.setItem(DISPLAY.MODE.KEY, DISPLAY.MODE.ROW);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.FirmaToDelete !== '') {
            var toDel = this.state.FirmaToDelete;
            //this.props.getRelatedUserCount(this.props.user.UserID)
            this.props.deleteFirma(this.state.FirmaToDelete);
            this.setState({
                FirmaToDelete: '',
                FirmaList: this.props.FirmaList.filter(x => x.guid !== toDel)
            });
        }
        if (prevState.searchText !== this.state.searchText) {

            this.setState({
                FirmaList: !this.props.FirmaList ? [] :
                    this.props.FirmaList.filter(x =>
                    (
                        (x.name && x.name.toUpperCase().includes(this.state.searchText.toUpperCase())) ||
                        (x.addressCity && x.addressCity.toUpperCase().includes(this.state.searchText.toUpperCase())) ||
                        (x.description && x.description.toUpperCase().includes(this.state.searchText.toUpperCase()))
                    )
                    )
            })
        }
    }

    state = {
        FirmaList: [] as FirmaObj[],
        FirmaToDelete: '',
        searchText: '',
        UsersCount: [] as string[],
    }

    onClick_Delete_Company = guid => {
        this.setState({
            FirmaToDelete: guid
        })
    }

    setSearchText = txt => { this.setState({ searchText: txt }) }

    render() {
        console.log("this.props.UsersCount", this.props);
        if (/*this.props.ContentType === MAIN_CONTENT_TYPES.FIRMA_LIST &&*/ this.state.FirmaList && this.state.UsersCount)
            return (
                <CardWithHeader
                    level={BREADCRUMB_LEVELS.FIRMA}
                    breadcrumb_object={{}}
                    controlButtons={{
                        contentType: MAIN_CONTENT_TYPES.FIRMA_LIST,
                        buttons: [CONTROLBUTTON_TYPES.ADD, CONTROLBUTTON_TYPES.SEARCH],
                        metaData: [
                            { title: this.props.t(TRANS.COMPANY.ADD_NEW), onClick: this.props.onClick_AddNew_Company, permissionID: PORTAL_ACTIONS.DASH1.COM.ADD, type: MEMBERSHIP_TYPE.RIGHT, plantID: null },
                            { title: this.props.t(TRANS.ACTIONS.SEARCH), onChange: this.setSearchText, permissionID: PORTAL_ACTIONS.DASH1.COM.SEARCH, type: MEMBERSHIP_TYPE.RIGHT, plantID: null }]
                    }}
                    activeCompanyTag={this.props.activeCompanyTag}
                    t={this.props.t}
                >
                    {
                        this.state.FirmaList
                            .sort((a: FirmaObj, b: FirmaObj) => a.name > b.name ? 1 : -1)
                            .map((record: FirmaObj, id: number) => {
                                if (record.guid)
                                    return (
                                        <Fade key={'Fade' + record.guid}>
                                            <FirmaRow
                                                key={record.guid}
                                                firma={record}
                                                changeClickedFirma_onClickPlants={this.props.changeClickedFirma_onClickPlants}
                                                onClick_Delete_Company={this.onClick_Delete_Company}
                                                onClick_View_Firma={this.props.onClick_View_Firma}
                                                user={this.props.user}
                                                userCount={this.state.UsersCount[record.guid]}
                                                t={this.props.t}
                                            />
                                        </Fade>
                                    );
                            })
                    }
                    <div>
                        <ScrollUpButton />
                    </div>
                </CardWithHeader>
            )
        else
            return (
                <CardWithHeader
                    level={BREADCRUMB_LEVELS.FIRMA}
                    controlButtons={{
                        contentType: MAIN_CONTENT_TYPES.FIRMA_LIST,
                        buttons: [CONTROLBUTTON_TYPES.ADD],
                        metaData: [{ title: this.props.t(TRANS.COMPANY.ADD_NEW), onClick: this.props.onClick_AddNew_Company }]
                    }}
                    t={this.props.t}
                >
                    <h4 className="blink_me">{this.props.t(TRANS.ACTIONS.LOADING)}</h4>
                </CardWithHeader>
            );
    };

}

function mapStateToProps(state, ownProps) {
    return {
        FirmaList: state.mainContent.mainContent && state.mainContent.mainContent.content,
        ContentType: state.mainContent.mainContent && state.mainContent.mainContent.type,
        user: ownProps.user,
        UsersCount: state.mainContent.mainContent && state.mainContent.mainContent.usersCount,
    }
}

function mapActionToProps(dispatch) {
    return {
        fetchAllFirma: () => dispatch(FirmaActions.firma_fetchall()),
        fetchAllFirmaByUserID: userID => dispatch(FirmaActions.firma_fetchall_ByUserID(userID)),
        deleteFirma: (guid) => dispatch(FirmaActions.firma_delete(guid)),
        getRelatedUserCount: userID => dispatch(UsersActions.user_fetchall_ByUserIDPermissions_forOneFirma_Count(userID, 'usersCount')),
    }
}

export default connect(mapStateToProps, mapActionToProps)(AllFirmaTable);

