import { baseUrl,  IDENTITY , SYSTEMUSER, baseAuthUrl} from "./general.actions";



export const login = user => ({
    type: IDENTITY.ACTIONS.LOGIN,
    payload: {
        url: baseAuthUrl + 'identity',
        data: user,
        onSuccess: IDENTITY.ACTIONS.LOGIN + IDENTITY.RESULTS.SUCCESSED,
        onError: IDENTITY.ACTIONS.LOGIN + IDENTITY.RESULTS.FAILED,
    }
})

export const register = user => ({
    type: IDENTITY.ACTIONS.REGISTER,
    payload: {
        url: baseUrl + SYSTEMUSER.TAB_NAME,
        method: 'post',
        data: user ,
        onSuccess: IDENTITY.ACTIONS.REGISTER + IDENTITY.RESULTS.SUCCESSED,
        onError: IDENTITY.ACTIONS.REGISTER + IDENTITY.RESULTS.FAILED
    }
})

export const insertIntoEventLogs = (country, ip, username, stat) => ({
    type: IDENTITY.ACTIONS.ADD_EVENT_LOG,
    payload: {
        url: baseAuthUrl + "Identity/" + country + "/" + ip + "/" + username + "/" + stat,
        method: "get",
        onSuccess: IDENTITY.ACTIONS.ADD_EVENT_LOG + IDENTITY.RESULTS.SUCCESSED,
        onError: IDENTITY.ACTIONS.ADD_EVENT_LOG + IDENTITY.RESULTS.FAILED,
    }
})