import {
    FIRMA, API, MAIN_CONTENT_TYPES, PLANT, PLANTPART, MAINTENANCEPLAN, DEVICE, DATAPOINT, PRODUCT, MAINTENANCEPLANTASK, USER_MAINTENANCEPLAN, SYSTEMUSER,
    USERGROUP, RIGHT, ROLE, USERGROUP_ROLE, USERGROUP_RIGHT, USER_USERGROUP_PLANT, PROTOCOL, REPORT_PDF, COMPONENTTYPE, TARGETACTUALSUMMARY, FILESERVER,
    SAVEDDAILYREPORT, SAVEDTRENDREPORT, PREDEFINEDMAINTENANCEPLAN, PREDEFINEDMAINTENANCEPLANTASK, HOWTO, MAIL, IDENTITY, REPORT, REPORT_DATAPOINT, REPORT_SET, REPORT_SET_DATAPOINT,
} from "../actions/general.actions";
import { release } from "os";
import { stat } from "fs";
import { report } from "process";
import api from "../middlewares/api.middleware";

const initialState = {
    type: '',
    content : []
}

//FIRMA
const FIRMA_FETCHALL_SUCC = FIRMA.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const FIRMA_FETCHALL_FAIL = FIRMA.ACTION_TYPES.FETCH_ALL + API.FAILED;
const FIRMA_DELETE_SUCC = FIRMA.ACTION_TYPES.DELETE + API.SUCCESSED;
const FIRMA_DELETE_FAIL = FIRMA.ACTION_TYPES.DELETE + API.FAILED;
const FIRMA_CREATE_SUCC = FIRMA.ACTION_TYPES.CREATE + API.SUCCESSED;
const FIRMA_CREATE_FAIL = FIRMA.ACTION_TYPES.CREATE + API.FAILED;
const FIRMA_GET_MP_STATISTICS_SUCC = FIRMA.ACTION_TYPES.GET_FIRMA_MP_STATISTICS + API.SUCCESSED;
const FIRMA_GET_MP_STATISTICS_FAIL = FIRMA.ACTION_TYPES.GET_FIRMA_MP_STATISTICS + API.FAILED;
const FIRMA_FETCH_BY_GUID_SUCC = FIRMA.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const FIRMA_FETCH_BY_GUID_FAIL = FIRMA.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;
const FIRMA_EDIT_SUCC = FIRMA.ACTION_TYPES.UPDATE + API.SUCCESSED;
const FIRMA_EDIT_FAIL = FIRMA.ACTION_TYPES.UPDATE + API.FAILED;
const FIRMA_FETCH_ALL_FILES_SUCC = FIRMA.ACTION_TYPES.FETCH_ALL_FILES + API.SUCCESSED;
const FIRMA_FETCH_ALL_FILES_FAIL = FIRMA.ACTION_TYPES.FETCH_ALL_FILES + API.FAILED;
const FIRMA_FETCH_NAMES_DICTIONARY_SUCC = FIRMA.ACTION_TYPES.FETCH_NAMES_DICTIONARY + API.SUCCESSED;
const FIRMA_FETCH_NAMES_DICTIONARY_FAIL = FIRMA.ACTION_TYPES.FETCH_NAMES_DICTIONARY + API.FAILED;

//PLANT
const PLANT_FETCHALL_SUCC = PLANT.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const PLANT_FETCHALL_FAIL = PLANT.ACTION_TYPES.FETCH_ALL + API.FAILED;
const PLANT_FETCH_ALL_BY_FIRMA_ID_SUCC = PLANT.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID + API.SUCCESSED;
const PLANT_FETCH_ALL_BY_FIRMA_ID_FAIL = PLANT.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID + API.FAILED;
const PLANT_CREATE_SUCC = PLANT.ACTION_TYPES.CREATE + API.SUCCESSED;
const PLANT_CREATE_FAIL = PLANT.ACTION_TYPES.CREATE + API.FAILED;
const PLANT_FETCH_BGANAMES_AND_IDS_SUCC = PLANT.ACTION_TYPES.FETCH_BGANAME_AND_IDS + API.SUCCESSED;
const PLANT_FETCH_BGANAMES_AND_IDS_FAIL = PLANT.ACTION_TYPES.FETCH_BGANAME_AND_IDS + API.FAILED;
const PLANT_DELETE_SUCC = PLANT.ACTION_TYPES.DELETE + API.SUCCESSED;
const PLANT_DELETE_FAIL = PLANT.ACTION_TYPES.DELETE + API.FAILED;
const PLANT_FETCH_BY_GUID_SUCC = PLANT.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const PLANT_FETCH_BY_GUID_FAIL = PLANT.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;
const PLANT_EDIT_SUCC = PLANT.ACTION_TYPES.UPDATE + API.SUCCESSED;
const PLANT_EDIT_FAIL = PLANT.ACTION_TYPES.UPDATE + API.FAILED;
const PLANT_FETCH_ALL_FILES_SUCC = PLANT.ACTION_TYPES.FETCH_ALL_FILES + API.SUCCESSED;
const PLANT_FETCH_ALL_FILES_FAIL = PLANT.ACTION_TYPES.FETCH_ALL_FILES + API.FAILED;
const PLANT_ADD_PLANT_TO_RTIP_SUCC = PLANT.ACTION_TYPES.ADD_PLANT_TO_RTIP + API.SUCCESSED;
const PLANT_ADD_PLANT_TO_RTIP_FAIL = PLANT.ACTION_TYPES.ADD_PLANT_TO_RTIP + API.FAILED;
const PLANT_DELETE_PLANT_FROM_RTIP_SUCC = PLANT.ACTION_TYPES.DELETE_PLANT_FROM_RTIP + API.SUCCESSED;
const PLANT_DELETE_PLANT_FROM_RTIP_FAIL = PLANT.ACTION_TYPES.DELETE_PLANT_FROM_RTIP + API.FAILED;
const PLANT_FETCH_DIFF_PROZESSWRET_PW_ALLE_SUCC = PLANT.ACTION_TYPES.FETCH_DIFF_PROZESSWERT_PW_ALLE + API.SUCCESSED;
const PLANT_FETCH_DIFF_PROZESSWRET_PW_ALLE_FAIL = PLANT.ACTION_TYPES.FETCH_DIFF_PROZESSWERT_PW_ALLE + API.FAILED;
const PLANT_FETCH_DIFF_BETRIEBSSTUNDEN_BS_ALLE_SUCC = PLANT.ACTION_TYPES.FETCH_DIFF_BETRIEBSSTUNDEN_BS_ALLE + API.SUCCESSED;
const PLANT_FETCH_DIFF_BETRIEBSSTUNDEN_BS_ALLE_FAIL = PLANT.ACTION_TYPES.FETCH_DIFF_BETRIEBSSTUNDEN_BS_ALLE + API.FAILED;
const PLANT_FETCH_ALL_PW_ALLE_FROM_RTIP_SUCC = PLANT.ACTION_TYPES.FETCH_ALL_PW_ALLE_FROM_RTIP + API.SUCCESSED;
const PLANT_FETCH_ALL_PW_ALLE_FROM_RTIP_FAIL = PLANT.ACTION_TYPES.FETCH_ALL_PW_ALLE_FROM_RTIP + API.FAILED;
const PLANT_FETCH_ALL_BS_ALLE_FROM_RTIP_SUCC = PLANT.ACTION_TYPES.FETCH_ALL_BS_ALLE_FROM_RTIP + API.SUCCESSED;
const PLANT_FETCH_ALL_BS_ALLE_FROM_RTIP_FAIL = PLANT.ACTION_TYPES.FETCH_ALL_BS_ALLE_FROM_RTIP + API.FAILED;
const PLANT_FETCH_ALL_RTIP_PW_HR_SUCC = PLANT.ACTION_TYPES.FETCH_ALL_RTIP_PW_HR + API.SUCCESSED;
const PLANT_FETCH_ALL_RTIP_PW_HR_FAIL = PLANT.ACTION_TYPES.FETCH_ALL_RTIP_PW_HR + API.FAILED;
const PLANT_FETCH_ALL_RTIP_TAB_BW_SUCC = PLANT.ACTION_TYPES.FETCH_ALL_RTIP_TAB_BW + API.SUCCESSED;
const PLANT_FETCH_ALL_RTIP_TAB_BW_FAIL = PLANT.ACTION_TYPES.FETCH_ALL_RTIP_TAB_BW + API.FAILED;
const PLANT_FETCH_ALL_RTIP_PW_NOT_HR_SUCC = PLANT.ACTION_TYPES.FETCH_ALL_RTIP_PW_NOT_HR + API.SUCCESSED;
const PLANT_FETCH_ALL_RTIP_PW_NOT_HR_FAIL = PLANT.ACTION_TYPES.FETCH_ALL_RTIP_PW_NOT_HR + API.FAILED;
const PLANT_ADD_NEW_PW_HR_2_RTIP_SUCC = PLANT.ACTION_TYPES.ADD_NEW_PW_HR_2_RTIP + API.SUCCESSED;
const PLANT_ADD_NEW_PW_HR_2_RTIP_FAIL = PLANT.ACTION_TYPES.ADD_NEW_PW_HR_2_RTIP + API.FAILED;
const PLANT_ADD_NEW_TAB_BW_2_RTIP_SUCC = PLANT.ACTION_TYPES.ADD_NEW_TAB_BW_2_RTIP + API.SUCCESSED;
const PLANT_ADD_NEW_TAB_BW_2_RTIP_FAIL = PLANT.ACTION_TYPES.ADD_NEW_TAB_BW_2_RTIP + API.FAILED;
const PLANT_ADD_NEW_PW_NOT_HR_2_RTIP_SUCC = PLANT.ACTION_TYPES.ADD_NEW_PW_NOT_HR_2_RTIP + API.SUCCESSED;
const PLANT_ADD_NEW_PW_NOT_HR_2_RTIP_FAIL = PLANT.ACTION_TYPES.ADD_NEW_PW_NOT_HR_2_RTIP + API.FAILED;
const PLANT_ADD_NEW_KEYVALUE2_AND_INI_PWREAL_2_RTIP_SUCC = PLANT.ACTION_TYPES.ADD_NEW_KEYVALUE2_AND_INI_PWREAL_2_RTIP + API.SUCCESSED;
const PLANT_ADD_NEW_KEYVALUE2_AND_INI_PWREAL_2_RTIP_FAIL = PLANT.ACTION_TYPES.ADD_NEW_KEYVALUE2_AND_INI_PWREAL_2_RTIP + API.FAILED;
const PLANT_ADD_NEW_BW_KEYVALUE_2_RTIP_SUCC = PLANT.ACTION_TYPES.ADD_NEW_BW_KEYVALUE2_2_RTIP + API.SUCCESSED;
const PLANT_ADD_NEW_BW_KEYVALUE_2_RTIP_FAIL = PLANT.ACTION_TYPES.ADD_NEW_BW_KEYVALUE2_2_RTIP + API.FAILED;
const PLANT_ADD_NEW_PW_2_RTIP_PW_ALLE_SUCC = PLANT.ACTION_TYPES.ADD_NEW_PW_TO_RTIP_PW_ALLE + API.SUCCESSED;
const PLANT_ADD_NEW_PW_2_RTIP_PW_ALLE_FAIL = PLANT.ACTION_TYPES.ADD_NEW_PW_TO_RTIP_PW_ALLE + API.FAILED;
const PLANT_ADD_NEW_BS_2_RTIP_BW_ALLE_SUCC = PLANT.ACTION_TYPES.ADD_NEW_BS_TO_RTIP_BW_ALLE + API.SUCCESSED;
const PLANT_ADD_NEW_BS_2_RTIP_BW_ALLE_FAIL = PLANT.ACTION_TYPES.ADD_NEW_BS_TO_RTIP_BW_ALLE + API.FAILED;
const PLANT_FETCH_NAMES_DICTIONARY_SUCC = PLANT.ACTION_TYPES.FETCH_NAMES_DICTIONARY + API.SUCCESSED;
const PLANT_FETCH_NAMES_DICTIONARY_FAIL = PLANT.ACTION_TYPES.FETCH_NAMES_DICTIONARY + API.FAILED;

//PLANTPART
const PLANTPART_FETCHALL_SUCC = PLANTPART.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const PLANTPART_FETCHALL_FAIL = PLANTPART.ACTION_TYPES.FETCH_ALL + API.FAILED;
const PLANTPART_FETCH_ALL_BY_PLANT_ID_SUCC = PLANTPART.ACTION_TYPES.FETCH_ALL_BY_PLANT_ID + API.SUCCESSED;
const PLANTPART_FETCH_ALL_BY_PLANT_ID_FAIL = PLANTPART.ACTION_TYPES.FETCH_ALL_BY_PLANT_ID + API.FAILED;
const PLANTPART_CREATE_SUCC = PLANTPART.ACTION_TYPES.CREATE + API.SUCCESSED;
const PLANTPART_CREATE_FAIL = PLANTPART.ACTION_TYPES.CREATE + API.FAILED;
const PLANTPART_DELETE_SUCC = PLANTPART.ACTION_TYPES.DELETE + API.SUCCESSED;
const PLANTPART_DELETE_FAIL = PLANTPART.ACTION_TYPES.DELETE + API.FAILED;
const PLANTPART_FETCH_BY_GUID_SUCC = PLANTPART.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const PLANTPART_FETCH_BY_GUID_FAIL = PLANTPART.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;
const PLANTPART_EDIT_SUCC = PLANTPART.ACTION_TYPES.UPDATE + API.SUCCESSED;
const PLANTPART_EDIT_FAIL = PLANTPART.ACTION_TYPES.UPDATE + API.FAILED;
const PLANTPART_FETCH_DETAILS_TO_DISPALY_SUCC = PLANTPART.ACTION_TYPES.FETCH_DETAILS_TO_DISPALY + API.SUCCESSED;
const PLANTPART_FETCH_DETAILS_TO_DISPALY_FAIL = PLANTPART.ACTION_TYPES.FETCH_DETAILS_TO_DISPALY + API.FAILED;
const PLANTPART_FETCH_ALL_FILES_SUCC = PLANTPART.ACTION_TYPES.FETCH_ALL_FILES + API.SUCCESSED;
const PLANTPART_FETCH_ALL_FILES_FAIL = PLANTPART.ACTION_TYPES.FETCH_ALL_FILES + API.FAILED;

//PRE MAINTENANCE PLAN
const PREMAINTENANCEPLAN_FETCH_ALL_BY_PRODUCT_GUID_SUCC = PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_BY_PRODUCT_GUID + API.SUCCESSED;
const PREMAINTENANCEPLAN_FETCH_ALL_BY_PRODUCT_GUID_FAIL = PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_BY_PRODUCT_GUID + API.FAILED;
const PREMAINTENANCEPLAN_CREATE_SUCC = PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.CREATE + API.SUCCESSED;
const PREMAINTENANCEPLAN_CREATE_FAIL = PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.CREATE + API.FAILED;
const PREMAINTENANCEPLAN_DELETE_SUCC = PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.DELETE + API.SUCCESSED;
const PREMAINTENANCEPLAN_DELETE_FAIL = PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.DELETE + API.FAILED;
const PREMAINTENANCEPLAN_EDIT_SUCC = PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.UPDATE + API.SUCCESSED;
const PREMAINTENANCEPLAN_EDIT_FAIL = PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.UPDATE + API.FAILED;
const PREMAINTENANCEPLAN_FETCH_BY_GUID_SUCC = PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const PREMAINTENANCEPLAN_FETCH_BY_GUID_FAIL = PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;

//MAINTENANCE PLAN
const MAINTENANCEPLAN_FETCHALL_SUCC = MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const MAINTENANCEPLAN_FETCHALL_FAIL = MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL + API.FAILED;
const MAINTENANCEPLAN_FETCH_ALL_BY_PLANTPART_ID_SUCC = MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_MAINTENANCEPLAN_BY_PLANTPART_ID + API.SUCCESSED;
const MAINTENANCEPLAN_FETCH_ALL_BY_PLANTPART_ID_FAIL = MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_MAINTENANCEPLAN_BY_PLANTPART_ID + API.FAILED;
const MAINTENANCEPLAN_FETCH_ALL_BY_PLANT_ID_SUCC = MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_MAINTENANCEPLAN_BY_PLANT_ID + API.SUCCESSED;
const MAINTENANCEPLAN_FETCH_ALL_BY_PLANT_ID_FAIL = MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_MAINTENANCEPLAN_BY_PLANT_ID + API.FAILED;
const MAINTENANCEPLAN_CREATE_SUCC = MAINTENANCEPLAN.ACTION_TYPES.CREATE + API.SUCCESSED;
const MAINTENANCEPLAN_CREATE_FAIL = MAINTENANCEPLAN.ACTION_TYPES.CREATE + API.FAILED;
const MAINTENANCEPLAN_CREATE_AUTO_SUCC = MAINTENANCEPLAN.ACTION_TYPES.CREATE_AUTO + API.SUCCESSED;
const MAINTENANCEPLAN_CREATE_AUTO_FAIL = MAINTENANCEPLAN.ACTION_TYPES.CREATE_AUTO + API.FAILED;
const MAINTENANCEPLAN_FETCH_ALL_BY_DEVICE_ID_SUCC = MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_MAINTENANCEPLAN_BY_DEVICE_ID + API.SUCCESSED;
const MAINTENANCEPLAN_FETCH_ALL_BY_DEVICE_ID_FAIL = MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_MAINTENANCEPLAN_BY_DEVICE_ID + API.FAILED;
const MAINTENANCEPLAN_FETCH_MP_BY_GUID_SUCC = MAINTENANCEPLAN.ACTION_TYPES.FETCH_MAINTENANCEPLAN_BY_GUID + API.SUCCESSED;
const MAINTENANCEPLAN_FETCH_MP_BY_GUID_FAIL = MAINTENANCEPLAN.ACTION_TYPES.FETCH_MAINTENANCEPLAN_BY_GUID + API.FAILED;
const MAINTENANCEPLAN_EDIT_SUCC = MAINTENANCEPLAN.ACTION_TYPES.UPDATE + API.SUCCESSED;
const MAINTENANCEPLAN_EDIT_FAIL = MAINTENANCEPLAN.ACTION_TYPES.UPDATE + API.FAILED;
const MAINTENANCEPLAN_DELETE_FROM_TABLE_SUCC = MAINTENANCEPLAN.ACTION_TYPES.DELETE_FROM_TABLE + API.SUCCESSED;
const MAINTENANCEPLAN_DELETE_FROM_TABLE_FAIL = MAINTENANCEPLAN.ACTION_TYPES.DELETE_FROM_TABLE + API.FAILED;
const MAINTENANCEPLAN_DELETE_FROM_VIEW_SUCC = MAINTENANCEPLAN.ACTION_TYPES.DELETE_FROM_VIEW + API.SUCCESSED;
const MAINTENANCEPLAN_DELETE_FROM_VIEW_FAIL = MAINTENANCEPLAN.ACTION_TYPES.DELETE_FROM_VIEW + API.FAILED;


//USER_MAINTENANCEPLAN 
const USER_MAINTENANCEPLAN_CREATE_LIST_SUCC = USER_MAINTENANCEPLAN.ACTION_TYPES.CREATE_LIST + API.SUCCESSED;
const USER_MAINTENANCEPLAN_CREATE_LIST_FAIL = USER_MAINTENANCEPLAN.ACTION_TYPES.CREATE_LIST + API.FAILED;
const USER_MAINTENANCEPLAN_FETCH_BY_MP_ID_SUCC = USER_MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_BY_MAINTENANCEPLAN_ID + API.SUCCESSED;
const USER_MAINTENANCEPLAN_FETCH_BY_MP_ID_FAIL = USER_MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_BY_MAINTENANCEPLAN_ID + API.FAILED;
const USER_MAINTENANCEPLAN_DELETE_LIST_SUCC = USER_MAINTENANCEPLAN.ACTION_TYPES.DELETE_LIST + API.SUCCESSED;
const USER_MAINTENANCEPLAN_DELETE_LIST_FAIL = USER_MAINTENANCEPLAN.ACTION_TYPES.DELETE_LIST + API.FAILED;

//MAINTENANCE PLAN TASK
const MAINTENANCEPLANTASK_CREATE_LIST_SUCC = MAINTENANCEPLANTASK.ACTION_TYPES.CREATE_LIST + API.SUCCESSED;
const MAINTENANCEPLANTASK_CREATE_LIST_FAIL = MAINTENANCEPLANTASK.ACTION_TYPES.CREATE_LIST + API.FAILED;
const MAINTENANCEPLANTASK_DELETE_LIST_SUCC = MAINTENANCEPLANTASK.ACTION_TYPES.DELETE_LIST + API.SUCCESSED;
const MAINTENANCEPLANTASK_DELETE_LIST_FAIL = MAINTENANCEPLANTASK.ACTION_TYPES.DELETE_LIST + API.FAILED;
const MAINTENANCEPLANTASK_TOGGLE_ISDONE_SUCC = MAINTENANCEPLANTASK.ACTION_TYPES.TOGGLE_ISDONE + API.SUCCESSED;
const MAINTENANCEPLANTASK_TOGGLE_ISDONE_FAIL = MAINTENANCEPLANTASK.ACTION_TYPES.TOGGLE_ISDONE + API.FAILED;

//PREMAINTENANCE PLAN TASK 
const PREMAINTENANCEPLANTASK_CREATE_LIST_SUCC = PREDEFINEDMAINTENANCEPLANTASK.ACTION_TYPES.CREATE_LIST + API.SUCCESSED;
const PREMAINTENANCEPLANTASK_CREATE_LIST_FAIL = PREDEFINEDMAINTENANCEPLANTASK.ACTION_TYPES.CREATE_LIST + API.FAILED;
const PREMAINTENANCEPLANTASK_DELETE_LIST_SUCC = PREDEFINEDMAINTENANCEPLANTASK.ACTION_TYPES.DELETE_LIST + API.SUCCESSED;
const PREMAINTENANCEPLANTASK_DELETE_LIST_FAIL = PREDEFINEDMAINTENANCEPLANTASK.ACTION_TYPES.DELETE_LIST + API.FAILED;
const PREMAINTENANCEPLANTASK_GET_ALL_BY_PREDEFINEDMAINTENANCEPLAN_GUID_SUCC = PREDEFINEDMAINTENANCEPLANTASK.ACTION_TYPES.GET_ALL_BY_PREDEFINEDMAINTENANCEPLAN_GUID + API.SUCCESSED;
const PREMAINTENANCEPLANTASK_GET_ALL_BY_PREDEFINEDMAINTENANCEPLAN_GUID_FAIL = PREDEFINEDMAINTENANCEPLANTASK.ACTION_TYPES.GET_ALL_BY_PREDEFINEDMAINTENANCEPLAN_GUID + API.FAILED;

//DEVICE
const DEVICE_FETCHALL_SUCC = DEVICE.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const DEVICE_FETCHALL_FAIL = DEVICE.ACTION_TYPES.FETCH_ALL + API.FAILED;
const DEVICE_FETCH_ALL_DEVICE_BY_PLANTPART_ID_SUCC = DEVICE.ACTION_TYPES.FETCH_ALL_DEVICE_BY_PLANTPART_ID + API.SUCCESSED;
const DEVICE_FETCH_ALL_DEVICE_BY_PLANTPART_ID_FAIL = DEVICE.ACTION_TYPES.FETCH_ALL_DEVICE_BY_PLANTPART_ID + API.FAILED;
const DEVICE_CREATE_SUCC = DEVICE.ACTION_TYPES.CREATE + API.SUCCESSED;
const DEVICE_CREATE_FAIL = DEVICE.ACTION_TYPES.CREATE + API.FAILED;
const DEVICE_DELETE_SUCC = DEVICE.ACTION_TYPES.DELETE + API.SUCCESSED;
const DEVICE_DELETE_FAIL = DEVICE.ACTION_TYPES.DELETE + API.FAILED;
const DEVICE_FETCH_BY_GUID_SUCC = DEVICE.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const DEVICE_FETCH_BY_GUID_FAIL = DEVICE.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;
const DEVICE_EDIT_SUCC = DEVICE.ACTION_TYPES.UPDATE + API.SUCCESSED;
const DEVICE_EDIT_FAIL = DEVICE.ACTION_TYPES.UPDATE + API.FAILED;
const DEVICE_FETCH_ALL_FILES_SUCC = DEVICE.ACTION_TYPES.FETCH_ALL_FILES + API.SUCCESSED;
const DEVICE_FETCH_ALL_FILES_FAIL = DEVICE.ACTION_TYPES.FETCH_ALL_FILES + API.FAILED;

//DATAPOINT
const DATAPOINT_FETCH_ALL_DATAPOINT_BY_PLANTPART_ID_SUCC = DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_PLANTPART_ID + API.SUCCESSED;
const DATAPOINT_FETCH_ALL_DATAPOINT_BY_PLANTPART_ID_FAIL = DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_PLANTPART_ID + API.FAILED;
const DATAPOINT_FETCH_ALL_DATAPOINT_BY_DEVICE_ID_SUCC = DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_DEVICE_ID + API.SUCCESSED;
const DATAPOINT_FETCH_ALL_DATAPOINT_BY_DEVICE_ID_FAIL = DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_DEVICE_ID + API.FAILED;
const DATAPOINT_FETCH_ALL_DATAPOINT_BY_PLANT_ID_SUCC = DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_PLANT_ID + API.SUCCESSED;
const DATAPOINT_FETCH_ALL_DATAPOINT_BY_PLANT_ID_FAIL = DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_PLANT_ID + API.FAILED;
const DATAPOINT_CREATE_SUCC = DATAPOINT.ACTION_TYPES.CREATE + API.SUCCESSED;
const DATAPOINT_CREATE_FAIL = DATAPOINT.ACTION_TYPES.CREATE + API.FAILED;
const DATAPOINT_FETCH_RELATED_RTIP_DATAPOINT_SUCC = DATAPOINT.ACTION_TYPES.FETCH_RELATED_RTIP_DATAPOINT + API.SUCCESSED;
const DATAPOINT_FETCH_RELATED_RTIP_DATAPOINT_FAIL = DATAPOINT.ACTION_TYPES.FETCH_RELATED_RTIP_DATAPOINT + API.FAILED;
const DATAPOINT_DELETE_SUCC = DATAPOINT.ACTION_TYPES.DELETE + API.SUCCESSED;
const DATAPOINT_DELETE_FAIL = DATAPOINT.ACTION_TYPES.DELETE + API.FAILED;
const DATAPOINT_FETCH_BY_GUID_SUCC = DATAPOINT.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const DATAPOINT_FETCH_BY_GUID_FAIL = DATAPOINT.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;
const DATAPOINT_EDIT_SUCC = DATAPOINT.ACTION_TYPES.UPDATE + API.SUCCESSED;
const DATAPOINT_EDIT_FAIL = DATAPOINT.ACTION_TYPES.UPDATE + API.FAILED;
const DATAPOINT_GET_OPERATING_HOURS_SUCC = DATAPOINT.ACTION_TYPES.GET_OPERATING_HOURS_FOR_DATAPOINT + API.SUCCESSED;
const DATAPOINT_GET_OPERATING_HOURS_FAIL = DATAPOINT.ACTION_TYPES.GET_OPERATING_HOURS_FOR_DATAPOINT + API.FAILED;
const DATAPOINT_GET_VALUES_TO_DISPLAY_SUCC = DATAPOINT.ACTION_TYPES.GET_DATAPOINTS_TO_DISPLAY + API.SUCCESSED;
const DATAPOINT_GET_VALUES_TO_DISPLAY_FAIL = DATAPOINT.ACTION_TYPES.GET_DATAPOINTS_TO_DISPLAY + API.FAILED;
const DATAPOINT_TOGGLE_IS_DISPLAY_SUCC = DATAPOINT.ACTION_TYPES.TOGGLE_IS_DISPLAY + API.SUCCESSED;
const DATAPOINT_TOGGLE_IS_DISPLAY_FAIL = DATAPOINT.ACTION_TYPES.TOGGLE_IS_DISPLAY + API.FAILED;
const DATAPOINT_GET_DAILYVALUES_BETWEENTWODATES_SUCC = DATAPOINT.ACTION_TYPES.GET_DAILYVALUES_BETWEENTWODATES + API.SUCCESSED;
const DATAPOINT_GET_DAILYVALUES_BETWEENTWODATES_FAIL = DATAPOINT.ACTION_TYPES.GET_DAILYVALUES_BETWEENTWODATES + API.FAILED;
const DATAPOINT_GET_DAILYVALUES_BETWEENTWODATES_FOR_DPLIST_SUCC = DATAPOINT.ACTION_TYPES.GET_DAILYVALUES_BETWEENTWODATES_FOR_DPLIST + API.SUCCESSED;
const DATAPOINT_GET_DAILYVALUES_BETWEENTWODATES_FOR_DPLIST_FAIL = DATAPOINT.ACTION_TYPES.GET_DAILYVALUES_BETWEENTWODATES_FOR_DPLIST + API.FAILED;
const DATAPOINT_GET_TRENDVALUES_BETWEENTWODATES_FOR_DPLIST_SUCC = DATAPOINT.ACTION_TYPES.GET_TRENDVALUES_BETWEENTWODATES_FOR_DPLIST + API.SUCCESSED;
const DATAPOINT_GET_TRENDVALUES_BETWEENTWODATES_FOR_DPLIST_FAIL = DATAPOINT.ACTION_TYPES.GET_TRENDVALUES_BETWEENTWODATES_FOR_DPLIST + API.FAILED;
const DATAPOINT_FETCH_ALL_DATAPOINT_BY_REPORT_GUID_A_OR_G_SUCC = DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_REPORT_GUID_A_OR_G + API.SUCCESSED;
const DATAPOINT_FETCH_ALL_DATAPOINT_BY_REPORT_GUID_A_OR_G_FAIL = DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_REPORT_GUID_A_OR_G + API.FAILED;

//PRODUCT
const PRODUCT_FETCHALL_SUCC = PRODUCT.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const PRODUCT_FETCHALL_FAIL = PRODUCT.ACTION_TYPES.FETCH_ALL + API.FAILED;
const PRODUCT_FETCHALL_FOR_ADDDEVICE_SUCC = PRODUCT.ACTION_TYPES.FETCHALL_FOR_ADD_DEVICE + API.SUCCESSED;
const PRODUCT_FETCHALL_FOR_ADDDEVICE_FAIL = PRODUCT.ACTION_TYPES.FETCHALL_FOR_ADD_DEVICE + API.FAILED;
const PRODUCT_CREATE_SUCC = PRODUCT.ACTION_TYPES.CREATE + API.SUCCESSED;
const PRODUCT_CREATE_FAIL = PRODUCT.ACTION_TYPES.CREATE + API.FAILED;
const PRODUCT_EDIT_SUCC = PRODUCT.ACTION_TYPES.UPDATE + API.SUCCESSED;
const PRODUCT_EDIT_FAIL = PRODUCT.ACTION_TYPES.UPDATE + API.FAILED;
const PRODUCT_DELETE_SUCC = PRODUCT.ACTION_TYPES.DELETE + API.SUCCESSED;
const PRODUCT_DELETE_FAIL = PRODUCT.ACTION_TYPES.DELETE + API.FAILED;
const PRODUCT_FETCH_BY_GUID_SUCC = PRODUCT.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const PRODUCT_FETCH_BY_GUID_FAIL = PRODUCT.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;

//SYSTEMUSER
const SYSTEMUSER_FETCH_ALL_BY_FIRMA_ID_SUCC = SYSTEMUSER.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID + API.SUCCESSED;
const SYSTEMUSER_FETCH_ALL_BY_FIRMA_ID_FAIL = SYSTEMUSER.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID + API.FAILED;
const SYSTEMUSER_FETCH_ALL_SUCC = SYSTEMUSER.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const SYSTEMUSER_FETCH_ALL_FAIL = SYSTEMUSER.ACTION_TYPES.FETCH_ALL + API.FAILED;
const SYSTEMUSER_FETCH_BY_GUID_SUCC = SYSTEMUSER.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const SYSTEMUSER_FETCH_BY_GUID_FAIL = SYSTEMUSER.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;
const SYSTEMUSER_CREATE_SUCC = SYSTEMUSER.ACTION_TYPES.CREATE + API.SUCCESSED;
const SYSTEMUSER_CREATE_FAIL = SYSTEMUSER.ACTION_TYPES.CREATE + API.FAILED;
const SYSTEMUSER_DELETE_SUCC = SYSTEMUSER.ACTION_TYPES.DELETE + API.SUCCESSED;
const SYSTEMUSER_DELETE_FAIL = SYSTEMUSER.ACTION_TYPES.DELETE + API.FAILED;
const SYSTEMUSER_COUNT_USERS_IN_COMPANY_SUCC = SYSTEMUSER.ACTION_TYPES.COUNT_USERS_IN_COMPANY + API.SUCCESSED;
const SYSTEMUSER_COUNT_USERS_IN_COMPANY_FAIL = SYSTEMUSER.ACTION_TYPES.COUNT_USERS_IN_COMPANY + API.FAILED;
const SYSTEMUSER_SET_AS_SUPERADMIN_SUCC = SYSTEMUSER.ACTION_TYPES.SET_AS_SUPERADMIN + API.SUCCESSED;
const SYSTEMUSER_SET_AS_SUPERADMIN_FAIL = SYSTEMUSER.ACTION_TYPES.SET_AS_SUPERADMIN + API.FAILED;
const SYSTEMUSER_SET_AS_NOT_SUPERADMIN_SUCC = SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_SUPERADMIN + API.SUCCESSED;
const SYSTEMUSER_SET_AS_NOT_SUPERADMIN_FAIL = SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_SUPERADMIN + API.FAILED;
const SYSTEMUSER_SET_AS_SYSTEMADMIN_SUCC = SYSTEMUSER.ACTION_TYPES.SET_AS_SYSTEMADMIN + API.SUCCESSED;
const SYSTEMUSER_SET_AS_SYSTEMADMIN_FAIL = SYSTEMUSER.ACTION_TYPES.SET_AS_SYSTEMADMIN + API.FAILED;
const SYSTEMUSER_SET_AS_NOT_SYSTEMADMIN_SUCC = SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_SYSTEMADMIN + API.SUCCESSED;
const SYSTEMUSER_SET_AS_NOT_SYSTEMADMIN_FAIL = SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_SYSTEMADMIN + API.FAILED;
const SYSTEMUSER_SET_AS_CONSULTANT_SUCC = SYSTEMUSER.ACTION_TYPES.SET_AS_CONSULTANT + API.SUCCESSED;
const SYSTEMUSER_SET_AS_CONSULTANT_FAIL = SYSTEMUSER.ACTION_TYPES.SET_AS_CONSULTANT + API.FAILED;
const SYSTEMUSER_SET_AS_NOT_CONSULTANT_SUCC = SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_CONSULTANT + API.SUCCESSED;
const SYSTEMUSER_SET_AS_NOT_CONSULTANT_FAIL = SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_CONSULTANT + API.FAILED;
const SYSTEMUSER_EDIT_SUCC = SYSTEMUSER.ACTION_TYPES.UPDATE + API.SUCCESSED;
const SYSTEMUSER_EDIT_FAIL = SYSTEMUSER.ACTION_TYPES.UPDATE + API.FAILED;
const SYSTEMUSER_FETCH_EMAILS_BY_USERGROUP_AND_FIRMA_SUCC = SYSTEMUSER.ACTION_TYPES.FETCH_EMAILS_BY_USERGROUP_AND_FIRMA + API.SUCCESSED;
const SYSTEMUSER_FETCH_EMAILS_BY_USERGROUP_AND_FIRMA_FAIL = SYSTEMUSER.ACTION_TYPES.FETCH_EMAILS_BY_USERGROUP_AND_FIRMA + API.FAILED;

//USERGROUP
const USERGROUP_FETCH_ALL_SUCC = USERGROUP.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const USERGROUP_FETCH_ALL_FAIL = USERGROUP.ACTION_TYPES.FETCH_ALL + API.FAILED;
const USERGROUP_FETCH_BY_GUID_SUCC = USERGROUP.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const USERGROUP_FETCH_BY_GUID_FAIL = USERGROUP.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;
const USERGROUP_CREATE_SUCC = USERGROUP.ACTION_TYPES.CREATE + API.SUCCESSED;
const USERGROUP_CREATE_FAIL = USERGROUP.ACTION_TYPES.CREATE + API.FAILED;
const USERGROUP_DELETE_SUCC = USERGROUP.ACTION_TYPES.DELETE + API.SUCCESSED;
const USERGROUP_DELETE_FAIL = USERGROUP.ACTION_TYPES.DELETE + API.FAILED;
const USERGROUP_UPDATE_SUCC = USERGROUP.ACTION_TYPES.UPDATE + API.SUCCESSED;
const USERGROUP_UPDATE_FAIL = USERGROUP.ACTION_TYPES.UPDATE + API.FAILED;

//ROLE
const ROLE_FETCH_ALL_SUCC = ROLE.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const ROLE_FETCH_ALL_FAIL = ROLE.ACTION_TYPES.FETCH_ALL + API.FAILED;
const ROLE_CREATE_SUCC = ROLE.ACTION_TYPES.CREATE + API.SUCCESSED;
const ROLE_CREATE_FAIL = ROLE.ACTION_TYPES.CREATE + API.FAILED;
const ROLE_DELETE_SUCC = ROLE.ACTION_TYPES.DELETE + API.SUCCESSED;
const ROLE_DELETE_FAIL = ROLE.ACTION_TYPES.DELETE + API.FAILED;
const ROLE_UPDATE_SUCC = ROLE.ACTION_TYPES.UPDATE + API.SUCCESSED;
const ROLE_UPDATE_FAIL = ROLE.ACTION_TYPES.UPDATE + API.FAILED;

//RIGHT
const RIGHT_FETCH_ALL_SUCC = RIGHT.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const RIGHT_FETCH_ALL_FAIL = RIGHT.ACTION_TYPES.FETCH_ALL + API.FAILED;
const RIGHT_CREATE_SUCC = RIGHT.ACTION_TYPES.CREATE + API.SUCCESSED;
const RIGHT_CREATE_FAIL = RIGHT.ACTION_TYPES.CREATE + API.FAILED;
const RIGHT_DELETE_SUCC = RIGHT.ACTION_TYPES.DELETE + API.SUCCESSED;
const RIGHT_DELETE_FAIL = RIGHT.ACTION_TYPES.DELETE + API.FAILED;
const RIGHT_UPDATE_SUCC = RIGHT.ACTION_TYPES.UPDATE + API.SUCCESSED;
const RIGHT_UPDATE_FAIL = RIGHT.ACTION_TYPES.UPDATE + API.FAILED;

//USERGROUP_ROLE
const USERGROUP_ROLE_DELETE_SUCC = USERGROUP_ROLE.ACTION_TYPES.DELETE + API.SUCCESSED;
const USERGROUP_ROLE_DELETE_FAIL = USERGROUP_ROLE.ACTION_TYPES.DELETE + API.FAILED;
const USERGROUP_ROLE_CREATE_LIST_SUCC = USERGROUP_ROLE.ACTION_TYPES.CREATE_LIST + API.SUCCESSED;
const USERGROUP_ROLE_CREATE_LIST_FAIL = USERGROUP_ROLE.ACTION_TYPES.CREATE_LIST + API.FAILED;

//USERGROUP_RIGHT
const USERGROUP_RIGHT_DELETE_SUCC = USERGROUP_RIGHT.ACTION_TYPES.DELETE + API.SUCCESSED;
const USERGROUP_RIGHT_DELETE_FAIL = USERGROUP_RIGHT.ACTION_TYPES.DELETE + API.FAILED;
const USERGROUP_RIGHT_CREATE_LIST_SUCC = USERGROUP_RIGHT.ACTION_TYPES.CREATE_LIST + API.SUCCESSED;
const USERGROUP_RIGHT_CREATE_LIST_FAIL = USERGROUP_RIGHT.ACTION_TYPES.CREATE_LIST + API.FAILED;

//USER_USERGROUP_PLANT
const USER_USERGROUP_PLANT_DELETE_SUCC = USER_USERGROUP_PLANT.ACTION_TYPES.DELETE + API.SUCCESSED;
const USER_USERGROUP_PLANT_DELETE_FAIL = USER_USERGROUP_PLANT.ACTION_TYPES.DELETE + API.FAILED;
const USER_USERGROUP_PLANT_CREATE_SUCC = USER_USERGROUP_PLANT.ACTION_TYPES.CREATE + API.SUCCESSED;
const USER_USERGROUP_PLANT_CREATE_FAIL = USER_USERGROUP_PLANT.ACTION_TYPES.CREATE + API.FAILED;
const USER_USERGROUP_PLANT_FETCH_BY_GUID_SUCC = USER_USERGROUP_PLANT.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const USER_USERGROUP_PLANT_FETCH_BY_GUID_FAIL = USER_USERGROUP_PLANT.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;
const USER_USERGROUP_PLANT_COUNT_COMPANIES_IN_USER_SUCC = USER_USERGROUP_PLANT.ACTION_TYPES.COUNT_COMPANIES_IN_USER + API.SUCCESSED;
const USER_USERGROUP_PLANT_COUNT_COMPANIES_IN_USER_FAIL = USER_USERGROUP_PLANT.ACTION_TYPES.COUNT_COMPANIES_IN_USER + API.FAILED;
const USER_USERGROUP_PLANT_FETCH_ALL_SUCC = USER_USERGROUP_PLANT.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const USER_USERGROUP_PLANT_FETCH_ALL_FAIL = USER_USERGROUP_PLANT.ACTION_TYPES.FETCH_ALL + API.FAILED;
//PROTOCOL 
const PROTOCOL_CREATE_SUCC = PROTOCOL.ACTION_TYPES.CREATE + API.SUCCESSED;
const PROTOCOL_CREATE_FAIL = PROTOCOL.ACTION_TYPES.CREATE + API.FAILED;
//COMPONENTTYPE
const COMPONENTTYPE_FETCH_ALL_SUCC = COMPONENTTYPE.ACTION_TYPES.FETCH_ALL + API.SUCCESSED;
const COMPONENTTYPE_FETCH_ALL_FAIL = COMPONENTTYPE.ACTION_TYPES.FETCH_ALL + API.FAILED;

//FILESERVER 
const FILESERVER_DEVICE_CREATE_SUCC = FILESERVER.ACTION_TYPES.DEVICE.CREATE + API.SUCCESSED;
const FILESERVER_DEVICE_CREATE_FAIL = FILESERVER.ACTION_TYPES.DEVICE.CREATE + API.FAILED;
const FILESERVER_PLANTPART_CREATE_SUCC = FILESERVER.ACTION_TYPES.PLANTPART.CREATE + API.SUCCESSED;
const FILESERVER_PLANTPART_CREATE_FAIL = FILESERVER.ACTION_TYPES.PLANTPART.CREATE + API.FAILED;
const FILESERVER_PLANT_CREATE_SUCC = FILESERVER.ACTION_TYPES.PLANT.CREATE + API.SUCCESSED;
const FILESERVER_PLANT_CREATE_FAIL = FILESERVER.ACTION_TYPES.PLANT.CREATE + API.FAILED;
const FILESERVER_FIRMA_CREATE_SUCC = FILESERVER.ACTION_TYPES.DEVICE.CREATE + API.SUCCESSED;
const FILESERVER_FIRMA_CREATE_FAIL = FILESERVER.ACTION_TYPES.DEVICE.CREATE + API.FAILED;

const FILESERVER_DEVICE_DELETE_SUCC = FILESERVER.ACTION_TYPES.DEVICE.DELETE + API.SUCCESSED;
const FILESERVER_DEVICE_DELETE_FAIL = FILESERVER.ACTION_TYPES.DEVICE.DELETE + API.FAILED;
const FILESERVER_PLANTPART_DELETE_SUCC = FILESERVER.ACTION_TYPES.PLANTPART.DELETE + API.SUCCESSED;
const FILESERVER_PLANTPART_DELETE_FAIL = FILESERVER.ACTION_TYPES.PLANTPART.DELETE + API.FAILED;
const FILESERVER_PLANT_DELETE_SUCC = FILESERVER.ACTION_TYPES.PLANT.DELETE + API.SUCCESSED;
const FILESERVER_PLANT_DELETE_FAIL = FILESERVER.ACTION_TYPES.PLANT.DELETE + API.FAILED;
const FILESERVER_FIRMA_DELETE_SUCC = FILESERVER.ACTION_TYPES.FIRMA.DELETE + API.SUCCESSED;
const FILESERVER_FIRMA_DELETE_FAIL = FILESERVER.ACTION_TYPES.FIRMA.DELETE + API.FAILED;
const FILESERVER_DEVICE_GET_LIST_OF_DOCUMENTS_SUCC = FILESERVER.ACTION_TYPES.DEVICE.GET_LIST_OF_DOCUMENTS + API.SUCCESSED;
const FILESERVER_DEVICE_GET_LIST_OF_DOCUMENTS_FAIL = FILESERVER.ACTION_TYPES.DEVICE.GET_LIST_OF_DOCUMENTS + API.FAILED;
const FILESERVER_PHYSICALLY_SAVE_SUCC = FILESERVER.ACTION_TYPES.PHYSICALLY.SAVE + API.SUCCESSED;
const FILESERVER_PHYSICALLY_SAVE_FAIL = FILESERVER.ACTION_TYPES.PHYSICALLY.SAVE + API.FAILED;
const FILESERVER_PHYSICALLY_DELETE_SUCC = FILESERVER.ACTION_TYPES.PHYSICALLY.DELETE + API.SUCCESSED;
const FILESERVER_PHYSICALLY_DELETE_FAIL = FILESERVER.ACTION_TYPES.PHYSICALLY.DELETE + API.FAILED;
const FILESERVER_PHYSICALLY_DOWNLOAD_SUCC = FILESERVER.ACTION_TYPES.PHYSICALLY.DOWNLOAD + API.SUCCESSED;
const FILESERVER_PHYSICALLY_DOWNLOAD_FAIL = FILESERVER.ACTION_TYPES.PHYSICALLY.DOWNLOAD + API.FAIL;
const FILESERVER_GET_FILES_TREE_BY_PLANT_GUID_SUCC = FILESERVER.ACTION_TYPES.GET_FILES_TREE_BY_PLANT_GUID + API.SUCCESSED;
const FILESERVER_GET_FILES_TREE_BY_PLANT_GUID_FAIL = FILESERVER.ACTION_TYPES.GET_FILES_TREE_BY_PLANT_GUID + API.FAILED;
const FILESERVER_GET_ALL_FILES_BY_FIRMA_GUID_SUCC = FILESERVER.ACTION_TYPES.GET_ALL_FILES_BY_FIRMA_GUID + API.SUCCESSED;
const FILESERVER_GET_ALL_FILES_BY_FIRMA_GUID_FAIL = FILESERVER.ACTION_TYPES.GET_ALL_FILES_BY_FIRMA_GUID + API.FAILED;


//REPORT_PDF
const REPORT_PDF_GENERATE_SUCC = REPORT_PDF.ACTION_TYPES.GENERATE + API.SUCCESSED;
const REPORT_PDF_GENERATE_FAIL = REPORT_PDF.ACTION_TYPES.GENERATE + API.FAILED;

//TARGETACTUALSUMMARY
const TARGETACTUALSUMMARY_FETCH_BY_DEVICE_ID_SUCC = TARGETACTUALSUMMARY.ACTION_TYPES.FETCH_BY_DEVICE_ID + API.SUCCESSED;
const TARGETACTUALSUMMARY_FETCH_BY_DEVICE_ID_FAIL = TARGETACTUALSUMMARY.ACTION_TYPES.FETCH_BY_DEVICE_ID + API.FAILED;
const TARGETACTUALSUMMARY_FETCH_BY_PP_ID_SUCC = TARGETACTUALSUMMARY.ACTION_TYPES.FETCH_BY_PP_ID + API.SUCCESSED;
const TARGETACTUALSUMMARY_FETCH_BY_PP_ID_FAIL = TARGETACTUALSUMMARY.ACTION_TYPES.FETCH_BY_PP_ID + API.FAILED;
const TARGETACTUALSUMMARY_CREATE_SUCC = TARGETACTUALSUMMARY.ACTION_TYPES.CREATE + API.SUCCESSED;
const TARGETACTUALSUMMARY_CREATE_FAIL = TARGETACTUALSUMMARY.ACTION_TYPES.CREATE + API.FAILED;
const TARGETACTUALSUMMARY_UPDATE_SUCC = TARGETACTUALSUMMARY.ACTION_TYPES.UPDATE + API.SUCCESSED;
const TARGETACTUALSUMMARY_UPDATE_FAIL = TARGETACTUALSUMMARY.ACTION_TYPES.UPDATE + API.FAILED;
const TARGETACTUALSUMMARY_DELETE_SUCC = TARGETACTUALSUMMARY.ACTION_TYPES.DELETE + API.SUCCESSED;
const TARGETACTUALSUMMARY_DELETE_FAIL = TARGETACTUALSUMMARY.ACTION_TYPES.DELETE + API.FAILED;
const TARGETACTUALSUMMARY_GET_VALUES_OF_TARGETACTUALSUMMAY_SUCC = TARGETACTUALSUMMARY.ACTION_TYPES.GET_VALUES_OF_TARGETACTUALSUMMAY + API.SUCCESSED;
const TARGETACTUALSUMMARY_GET_VALUES_OF_TARGETACTUALSUMMAY_FAIL = TARGETACTUALSUMMARY.ACTION_TYPES.GET_VALUES_OF_TARGETACTUALSUMMAY + API.FAILED;
const TARGETACTUALSUMMARY_GET_IF_CAN_BE_BHKW_SUCC = TARGETACTUALSUMMARY.ACTION_TYPES.GET_IF_CAN_BE_BHKW + API.SUCCESSED;
const TARGETACTUALSUMMARY_GET_IF_CAN_BE_BHKW_FAIL = TARGETACTUALSUMMARY.ACTION_TYPES.GET_IF_CAN_BE_BHKW + API.FAILED;
const TARGETACTUALSUMMARY_GET_LIST_OF_CHILDREN_SUCC  = TARGETACTUALSUMMARY.ACTION_TYPES.GET_LIST_OF_CHILDREN + API.SUCCESSED;
const TARGETACTUALSUMMARY_GET_LIST_OF_CHILDREN_FAIL = TARGETACTUALSUMMARY.ACTION_TYPES.GET_LIST_OF_CHILDREN + API.FAILED;

//SAVEDDAILYREPORT
const SAVEDDAILYREPORT_CREATE_SUCC = SAVEDDAILYREPORT.ACTION_TYPES.CREATE + API.SUCCESSED;
const SAVEDDAILYREPORT_CREATE_FAIL = SAVEDDAILYREPORT.ACTION_TYPES.CREATE + API.FAILED;
const SAVEDDAILYREPORT_UPDATE_SUCC = SAVEDDAILYREPORT.ACTION_TYPES.UPDATE + API.SUCCESSED;
const SAVEDDAILYREPORT_UPDATE_FAIL = SAVEDDAILYREPORT.ACTION_TYPES.UPDATE + API.FAILED;
const SAVEDDAILYREPORT_DELETE_SUCC = SAVEDDAILYREPORT.ACTION_TYPES.DELETE + API.SUCCESSED;
const SAVEDDAILYREPORT_DELETE_FAIL = SAVEDDAILYREPORT.ACTION_TYPES.DELETE + API.FAILED;
const SAVEDDAILYREPORT_FETCH_ALL_SAVED_REPORTS_BY_PLANTID_SUCC = SAVEDDAILYREPORT.ACTION_TYPES.FETCH_ALL_SAVED_REPORTS_BY_PLANTID + API.SUCCESSED;
const SAVEDDAILYREPORT_FETCH_ALL_SAVED_REPORTS_BY_PLANTID_FAIL = SAVEDDAILYREPORT.ACTION_TYPES.FETCH_ALL_SAVED_REPORTS_BY_PLANTID + API.FAILED;
const SAVEDDAILYREPORT_GET_IF_AUTO_GENERATED_SUCC = SAVEDDAILYREPORT.ACTION_TYPES.GET_IF_AUTO_GENERATED + API.SUCCESSED;
const SAVEDDAILYREPORT_GET_IF_AUTO_GENERATED_FAIL = SAVEDDAILYREPORT.ACTION_TYPES.GET_IF_AUTO_GENERATED + API.FAILED;

//SAVEDTRENDREPORT
const SAVEDTRENDREPORT_CREATE_SUCC = SAVEDTRENDREPORT.ACTION_TYPES.CREATE + API.SUCCESSED;
const SAVEDTRENDREPORT_CREATE_FAIL = SAVEDTRENDREPORT.ACTION_TYPES.CREATE + API.FAILED;
const SAVEDTRENDREPORT_UPDATE_SUCC = SAVEDTRENDREPORT.ACTION_TYPES.UPDATE + API.SUCCESSED;
const SAVEDTRENDREPORT_UPDATE_FAIL = SAVEDTRENDREPORT.ACTION_TYPES.UPDATE + API.FAILED;
const SAVEDTRENDREPORT_DELETE_SUCC = SAVEDTRENDREPORT.ACTION_TYPES.DELETE + API.SUCCESSED;
const SAVEDTRENDREPORT_DELETE_FAIL = SAVEDTRENDREPORT.ACTION_TYPES.DELETE + API.FAILED;
const SAVEDTRENDREPORT_FETCH_ALL_SAVED_REPORTS_BY_PLANTID_SUCC = SAVEDTRENDREPORT.ACTION_TYPES.FETCH_ALL_SAVED_REPORTS_BY_PLANTID + API.SUCCESSED;
const SAVEDTRENDREPORT_FETCH_ALL_SAVED_REPORTS_BY_PLANTID_FAIL = SAVEDTRENDREPORT.ACTION_TYPES.FETCH_ALL_SAVED_REPORTS_BY_PLANTID + API.FAILED;

//HOWTO
const HOWTO_CREATE_SUCC = HOWTO.ACTION_TYPES.CREATE + API.SUCCESSED;
const HOWTO_CREATE_FAIL = HOWTO.ACTION_TYPES.CREATE + API.FAILED;
const HOWTO_DELETE_SUCC = HOWTO.ACTION_TYPES.DELETE + API.SUCCESSED;
const HOWTO_DELETE_FAIL = HOWTO.ACTION_TYPES.DELETE + API.FAILED;
const HOWTO_UPDATE_SUCC = HOWTO.ACTION_TYPES.UPDATE + API.SUCCESSED;
const HOWTO_UPDATE_FAIL = HOWTO.ACTION_TYPES.UPDATE + API.FAILED;
const HOWTO_FETCH_HOW_TO_BY_GUID_SUCC = HOWTO.ACTION_TYPES.FETCH_HOW_TO_BY_GUID + API.SUCCESSED;
const HOWTO_FETCH_HOW_TO_BY_GUID_FAIL = HOWTO.ACTION_TYPES.FETCH_HOW_TO_BY_GUID + API.FAILED;
const HOWTO_FETCH_ALL_HOW_TOS_BY_LANG_SUCC = HOWTO.ACTION_TYPES.FETCH_ALL_HOW_TOS_BY_LANG + API.SUCCESSED;
const HOWTO_FETCH_ALL_HOW_TOS_BY_LANG_FAIL = HOWTO.ACTION_TYPES.FETCH_ALL_HOW_TOS_BY_LANG + API.FAILED;
const HOWTO_GET_ALL_EXISTING_TAGS_SUCC = HOWTO.ACTION_TYPES.GET_ALL_EXISTING_TAGS + API.SUCCESSED;
const HOWTO_GET_ALL_EXISTING_TAGS_FAIL = HOWTO.ACTION_TYPES.GET_ALL_EXISTING_TAGS + API.FAILED;

//MAIL
const MAIL_SEND_EMAIL_SUCC = MAIL.ACTION_TYPES.SEND_EMAIL + API.SUCCESSED;
const MAIL_SEND_EMAIL_FAIL = MAIL.ACTION_TYPES.SEND_EMAIL + API.FAILED;

//IDENTITY
const IDENTITY_INSERT_INTO_EVENT_LOG_SUCC = IDENTITY.ACTIONS.ADD_EVENT_LOG + IDENTITY.RESULTS.SUCCESSED;
const IDENTITY_INSERT_INTO_EVENT_LOG_FAIL = IDENTITY.ACTIONS.ADD_EVENT_LOG + IDENTITY.RESULTS.FAILED;

//REPORT 
const REPORT_FETCH_ALL_BY_GUID_AND_USERID_SUCC = REPORT.ACTION_TYPES.FETCH_ALL_BY_GUID_AND_USERID + API.SUCCESSED;
const REPORT_FETCH_ALL_BY_GUID_AND_USERID_FAIL = REPORT.ACTION_TYPES.FETCH_ALL_BY_GUID_AND_USERID + API.FAILED;
const REPORT_CREATE_SUCC = REPORT.ACTION_TYPES.CREATE + API.SUCCESSED;
const REPORT_CREATE_FAIL = REPORT.ACTION_TYPES.CREATE + API.FAILED;
const REPORT_FETCH_BY_GUID_SUCC = REPORT.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const REPORT_FETCH_BY_GUID_FAIL = REPORT.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;
const REPORT_DELETE_SUCC = REPORT.ACTION_TYPES.DELETE + API.SUCCESSED;
const REPORT_DELETE_FAIL = REPORT.ACTION_TYPES.DELETE + API.FAILED;
const REPORT_EDIT_SUCC = REPORT.ACTION_TYPES.UPDATE + API.SUCCESSED;
const REPORT_EDIT_FAIL = REPORT.ACTION_TYPES.UPDATE + API.FAILED;

//REPORT_DATAPOINT
const REPORT_DATAPOINT_FETCH_ALL_BY_REPORT_GUID_SUCC = REPORT_DATAPOINT.ACTION_TYPES.FETCH_BY_REPORT_GUID + API.SUCCESSED;
const REPORT_DATAPOINT_FETCH_ALL_BY_REPORT_GUID_FAIL = REPORT_DATAPOINT.ACTION_TYPES.FETCH_BY_REPORT_GUID + API.FAILED;
const REPORT_DATAPOINT_CREATE_SUCC = REPORT_DATAPOINT.ACTION_TYPES.CREATE + API.SUCCESSED;
const REPORT_DATAPOINT_CREATE_FAIL = REPORT_DATAPOINT.ACTION_TYPES.CREATE + API.FAILED;
const REPORT_DATAPOINT_DELETE_SUCC = REPORT_DATAPOINT.ACTION_TYPES.DELETE + API.SUCCESSED;
const REPORT_DATAPOINT_DELETE_FAIL = REPORT_DATAPOINT.ACTION_TYPES.DELETE + API.FAILED;


//REPORT_SET
const REPORT_SET_FETCH_ALL_BY_REPORT_GUID_SUCC = REPORT_SET.ACTION_TYPES.FETCH_ALL_BY_REPORT_GUID + API.SUCCESSED;
const REPORT_SET_FETCH_ALL_BY_REPORT_GUID_FAIL = REPORT_SET.ACTION_TYPES.FETCH_ALL_BY_REPORT_GUID + API.FAILED;
const REPORT_SET_FETCH_BY_GUID_SUCC = REPORT_SET.ACTION_TYPES.FETCH_BY_GUID + API.SUCCESSED;
const REPORT_SET_FETCH_BY_GUID_FAIL = REPORT_SET.ACTION_TYPES.FETCH_BY_GUID + API.FAILED;
const REPORT_SET_CREATE_SUCC = REPORT_SET.ACTION_TYPES.CREATE + API.SUCCESSED;
const REPORT_SET_CREATE_FAIL = REPORT_SET.ACTION_TYPES.CREATE + API.FAILED;
const REPORT_SET_DELETE_SUCC = REPORT_SET.ACTION_TYPES.DELETE + API.SUCCESSED;
const REPORT_SET_DELETE_FAIL = REPORT_SET.ACTION_TYPES.DELETE + API.FAILED;

//REPORT_SET_DATAPOINT
const REPORT_SET_DATAPOINT_CREATE_SUCC = REPORT_SET_DATAPOINT.ACTION_TYPES.CREATE + API.SUCCESSED;
const REPORT_SET_DATAPOINT_CREATE_FAIL = REPORT_SET_DATAPOINT.ACTION_TYPES.CREATE + API.FAILED;
const REPORT_SET_DATAPOINT_DELETE_SUCC = REPORT_SET_DATAPOINT.ACTION_TYPES.DELETE + API.SUCCESSED;
const REPORT_SET_DATAPOINT_DELETE_FAIL = REPORT_SET_DATAPOINT.ACTION_TYPES.DELETE + API.FAILED;
const REPORT_SET_DATAPOINT_FETCH_ALL_BY_REPORT_SET_GUID_SUCC = REPORT_SET_DATAPOINT.ACTION_TYPES.FETCH_BY_REPORT_SET_GUID + API.SUCCESSED;
const REPORT_SET_DATAPOINT_FETCH_ALL_BY_REPORT_SET_GUID_FAIL = REPORT_SET_DATAPOINT.ACTION_TYPES.FETCH_BY_REPORT_SET_GUID + API.FAILED;

//ERROR STRINGS LIST
const ERROR_LIST = [
    //PREMAINTENANCEPLAN
    PREMAINTENANCEPLAN_FETCH_ALL_BY_PRODUCT_GUID_FAIL,
    PREMAINTENANCEPLAN_CREATE_FAIL,
    PREMAINTENANCEPLAN_DELETE_FAIL,
    PREMAINTENANCEPLAN_EDIT_FAIL,
    PREMAINTENANCEPLAN_FETCH_BY_GUID_FAIL,
    //SAVEDDAILYREPORT
    SAVEDDAILYREPORT_CREATE_FAIL,
    SAVEDDAILYREPORT_UPDATE_FAIL,
    SAVEDDAILYREPORT_DELETE_FAIL,
    SAVEDDAILYREPORT_FETCH_ALL_SAVED_REPORTS_BY_PLANTID_FAIL,
    SAVEDDAILYREPORT_GET_IF_AUTO_GENERATED_FAIL,
    //SAVEDTRENDREPORT
    SAVEDTRENDREPORT_CREATE_FAIL,
    SAVEDTRENDREPORT_UPDATE_FAIL,
    SAVEDTRENDREPORT_DELETE_FAIL,
    SAVEDTRENDREPORT_FETCH_ALL_SAVED_REPORTS_BY_PLANTID_FAIL,
    //FIRMA
    FIRMA_FETCHALL_FAIL,
    FIRMA_DELETE_FAIL,
    FIRMA_CREATE_FAIL,
    FIRMA_GET_MP_STATISTICS_FAIL,
    FIRMA_FETCH_BY_GUID_FAIL,
    FIRMA_EDIT_FAIL,
    FIRMA_FETCH_ALL_FILES_FAIL,
    FIRMA_FETCH_NAMES_DICTIONARY_FAIL,
    //PLANT
    PLANT_FETCHALL_FAIL,
    PLANT_FETCH_ALL_BY_FIRMA_ID_FAIL,
    PLANT_CREATE_FAIL,
    PLANT_FETCH_BGANAMES_AND_IDS_FAIL,
    PLANT_DELETE_FAIL,
    PLANT_FETCH_BY_GUID_FAIL,
    PLANT_EDIT_FAIL,
    PLANT_FETCH_ALL_FILES_FAIL,
    PLANT_ADD_PLANT_TO_RTIP_FAIL,
    PLANT_DELETE_PLANT_FROM_RTIP_FAIL,
    PLANT_FETCH_DIFF_PROZESSWRET_PW_ALLE_FAIL,
    PLANT_FETCH_DIFF_BETRIEBSSTUNDEN_BS_ALLE_FAIL,
    PLANT_FETCH_ALL_PW_ALLE_FROM_RTIP_FAIL,
    PLANT_FETCH_ALL_BS_ALLE_FROM_RTIP_FAIL,
    PLANT_FETCH_ALL_RTIP_PW_HR_FAIL,
    PLANT_FETCH_ALL_RTIP_TAB_BW_FAIL,
    PLANT_FETCH_ALL_RTIP_PW_NOT_HR_FAIL,
    PLANT_ADD_NEW_PW_HR_2_RTIP_FAIL,
    PLANT_ADD_NEW_PW_NOT_HR_2_RTIP_FAIL,
    PLANT_ADD_NEW_TAB_BW_2_RTIP_FAIL,
    PLANT_ADD_NEW_KEYVALUE2_AND_INI_PWREAL_2_RTIP_FAIL,
    PLANT_ADD_NEW_BW_KEYVALUE_2_RTIP_FAIL,
    PLANT_ADD_NEW_PW_2_RTIP_PW_ALLE_FAIL,
    PLANT_ADD_NEW_BS_2_RTIP_BW_ALLE_FAIL,
    PLANT_FETCH_NAMES_DICTIONARY_FAIL,
    //PLANTPART
    PLANTPART_FETCHALL_FAIL,
    PLANTPART_FETCH_ALL_BY_PLANT_ID_FAIL,
    PLANTPART_CREATE_FAIL,
    PLANTPART_DELETE_FAIL,
    PLANTPART_FETCH_BY_GUID_FAIL,
    PLANTPART_EDIT_FAIL,
    PLANTPART_FETCH_DETAILS_TO_DISPALY_FAIL,
    PLANTPART_FETCH_ALL_FILES_FAIL,
    //MAINTENANCEPLAN
    MAINTENANCEPLAN_FETCHALL_FAIL,
    MAINTENANCEPLAN_FETCH_ALL_BY_PLANTPART_ID_FAIL,
    MAINTENANCEPLAN_FETCH_ALL_BY_PLANT_ID_FAIL,
    MAINTENANCEPLAN_FETCH_ALL_BY_DEVICE_ID_FAIL,
    MAINTENANCEPLAN_CREATE_FAIL,
    MAINTENANCEPLAN_CREATE_AUTO_FAIL,
    MAINTENANCEPLAN_FETCH_MP_BY_GUID_FAIL,
    MAINTENANCEPLAN_EDIT_FAIL,
    MAINTENANCEPLAN_DELETE_FROM_TABLE_FAIL,
    MAINTENANCEPLAN_DELETE_FROM_VIEW_FAIL,
    //MAINTENANCEPLANTASK
    MAINTENANCEPLANTASK_CREATE_LIST_FAIL,
    MAINTENANCEPLANTASK_DELETE_LIST_FAIL,
    MAINTENANCEPLANTASK_TOGGLE_ISDONE_FAIL,
    //PREDEFINEDMAINTENANCEPLANTASK
    PREMAINTENANCEPLANTASK_CREATE_LIST_FAIL,
    PREMAINTENANCEPLANTASK_DELETE_LIST_FAIL,
    PREMAINTENANCEPLANTASK_GET_ALL_BY_PREDEFINEDMAINTENANCEPLAN_GUID_FAIL,
    //USER_MAINTENAANCEPLAN 
    USER_MAINTENANCEPLAN_CREATE_LIST_FAIL,
    USER_MAINTENANCEPLAN_FETCH_BY_MP_ID_FAIL,
    USER_MAINTENANCEPLAN_DELETE_LIST_FAIL,
    //DEVICE 
    DEVICE_FETCHALL_FAIL,
    DEVICE_FETCH_ALL_DEVICE_BY_PLANTPART_ID_FAIL,
    DEVICE_CREATE_FAIL,
    DEVICE_DELETE_FAIL,
    DEVICE_FETCH_BY_GUID_FAIL,
    DEVICE_EDIT_FAIL,
    DEVICE_FETCH_ALL_FILES_FAIL,
    //PROTOCOL
    PROTOCOL_CREATE_FAIL,
    //DATAPOINT
    DATAPOINT_FETCH_ALL_DATAPOINT_BY_DEVICE_ID_FAIL,
    DATAPOINT_FETCH_ALL_DATAPOINT_BY_PLANTPART_ID_FAIL,
    DATAPOINT_FETCH_ALL_DATAPOINT_BY_PLANT_ID_FAIL,
    DATAPOINT_CREATE_FAIL,
    DATAPOINT_FETCH_RELATED_RTIP_DATAPOINT_FAIL,
    DATAPOINT_DELETE_FAIL,
    DATAPOINT_FETCH_BY_GUID_FAIL,
    DATAPOINT_EDIT_FAIL,
    DATAPOINT_GET_OPERATING_HOURS_FAIL,
    DATAPOINT_GET_VALUES_TO_DISPLAY_FAIL,
    DATAPOINT_TOGGLE_IS_DISPLAY_FAIL,
    DATAPOINT_GET_DAILYVALUES_BETWEENTWODATES_FAIL,
    DATAPOINT_GET_DAILYVALUES_BETWEENTWODATES_FOR_DPLIST_FAIL,
    DATAPOINT_GET_TRENDVALUES_BETWEENTWODATES_FOR_DPLIST_FAIL,
    DATAPOINT_FETCH_ALL_DATAPOINT_BY_REPORT_GUID_A_OR_G_FAIL,
    //PRODUCT
    PRODUCT_FETCHALL_FAIL,
    PRODUCT_FETCHALL_FOR_ADDDEVICE_FAIL,
    PRODUCT_CREATE_FAIL,
    PRODUCT_EDIT_FAIL,
    PRODUCT_DELETE_FAIL,
    PRODUCT_FETCH_BY_GUID_FAIL,
    //SYSTEMUSER
    SYSTEMUSER_FETCH_ALL_BY_FIRMA_ID_FAIL,
    SYSTEMUSER_FETCH_ALL_FAIL,
    SYSTEMUSER_FETCH_BY_GUID_FAIL,
    SYSTEMUSER_CREATE_FAIL,
    SYSTEMUSER_DELETE_FAIL,
    SYSTEMUSER_COUNT_USERS_IN_COMPANY_FAIL,
    SYSTEMUSER_SET_AS_CONSULTANT_FAIL,
    SYSTEMUSER_SET_AS_NOT_CONSULTANT_FAIL,
    SYSTEMUSER_SET_AS_SYSTEMADMIN_FAIL,
    SYSTEMUSER_SET_AS_NOT_SYSTEMADMIN_FAIL,
    SYSTEMUSER_SET_AS_SUPERADMIN_FAIL,
    SYSTEMUSER_SET_AS_NOT_SUPERADMIN_FAIL,
    SYSTEMUSER_EDIT_FAIL,
    SYSTEMUSER_FETCH_EMAILS_BY_USERGROUP_AND_FIRMA_FAIL,
    //PROTOCOL 

    //FILESERVER
    FILESERVER_DEVICE_CREATE_FAIL,
    FILESERVER_PLANTPART_CREATE_FAIL,
    FILESERVER_PLANT_CREATE_FAIL,
    FILESERVER_FIRMA_CREATE_FAIL,
    FILESERVER_DEVICE_DELETE_FAIL,
    FILESERVER_PLANTPART_DELETE_FAIL,
    FILESERVER_PLANT_DELETE_FAIL,
    FILESERVER_FIRMA_DELETE_FAIL,
    FILESERVER_DEVICE_GET_LIST_OF_DOCUMENTS_FAIL,
    FILESERVER_PHYSICALLY_SAVE_FAIL,
    FILESERVER_PHYSICALLY_DELETE_FAIL,
    FILESERVER_PHYSICALLY_DOWNLOAD_FAIL,
    FILESERVER_GET_FILES_TREE_BY_PLANT_GUID_FAIL,
    FILESERVER_GET_ALL_FILES_BY_FIRMA_GUID_FAIL,
    //USERGROUP
    USERGROUP_FETCH_ALL_FAIL,
    USERGROUP_FETCH_BY_GUID_FAIL,
    USERGROUP_CREATE_FAIL,
    USERGROUP_DELETE_FAIL,
    USERGROUP_UPDATE_FAIL,
    //ROLE
    ROLE_FETCH_ALL_FAIL,
    ROLE_CREATE_FAIL,
    ROLE_DELETE_FAIL,
    ROLE_UPDATE_FAIL,
    //RIGHT
    RIGHT_FETCH_ALL_FAIL,
    RIGHT_CREATE_FAIL,
    RIGHT_DELETE_FAIL,
    RIGHT_UPDATE_FAIL,
    //USERGROUP_ROLE
    USERGROUP_ROLE_DELETE_FAIL,
    USERGROUP_ROLE_CREATE_LIST_FAIL,
    //USERGROUP_RIGHT
    USERGROUP_RIGHT_DELETE_FAIL,
    USERGROUP_RIGHT_CREATE_LIST_FAIL,
    //USER_USERGROUP_PLANT
    USER_USERGROUP_PLANT_CREATE_FAIL,
    USER_USERGROUP_PLANT_DELETE_FAIL,
    USER_USERGROUP_PLANT_FETCH_BY_GUID_FAIL,
    USER_USERGROUP_PLANT_COUNT_COMPANIES_IN_USER_FAIL,
    USER_USERGROUP_PLANT_FETCH_ALL_FAIL,
    //REPORT_PDF
    REPORT_PDF_GENERATE_FAIL,
    //COMPONENTTYPE
    COMPONENTTYPE_FETCH_ALL_FAIL,
    //TARGETACTUALSUMMARY
    TARGETACTUALSUMMARY_FETCH_BY_DEVICE_ID_FAIL,
    TARGETACTUALSUMMARY_FETCH_BY_PP_ID_FAIL,
    TARGETACTUALSUMMARY_CREATE_FAIL,
    TARGETACTUALSUMMARY_UPDATE_FAIL,
    TARGETACTUALSUMMARY_DELETE_FAIL,
    TARGETACTUALSUMMARY_GET_VALUES_OF_TARGETACTUALSUMMAY_FAIL,
    TARGETACTUALSUMMARY_GET_IF_CAN_BE_BHKW_FAIL,
    TARGETACTUALSUMMARY_GET_LIST_OF_CHILDREN_FAIL,
    //HOWTO
    HOWTO_CREATE_FAIL,
    HOWTO_DELETE_FAIL,
    HOWTO_UPDATE_FAIL,
    HOWTO_FETCH_HOW_TO_BY_GUID_FAIL,
    HOWTO_FETCH_ALL_HOW_TOS_BY_LANG_FAIL,
    HOWTO_GET_ALL_EXISTING_TAGS_FAIL,
    //MAIL
    MAIL_SEND_EMAIL_FAIL,
    //IDENETITY
    IDENTITY_INSERT_INTO_EVENT_LOG_FAIL,
    //REPORT
    REPORT_FETCH_ALL_BY_GUID_AND_USERID_FAIL,
    REPORT_CREATE_FAIL,
    REPORT_FETCH_BY_GUID_FAIL,
    REPORT_DELETE_FAIL,
    REPORT_EDIT_FAIL,
    //REPORT_DATAPOINT
    REPORT_DATAPOINT_FETCH_ALL_BY_REPORT_GUID_FAIL,
    REPORT_DATAPOINT_CREATE_FAIL,
    REPORT_DATAPOINT_DELETE_FAIL,
    //REPORT_SET
    REPORT_SET_DELETE_FAIL,
    REPORT_SET_CREATE_FAIL,
    REPORT_SET_FETCH_BY_GUID_FAIL,
    REPORT_SET_FETCH_ALL_BY_REPORT_GUID_FAIL,
    //REPORT_SET_DATAPOINT
    REPORT_SET_DATAPOINT_FETCH_ALL_BY_REPORT_SET_GUID_FAIL,
    REPORT_SET_DATAPOINT_DELETE_FAIL,
    REPORT_SET_DATAPOINT_CREATE_FAIL,
];

export default function mainContentReducer(state = initialState, action) {
    //console.log("action.type==>", action);
    //ERRORS
    if (ERROR_LIST.indexOf(action.type) >= 0)
        return getValue_Fetch(state, MAIN_CONTENT_TYPES.ERROR, []);

    switch (action.type) {
        //REPORT_SET_DATAPOINT
        case REPORT_SET_DATAPOINT_DELETE_SUCC:
            return { ...state };
        case REPORT_SET_DATAPOINT_CREATE_SUCC:
            return { ...state };
        case REPORT_SET_DATAPOINT_FETCH_ALL_BY_REPORT_SET_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //REPORT_SET
        case REPORT_SET_CREATE_SUCC:
            return { ...state };
        case REPORT_SET_DELETE_SUCC:
            return { ...state };
        case REPORT_SET_FETCH_BY_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case REPORT_SET_FETCH_ALL_BY_REPORT_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //REPORT_DATAPOINT
        case REPORT_DATAPOINT_FETCH_ALL_BY_REPORT_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case REPORT_DATAPOINT_CREATE_SUCC:
            return { ...state };
        case REPORT_DATAPOINT_DELETE_SUCC:
            return { ...state };
        //REPORT
        case REPORT_FETCH_ALL_BY_GUID_AND_USERID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case REPORT_CREATE_SUCC:
            return { ...state };
        case REPORT_FETCH_BY_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case REPORT_DELETE_SUCC:
            return { ...state };
        case REPORT_EDIT_SUCC:
            return { ...state };
        //IDENTITY
        case IDENTITY_INSERT_INTO_EVENT_LOG_SUCC:
                return { ...state, };
        //Mail 
        case MAIL_SEND_EMAIL_SUCC:
            return { ...state };
        //SAVEDDAILYREPORT 
        case SAVEDDAILYREPORT_GET_IF_AUTO_GENERATED_SUCC:
            return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case SAVEDDAILYREPORT_CREATE_SUCC:
            return { ...state };
        case SAVEDDAILYREPORT_DELETE_SUCC:
            return { ...state };
        case SAVEDDAILYREPORT_UPDATE_SUCC:
            return { ...state };
        case SAVEDDAILYREPORT_FETCH_ALL_SAVED_REPORTS_BY_PLANTID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //SAVEDTRENDREPORT 
        case SAVEDTRENDREPORT_CREATE_SUCC:
            return { ...state };
        case SAVEDTRENDREPORT_DELETE_SUCC:
            return { ...state };
        case SAVEDTRENDREPORT_UPDATE_SUCC:
            return { ...state };
        case SAVEDTRENDREPORT_FETCH_ALL_SAVED_REPORTS_BY_PLANTID_SUCC:
            if (action.targetName) 
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //TARGETACTUALSUMMARY
        case TARGETACTUALSUMMARY_GET_LIST_OF_CHILDREN_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case TARGETACTUALSUMMARY_FETCH_BY_DEVICE_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case TARGETACTUALSUMMARY_FETCH_BY_PP_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case TARGETACTUALSUMMARY_CREATE_SUCC:
            return { ...state };
        case TARGETACTUALSUMMARY_UPDATE_SUCC:
            return { ...state };
        case TARGETACTUALSUMMARY_DELETE_SUCC:
            return { ...state };
        case TARGETACTUALSUMMARY_GET_VALUES_OF_TARGETACTUALSUMMAY_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case TARGETACTUALSUMMARY_GET_IF_CAN_BE_BHKW_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //COMPONENTTYPE
        case COMPONENTTYPE_FETCH_ALL_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.FIRMA_LIST, action.payload);
        //HOWTO
        case HOWTO_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.HOWTO, action.payload);
        case HOWTO_UPDATE_SUCC:
            return { ...state };
        case HOWTO_FETCH_HOW_TO_BY_GUID_SUCC:
            return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEWHOWTO, action.payload);
        case HOWTO_FETCH_ALL_HOW_TOS_BY_LANG_SUCC:
            return getValue_Fetch(state, MAIN_CONTENT_TYPES.HOWTO, action.payload);
        case HOWTO_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.HOWTO, action.payload);
        case HOWTO_GET_ALL_EXISTING_TAGS_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //FIRMA
        case FIRMA_FETCHALL_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.NONE, action.payload);
        case FIRMA_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.FIRMA_LIST, action.payload);
        case FIRMA_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.FIRMA_LIST, action.payload);
        case FIRMA_GET_MP_STATISTICS_SUCC:
            return getValue_SecondaryContent(state, action.payload);
        case FIRMA_FETCH_BY_GUID_SUCC:
            return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEW_FIRMA, action.payload);
        case FIRMA_EDIT_SUCC:
            return { ...state };
        case FIRMA_FETCH_ALL_FILES_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case FIRMA_FETCH_NAMES_DICTIONARY_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //PLANT
        case PLANT_FETCHALL_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.PLANT_LIST, action.payload);
        case PLANT_FETCH_ALL_PW_ALLE_FROM_RTIP_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case PLANT_FETCH_ALL_BS_ALLE_FROM_RTIP_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case PLANT_FETCH_ALL_RTIP_PW_HR_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName); 
        case PLANT_FETCH_ALL_RTIP_TAB_BW_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case PLANT_FETCH_ALL_RTIP_PW_NOT_HR_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case PLANT_FETCH_ALL_BY_FIRMA_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.PLANT_LIST, action.payload);
        case PLANT_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.PLANT_LIST, action.payload);
        case PLANT_ADD_NEW_PW_HR_2_RTIP_SUCC:
            return { ...state };
        case PLANT_ADD_NEW_TAB_BW_2_RTIP_SUCC:
            return { ...state };
        case PLANT_ADD_NEW_PW_NOT_HR_2_RTIP_SUCC:
            return { ...state };
        case PLANT_ADD_NEW_KEYVALUE2_AND_INI_PWREAL_2_RTIP_SUCC:
            return { ...state };
        case PLANT_ADD_NEW_BW_KEYVALUE_2_RTIP_SUCC:
            return { ...state };
        case PLANT_ADD_NEW_PW_2_RTIP_PW_ALLE_SUCC:
            return { ...state };
        case PLANT_ADD_NEW_BS_2_RTIP_BW_ALLE_SUCC:
            return { ...state };
        case PLANT_FETCH_BGANAMES_AND_IDS_SUCC:
            return getValue_Fetch_BGANames_IDs(state, action.payload);
        case PLANT_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.PLANT_LIST, action.payload);
        case PLANT_FETCH_BY_GUID_SUCC:
            return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEW_PLANT, action.payload);
        case PLANT_EDIT_SUCC:
            return { ...state };
        case PLANT_FETCH_ALL_FILES_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case PLANT_ADD_PLANT_TO_RTIP_SUCC:
            return { ...state };
        case PLANT_DELETE_PLANT_FROM_RTIP_SUCC:
            return { ...state };
        case PLANT_FETCH_DIFF_PROZESSWRET_PW_ALLE_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case PLANT_FETCH_DIFF_BETRIEBSSTUNDEN_BS_ALLE_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case PLANT_FETCH_NAMES_DICTIONARY_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //PLANTPART
        case PLANTPART_FETCHALL_SUCC:
            return getValue_Fetch(state, MAIN_CONTENT_TYPES.PLANTPART_LIST, action.payload);
        case PLANTPART_FETCH_ALL_BY_PLANT_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.PLANTPART_LIST, action.payload);
        case PLANTPART_FETCH_DETAILS_TO_DISPALY_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case PLANTPART_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.PLANTPART_LIST, action.payload);
        case PLANTPART_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.PLANTPART_LIST, action.payload);
        case PLANTPART_FETCH_BY_GUID_SUCC:
            return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEW_PLANTPART, action.payload);
        case PLANTPART_EDIT_SUCC:
            return { ...state };
        case PLANTPART_FETCH_ALL_FILES_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //PREMAINTENANCEPLAN 
        case PREMAINTENANCEPLAN_FETCH_ALL_BY_PRODUCT_GUID_SUCC:
            return getValue_Fetch(state, MAIN_CONTENT_TYPES.PRE_MP_LIST, action.payload);
        case PREMAINTENANCEPLAN_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.PRE_MP_LIST, action.payload);
        case PREMAINTENANCEPLAN_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.PRE_MP_LIST, action.payload);
        case PREMAINTENANCEPLAN_EDIT_SUCC:
            return { ...state };
        case PREMAINTENANCEPLAN_FETCH_BY_GUID_SUCC:
            return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEW_PRE_MP, action.payload);
        //MAINTENANCE PLAN
        case MAINTENANCEPLAN_FETCHALL_SUCC:
            return getValue_Fetch(state, MAIN_CONTENT_TYPES.MAINTENANCEPLAN_LIST, action.payload);
        case MAINTENANCEPLAN_FETCH_ALL_BY_PLANTPART_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.MAINTENANCEPLAN_LIST, action.payload);

        case MAINTENANCEPLAN_FETCH_ALL_BY_PLANT_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.MAINTENANCEPLAN_LIST, action.payload);

        case MAINTENANCEPLAN_CREATE_SUCC:
            return getValue_Add_NewMP(state, MAIN_CONTENT_TYPES.MAINTENANCEPLAN_LIST, action.payload);
        case MAINTENANCEPLAN_CREATE_AUTO_SUCC:
            return { ...state };
        case MAINTENANCEPLAN_FETCH_ALL_BY_DEVICE_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.MAINTENANCEPLAN_LIST, action.payload);
        case MAINTENANCEPLAN_FETCH_MP_BY_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEW_MAINTENANCEPLAN, action.payload);
        case MAINTENANCEPLAN_EDIT_SUCC:
            return { ...state };
        case MAINTENANCEPLAN_DELETE_FROM_TABLE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.MAINTENANCEPLAN_LIST, action.payload);
        case MAINTENANCEPLAN_DELETE_FROM_VIEW_SUCC:
            return { ...state };
        //USER_MAINTENANCEPLAN 
        case USER_MAINTENANCEPLAN_CREATE_LIST_SUCC:
            return { ...state };
        case USER_MAINTENANCEPLAN_FETCH_BY_MP_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.NONE, action.payload);
        case USER_MAINTENANCEPLAN_DELETE_LIST_SUCC:
            return { ...state };
        //case USER_MAINTENANCEPLAN_FETCH_BY_MP_ID_SUCC:
        //    return getValue_SecondaryContent(state, action.payload);
        //MAINTENANCE PLAN TASK 
        case MAINTENANCEPLANTASK_CREATE_LIST_SUCC:
            return { ...state };
        case MAINTENANCEPLANTASK_DELETE_LIST_SUCC:
            return { ...state };
        case MAINTENANCEPLANTASK_TOGGLE_ISDONE_SUCC:
            return { ...state };
        //PREDEFINED MAINTENANCE PLAN TASK
        case PREMAINTENANCEPLANTASK_CREATE_LIST_SUCC:
            return { ...state };
        case PREMAINTENANCEPLANTASK_DELETE_LIST_SUCC:
            return { ...state };
        case PREMAINTENANCEPLANTASK_GET_ALL_BY_PREDEFINEDMAINTENANCEPLAN_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //DEVICE
        case DEVICE_FETCHALL_SUCC:
            return getValue_Fetch(state, MAIN_CONTENT_TYPES.DEVICE_LIST, action.payload);
        case DEVICE_FETCH_ALL_DEVICE_BY_PLANTPART_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.DEVICE_LIST, action.payload);
        case DEVICE_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.DEVICE_LIST, action.payload);
        case DEVICE_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.DEVICE_LIST, action.payload);
        case DEVICE_FETCH_BY_GUID_SUCC:
            return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEW_DEVICE, action.payload);
        case DEVICE_EDIT_SUCC:
            return { ...state };
        case DEVICE_FETCH_ALL_FILES_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //PROTOCOL 
        case PROTOCOL_CREATE_SUCC:
            return { ...state };
        //DATAPOINT 
        case DATAPOINT_FETCH_ALL_DATAPOINT_BY_DEVICE_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.DATAPOINT_LIST, action.payload);
        case DATAPOINT_FETCH_ALL_DATAPOINT_BY_PLANTPART_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.DATAPOINT_LIST, action.payload);
        case DATAPOINT_FETCH_ALL_DATAPOINT_BY_PLANT_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.DATAPOINT_LIST, action.payload);
        case DATAPOINT_GET_DAILYVALUES_BETWEENTWODATES_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.DATAPOINT_LIST, action.payload);
        case DATAPOINT_GET_DAILYVALUES_BETWEENTWODATES_FOR_DPLIST_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case DATAPOINT_GET_TRENDVALUES_BETWEENTWODATES_FOR_DPLIST_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case DATAPOINT_FETCH_ALL_DATAPOINT_BY_REPORT_GUID_A_OR_G_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case DATAPOINT_GET_VALUES_TO_DISPLAY_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case DATAPOINT_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.DATAPOINT_LIST, action.payload);
        case DATAPOINT_FETCH_RELATED_RTIP_DATAPOINT_SUCC:
            return getValue_FetchForCombo1(state, action.payload);
        case DATAPOINT_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.DATAPOINT_LIST, action.payload);
        case DATAPOINT_FETCH_BY_GUID_SUCC:
            return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEW_DATAPOINT, action.payload);
        case DATAPOINT_EDIT_SUCC:
            return { ...state };
        case DATAPOINT_GET_OPERATING_HOURS_SUCC:
            return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case DATAPOINT_TOGGLE_IS_DISPLAY_SUCC:
            return { ...state };
        //PRODUCT
        case PRODUCT_FETCHALL_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.PRODUCT_LIST, action.payload);
        case PRODUCT_FETCHALL_FOR_ADDDEVICE_SUCC:
            return getValue_FetchForCombo1(state, action.payload);
        case PRODUCT_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.PRODUCT_LIST, action.payload);
        case PRODUCT_EDIT_SUCC:
            return { ...state };
        case PRODUCT_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.PRODUCT_LIST, action.payload);
        case PRODUCT_FETCH_BY_GUID_SUCC:
            return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEW_PRODUCT, action.payload);
        //SYSTEMUSER
        case SYSTEMUSER_FETCH_EMAILS_BY_USERGROUP_AND_FIRMA_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case SYSTEMUSER_FETCH_ALL_BY_FIRMA_ID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.NONE, action.payload);
        case SYSTEMUSER_FETCH_ALL_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.USER_LIST, action.payload);
        case SYSTEMUSER_FETCH_BY_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEW_SYSTEMUSER, action.payload);
        case SYSTEMUSER_CREATE_SUCC:
            return getValue_Add_NewMP(state, MAIN_CONTENT_TYPES.USER_LIST, action.payload);
        case SYSTEMUSER_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.USER_LIST, action.payload);
        case SYSTEMUSER_COUNT_USERS_IN_COMPANY_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case SYSTEMUSER_SET_AS_SUPERADMIN_SUCC:
            return { ...state };
        case SYSTEMUSER_SET_AS_NOT_SUPERADMIN_SUCC:
            return { ...state };
        case SYSTEMUSER_SET_AS_SYSTEMADMIN_SUCC:
            return { ...state };
        case SYSTEMUSER_SET_AS_NOT_SYSTEMADMIN_SUCC:
            return { ...state };
        case SYSTEMUSER_SET_AS_CONSULTANT_SUCC:
            return { ...state };
        case SYSTEMUSER_SET_AS_NOT_CONSULTANT_SUCC:
            return { ...state };
        case SYSTEMUSER_EDIT_SUCC:
            return { ...state };
        //USERGROUP
        case USERGROUP_FETCH_ALL_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.USERGROUP_LIST, action.payload);
        case USERGROUP_FETCH_BY_GUID_SUCC:
            return getValue_Fetch_One(state, MAIN_CONTENT_TYPES.VIEW_USERGROUP, action.payload);
        case USERGROUP_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.USERGROUP_LIST, action.payload);
        case USERGROUP_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.USERGROUP_LIST, action.payload);
        case USERGROUP_UPDATE_SUCC:
            return { ...state };
        //ROLE
        case ROLE_FETCH_ALL_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.ROLE_LIST, action.payload);
        case ROLE_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.ROLE_LIST, action.payload);
        case ROLE_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.ROLE_LIST, action.payload);
        case ROLE_UPDATE_SUCC:
            return { ...state };
        //RIGHT
        case RIGHT_FETCH_ALL_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
            else
                return getValue_Fetch(state, MAIN_CONTENT_TYPES.RIGHT_LIST, action.payload);
        case RIGHT_CREATE_SUCC:
            return getValue_Add(state, MAIN_CONTENT_TYPES.RIGHT_LIST, action.payload);
        case RIGHT_DELETE_SUCC:
            return getValue_Delete_From_Table(state, MAIN_CONTENT_TYPES.RIGHT_LIST, action.payload);
        case RIGHT_UPDATE_SUCC:
            return { ...state };
        //USERGROUP_ROLE 
        case USERGROUP_ROLE_DELETE_SUCC:
            return { ...state };
        case USERGROUP_ROLE_CREATE_LIST_SUCC:
            return { ...state };
        //USERGROUP_RIGHT
        case USERGROUP_RIGHT_DELETE_SUCC:
            return { ...state };
        case USERGROUP_RIGHT_CREATE_LIST_SUCC:
            return { ...state };
        //USER_USERGROUP_PLANT
        case USER_USERGROUP_PLANT_CREATE_SUCC:
            return getValue_Add_NewUserPlantGroupLink(state, action.payload);
        case USER_USERGROUP_PLANT_DELETE_SUCC:
            return { ...state };
        case USER_USERGROUP_PLANT_FETCH_BY_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case USER_USERGROUP_PLANT_COUNT_COMPANIES_IN_USER_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case USER_USERGROUP_PLANT_FETCH_ALL_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //REPORT_PDF
        case REPORT_PDF_GENERATE_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        //FILESERVER
        case FILESERVER_DEVICE_CREATE_SUCC:
            return { ...state };
        case FILESERVER_PLANTPART_CREATE_SUCC:
            return { ...state };
        case FILESERVER_PLANT_CREATE_SUCC:
            return { ...state };
        case FILESERVER_FIRMA_CREATE_SUCC:
            return { ...state };
        case FILESERVER_DEVICE_GET_LIST_OF_DOCUMENTS_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case FILESERVER_PHYSICALLY_SAVE_SUCC:
            return { ...state };
        case FILESERVER_DEVICE_DELETE_SUCC:
            return { ...state };
        case FILESERVER_PLANTPART_DELETE_SUCC:
            return { ...state };
        case FILESERVER_PLANT_DELETE_SUCC:
            return { ...state };
        case FILESERVER_FIRMA_DELETE_SUCC:
            return { ...state };
        case FILESERVER_PHYSICALLY_DELETE_SUCC:
            return { ...state };
        case FILESERVER_PHYSICALLY_DOWNLOAD_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case FILESERVER_GET_FILES_TREE_BY_PLANT_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
        case FILESERVER_GET_ALL_FILES_BY_FIRMA_GUID_SUCC:
            if (action.targetName)
                return getValue_Fetch_withTarget(state, action.payload, action.targetName);
    }
    return state;

}


//FETCH 
const getValue_SecondaryContent = (state, secondaryContent) => {
    return {
        ...state,
        mainContent: {
            type: state.mainContent.type,
            content: state.mainContent.content,
            secondaryContent: secondaryContent
        }
    }
}

const getValue_FetchForCombo1 = (state, combolist1Content) => {
    return {
        ...state,
        mainContent: {
            type: state.mainContent.type,
            content: state.mainContent.content,
            comboList1: combolist1Content
        }
    }
}

const getValue_Fetch_BGANames_IDs = (state, content) => {
    return {
        ...state,
        mainContent: {
            type: state.mainContent.type,
            content: state.mainContent.content,
            bgaList: content
        }
    }
}

const getValue_Fetch = (state, type, content) => {
    //console.log("getValue_Fetch ", content);
    return {
        ...state,
        mainContent: {
            type: type,
            content: content
        }
    }
}

const getValue_Fetch_withTarget = (state, content, target) => {
    if (state.mainContent && state.mainContent.isAutoGen)
        console.log("getValue_Fetch->>>>>>- ", state.mainContent.isAutoGen.isAutoGen);
    return {
        ...state,
        mainContent: {
            ...state.mainContent,
            [target]: content
        }
    }
}

const getValue_Fetch_One = (state, type, content) => {
    return {
        ...state,
        mainContent: {
            type: type,
            content: content
        }
    }
}


//DELETE
const getValue_Delete_From_Table = (state, type, guid) => {
    //console.log("DELETE state.mainContent from table", state.mainContent.content);
    //console.log("DELETE state.mainContent from table", guid);
    //console.log("DELETE state.mainContent from table", state.mainContent.content.filter(x => x.guid !== guid));
    return {
        ...state,
        mainContent: {
            ...state.mainContent,
            type: type,
            content: state.mainContent.content.filter(x => x.guid !== guid)
        }
    }
}

const getValue_Delete_From_View = (state, type, guid) => {
    console.log("DELETE state.mainContent from view", state.mainContent);
    return {
        ...state,
        mainContent: {
            type: type,
            content: state.mainContent.content && state.mainContent.content.filter(x => x.guid !== guid)
        }
    }
}


//ADD
const getValue_Add = (state, type, newObject) => {
    return {
        ...state,
        mainContent: {
            type: type,
            content: [ ...state.mainContent.content, newObject ]
        }
    }
}

const getValue_Add_NewMP = (state, type, newObject) => {
    return {
        ...state,
        mainContent: {
            ...state.mainContent,
            type: type,
            content: [...state.mainContent.content, newObject ],
            NewMP_GUID: newObject.guid
        }
    }
}
//user_usergroup_plant
const getValue_Add_NewUserPlantGroupLink = (state, newLink) => {
    return {
        ...state,
        mainContent: {
            ...state.mainContent,
            newUserPlantGroupLink: newLink
        }
    }
}