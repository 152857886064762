import React from 'react';

import Header from './Header'
import Sidebar from './Sidebar'
//import Offsidebar from './Offsidebar'
import Footer from './Footer'
import Offsidebar from './Offsidebar';
import AllPlantsTable from '../Plants/AllPlantsTable';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Base = props => {

    const activeUser = useSelector(state => state.activeUser && state.activeUser.activeUser);
    console.log("this.props.activeUser", activeUser);

    const { t, i18n } = useTranslation();

    return (<div className="wrapper">
        <Header t={t} />
        <Sidebar t={t} />
        <Offsidebar />
        <section className="section-container">
            {props.children}
        </section>
        <Footer />
    </div>);
}



export default Base;



    //<Header />
    //<Sidebar />
    //<Offsidebar />
    //<section className="section-container">
    //    {props.children}
    //</section>
    //<Footer />