import { IDENTITY, USER_TOKEN } from "../actions/general.actions";
import { login as loginAfterRegister } from '../actions/identity.actions';
import Axios from 'axios';

Axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(USER_TOKEN);

const identity = ({ dispatch }) => next => async action => {
    let result = next(action)
    
    switch (action.type) {
        case IDENTITY.ACTIONS.REGISTER: {
            const { url, onSuccess, onError, data } = action.payload;
            try {

                const response = await Axios.post(url, data, {
                    'Content-Type': 'multipart/form-data',
                });
                //should add valid header
                dispatch({ type: onSuccess, payload: response.data });

                //should login
                dispatch(loginAfterRegister({ email: response.data.email, password: response.data.password }));
                //should redirect to page
            } catch (error) {
                dispatch({ type: onError, payload: error });
            }
            break;
        }
        case IDENTITY.ACTIONS.LOGIN: {
            const { url, onSuccess, onError, data } = action.payload;
            try {
                console.log("LOGINNNNNN 1", action.payload);
                const response = await Axios.post(url, data, { 'Content-Type': 'multipart/form-data' });
                console.log("LOGINNNNNN 2", response.data );
                dispatch({ type: onSuccess, payload: response.data });
            } catch (error) {
                dispatch({ type: onError, payload: error });
            }
            break;
        }
    }


    return result
}

export default identity ;

