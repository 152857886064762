import * as React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
import { PAGES } from './pages.website';
import PrivateRoute from './components/Common/PrivateRoute';
import  Favicon from 'react-favicon';
import asyncComponent from './AsyncComponent';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
//const waitFor = Tag => props => <Tag {...props}/>;

//const DashboardV1 = React.lazy(() => import('./components/Dashboard/DashboardV1'));
//const DashboardV2 = React.lazy(() => import('./components/Dashboard/DashboardV2'));
//const DashboardV3 = React.lazy(() => import('./components/Dashboard/DashboardV3'));
//const DashboardV4 = React.lazy(() => import('./components/Dashboard/DashboardV4'));
//const DashboardV5 = React.lazy(() => import('./components/Dashboard/DashboardV5'));

const AsyncDashboardV1 = asyncComponent(() => import('./components/Dashboard/DashboardV1'));
const AsyncDashboardV2 = asyncComponent(() => import('./components/Dashboard/DashboardV2'));
const AsyncDashboardV3 = asyncComponent(() => import('./components/Dashboard/DashboardV3'));
const AsyncDashboardV4 = asyncComponent(() => import('./components/Dashboard/DashboardV4'));
const AsyncDashboardV5 = asyncComponent(() => import('./components/Dashboard/DashboardV5'));
const AsyncDashboardV6 = asyncComponent(() => import('./components/Dashboard/DashboardV6'));
const AsyncDashboardV7 = asyncComponent(() => import('./components/Dashboard/DashboardV7'));
const AsyncDashboardV8 = asyncComponent(() => import('./components/Dashboard/DashboardV8'));
const AsyncDashboardV9 = asyncComponent(() => import('./components/Dashboard/DashboardV9'));
const AsyncDashboardV10 = asyncComponent(() => import('./components/Dashboard/DashboardV10'));
const AsyncDashboardV11 = asyncComponent(() => import('./components/Dashboard/DashboardV11'));
const AsyncDashboardV12 = asyncComponent(() => import('./components/Dashboard/DashboardV12'));
//const DashboardV2 = lazy(() => import('./components/Dashboard/DashboardV2'));
//const DashboardV3 = lazy(() => import('./components/Dashboard/DashboardV3'));
//const DashboardProto = lazy(() => import('./components/Dashboard/DashboardProto'));

//const AllPlants = lazy(() => import('./components/Plants/AllPlants'));
//const AssignPlants = lazy(() => import('./components/Dashboard/DashboardProto'));
//const DeactivatePlants = lazy(() => import('./components/Dashboard/DashboardProto'));
//const PlantsAdministration = lazy(() => import('./components/Dashboard/DashboardProto'));

//const NewUser = lazy(() => import('./components/Dashboard/DashboardProto'));
//const Groups_Rights = lazy(() => import('./components/Dashboard/DashboardProto'));
//const RolesAssignment = lazy(() => import('./components/Dashboard/DashboardProto'));
//const MaintenancePlans = lazy(() => import('./components/Dashboard/DashboardProto'));


//const Widgets = lazy(() => import('./components/Widgets/Widgets'));

//const Buttons = lazy(() => import('./components/Elements/Buttons'));
//const Notifications = lazy(() => import('./components/Elements/Notifications'));
//const SweetAlert = lazy(() => import('./components/Elements/SweetAlert'));
//const BsCarousel = lazy(() => import('./components/Elements/Carousel'));
//const Spinner = lazy(() => import('./components/Elements/Spinner'));
//const DropdownAnimation = lazy(() => import('./components/Elements/DropdownAnimation'));
//const Nestable = lazy(() => import('./components/Elements/Nestable'));
//const Sortable = lazy(() => import('./components/Elements/Sortable'));
//const Cards = lazy(() => import('./components/Elements/Cards'));
//const Grid = lazy(() => import('./components/Elements/Grid'));
//const GridMasonry = lazy(() => import('./components/Elements/GridMasonry'));
//const Typography = lazy(() => import('./components/Elements/Typography'));
//const FontIcons = lazy(() => import('./components/Elements/FontIcons'));
//const WeatherIcons = lazy(() => import('./components/Elements/WeatherIcons'));
//const Colors = lazy(() => import('./components/Elements/Colors'));

//const ChartFlot = lazy(() => import('./components/Charts/ChartFlot'));
//const ChartRadial = lazy(() => import('./components/Charts/ChartRadial'));
//const ChartChartJS = lazy(() => import('./components/Charts/ChartChartJS'));
//const ChartMorris = lazy(() => import('./components/Charts/ChartMorris'));
//const ChartChartist = lazy(() => import('./components/Charts/ChartChartist'));

//const MapsGoogle = lazy(() => import('./components/Maps/MapsGoogle'));
//const MapsVector = lazy(() => import('./components/Maps/MapsVector'));

//const TableStandard = lazy(() => import('./components/Tables/TableStandard'));
//const TableExtended = lazy(() => import('./components/Tables/TableExtended'));
//const Datatable = lazy(() => import('./components/Tables/DatatableView'));
//const DataGrid = lazy(() => import('./components/Tables/DataGrid'));

//const FormStandard = lazy(() => import('./components/Forms/FormStandard'));
//const FormExtended = lazy(() => import('./components/Forms/FormExtended'));
//const FormValidation = lazy(() => import('./components/Forms/FormValidation'));
//const FormWizard = lazy(() => import('./components/Forms/FormWizard'));
//const FormUpload = lazy(() => import('./components/Forms/FormUpload'));
//const FormCropper = lazy(() => import('./components/Forms/FormCropper'));

//const Login = React.lazy(() => import('./components/Pages/Login'));//.then(({ Login }) => ({ default: Login })),);
//const Register = React.lazy(() => import('./components/Pages/Register'));
//const Recover = React.lazy(() => import('./components/Pages/Recover'));
//const Lock = React.lazy(() => import('./components/Pages/Lock'));
//const NotFound = React.lazy(() => import('./components/Pages/NotFound'));
//const AccessDenied = React.lazy(() => import('./components/Pages/AccessDenied'));
//const Error500 = React.lazy(() => import('./components/Pages/Error500'));
//const Maintenance = React.lazy(() => import('./components/Pages/Maintenance'));

const AsyncNotFound = asyncComponent(() => import('./components/Pages/NotFound'));
const AsyncLogin = asyncComponent(() => import('./components/Pages/Login'));
const AsyncRegister = asyncComponent(() => import('./components/Pages/Register'));
const AsyncRecover = asyncComponent(() => import('./components/Pages/Recover'));
const AsyncLock = asyncComponent(() => import('./components/Pages/Lock'));
const AsyncAccessDenied = asyncComponent(() => import('./components/Pages/AccessDenied'));
const AsyncError500 = asyncComponent(() => import('./components/Pages/Error500'));
const AsyncMaintenance = asyncComponent(() => import('./components/Pages/Maintenance'));

//const Mailbox = lazy(() => import('./components/Extras/Mailbox'));
//const Timeline = lazy(() => import('./components/Extras/Timeline'));
//const Calendar = lazy(() => import('./components/Extras/Calendar'));
//const Invoice = lazy(() => import('./components/Extras/Invoice'));
//const Search = lazy(() => import('./components/Extras/Search'));
//const Todo = lazy(() => import('./components/Extras/Todo'));
//const Profile = lazy(() => import('./components/Extras/Profile'));
//const BugTracker = lazy(() => import('./components/Extras/BugTracker'));
//const ContactDetails = lazy(() => import('./components/Extras/ContactDetails'));
//const Contacts = lazy(() => import('./components/Extras/Contacts'));
//const Faq = lazy(() => import('./components/Extras/Faq'));
//const FileManager = lazy(() => import('./components/Extras/FileManager'));
//const Followers = lazy(() => import('./components/Extras/Followers'));
//const HelpCenter = lazy(() => import('./components/Extras/HelpCenter'));
//const Plans = lazy(() => import('./components/Extras/Plans'));
//const ProjectDetails = lazy(() => import('./components/Extras/ProjectDetails'));
//const Projects = lazy(() => import('./components/Extras/Projects'));
//const Settings = lazy(() => import('./components/Extras/Settings'));
//const SocialBoard = lazy(() => import('./components/Extras/SocialBoard'));
//const TeamViewer = lazy(() => import('./components/Extras/TeamViewer'));
//const VoteLinks = lazy(() => import('./components/Extras/VoteLinks'));

//const EcommerceOrder = lazy(() => import('./components/Ecommerce/EcommerceOrders'));
//const EcommerceOrderView = lazy(() => import('./components/Ecommerce/EcommerceOrderView'));
//const EcommerceProduct = lazy(() => import('./components/Ecommerce/EcommerceProducts'));
//const EcommerceProductView = lazy(() => import('./components/Ecommerce/EcommerceProductView'));
//const EcommerceCheckout = lazy(() => import('./components/Ecommerce/EcommerceCheckout'));

//const BlogList = lazy(() => import('./components/Blog/BlogList'));
//const BlogPost = lazy(() => import('./components/Blog/BlogPost'));
//const BlogArticle = lazy(() => import('./components/Blog/BlogArticles'));
//const BlogArticleView = lazy(() => import('./components/Blog/BlogArticleView'));

//const ForumHome = lazy(() => import('./components/Forum/ForumHome'));


// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/register',
    '/recover',
    '/lock',
    '/notfound',
    '/error500',
    '/maintenance',
    '/accessdenied',
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'
    console.log("location", location);
    if (listofPages.indexOf(location.pathname) > -1) {
        console.log("PART 1");
        return (
            // Page Layout component wrapper
            <BasePage>
                <React.Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path="/login" component={AsyncLogin}  />
                        <Route path="/register" component={AsyncRegister} />
                        <Route path="/recover" component={AsyncRecover} />
                        <Route path="/lock" component={AsyncLock} />
                        <Route path="/notfound" component={AsyncNotFound} />
                        <Route path="/notfound2" component={AsyncNotFound} />
                        <Route path="/accessdenied" component={AsyncAccessDenied} />
                        <Route path="/error500" component={AsyncError500} />
                        <Route path="/maintenance" component={AsyncMaintenance} />
                    </Switch>
                </React.Suspense>
            </BasePage>
        )
    }
    else {
        console.log("PART 2");
        return (
            // Layout component wrapper <Redirect to="/maintenace" />
            // Use <BaseHorizontal> to change layout
            // <Favicon url="../favicon.ico" />
            <div>
               
                <Base>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <React.Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <PrivateRoute path={PAGES.WP_LIST} component={AsyncDashboardV5} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.TAGESWERT_REPORT} component={AsyncDashboardV4} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.CONTROLPAGE} component={AsyncDashboardV2} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.USERSPAGE} component={AsyncDashboardV3} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.HOMEPAGE} component={AsyncDashboardV1} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.PRODUCT_MANAGER} component={AsyncDashboardV6} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.DOCUMENTSERVER} component={AsyncDashboardV7} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.TRENDS} component={AsyncDashboardV8} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.ADMINISTRATION} component={AsyncDashboardV9} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.HOWTO} component={AsyncDashboardV10} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.PORTAL_EMAIL} component={AsyncDashboardV11} to={AsyncLogin} />
                                    <PrivateRoute path={PAGES.REPORT_MANAGER} component={AsyncDashboardV12} to={AsyncLogin} />
                                    <PrivateRoute path={'/'} exact component={AsyncDashboardV1} to={AsyncLogin} />
                                    <Redirect to="/notfound" />
                                </Switch>
                            </React.Suspense>
                            
                        </CSSTransition>
                    </TransitionGroup>

                </Base>
                
            </div>
        )
    }
}

export default withRouter(Routes);



//<TransitionGroup>
//    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
//        <div>
//            <Suspense fallback={<PageLoader />}>
//                <Switch location={location}>
//                    <Redirect to="/maintenace" />
//                </Switch>
//            </Suspense>
//        </div>
//    </CSSTransition>
//</TransitionGroup>



