import * as React from 'react';
import FaCard from '../Common/FaCard';
import '../../styles/ribbon.css';
import { COMPONENTTYPE, DISPLAY, PLANT, TRANS } from '../../store/actions/general.actions';
import { PORTAL_ACTIONS, MEMBERSHIP_TYPE } from '../../store/identity/identity';
import Can from '../../store/identity/Can';
import { Fade } from 'reactstrap';
import * as TargetActualSummaryActions from '../../store/actions/targetactualsummary.actions';
import { connect } from 'react-redux';
import TargetActualSummary from '../TargetActualSummaries/TargetActualSummary';
import { PlantRow_Props, PlantRow_State } from '../PortalTypes/PortalTypes';



class PlantRow extends React.Component<PlantRow_Props, PlantRow_State> {
    state = {
        isSummaryOpen: sessionStorage.getItem(DISPLAY.BHKW.KEY) === "true" ? true : false,
    } 

    getRibbonColor = status => {
        switch (status) {
            case PLANT.STATUS.ACTIVE: return 'green';
            case PLANT.STATUS.INACTIVE: return 'red';
            default: return 'blue'
        }
    }

    closeSummaryFunction = () => {
        sessionStorage.setItem(DISPLAY.BHKW.KEY, ''+DISPLAY.BHKW.CLOSE);
        this.setState({ isSummaryOpen: false });
    }

    openSummaryFunction = () => {
        sessionStorage.setItem(DISPLAY.BHKW.KEY, ''+DISPLAY.BHKW.OPEN);
        this.setState({ isSummaryOpen: true });
    }

    async componentDidMount() {
        await this.props.getChildrenSummaries(this.props.plant.guid, COMPONENTTYPE.TYPES_GUID.BHKW);
        console.log('rerere', this.props.ChildrenSummaries);
    }

    render() {
        const { guid, name, description, status, tabPlantParts, rtipPlantBganame,  } = this.props.plant;
        const { t } = this.props;
        return (
            <Fade in={true} timeout={500}>
                <div className={"corner-ribbon top-left sticky " + this.getRibbonColor(status) + " shadow"}> {t('PLANT.' + status)}</div>
                <div className="container22">
                    <div className="LogoArea">
                        <div className="px-2" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                            <a style={{ cursor: 'pointer' }} title={t(TRANS.ACTIONS.VIEW_DETAILS)} onClick={() => {
                                this.props.onClick_View_Plant(guid, name ?? '', rtipPlantBganame ?? '');
                            }}>
                                <FaCard iconName="fas fa-industry" />
                            </a>
                        </div>
                    </div>
                    <div className="NameArea">
                        <div className="px-2" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                            <h4 className="mb-2">{name}</h4>
                            <small className="text-muted">{description}</small><br />
                            {
                                rtipPlantBganame !== undefined && rtipPlantBganame !== null &&
                                <small style={{ color: 'darkgreen' }}>{t(TRANS.PLANT.CONNECTED_TO)} <b>{' ' + rtipPlantBganame}</b></small>
                            }
                            {
                                (rtipPlantBganame === undefined || rtipPlantBganame === null) &&
                                <small style={{ color: 'red' }}>{t(TRANS.PLANT.NOT_CONNECTED)}</small>
                            }
                            <br />
                        </div>
                    </div>
                    <div className="RestArea">
                        {
                            this.state.isSummaryOpen && this.props.ChildrenSummaries && this.props.ChildrenSummaries.length > 0 &&
                            <Can
                                type={MEMBERSHIP_TYPE.RIGHT}
                                permissionID={PORTAL_ACTIONS.DASH1.PLANT.TARGETACTUAL}
                                plantID={guid}
                                yes={() => (
                                    <TargetActualSummary
                                        bgaName={this.props.plant.rtipPlantBganame}
                                        summary={this.props.ChildrenSummaries}
                                        closeSummaryFunction={this.closeSummaryFunction}
                                        isSum={true}
                                        t={this.props.t}
                                    />)}
                            />


                        }
                        {
                            !this.state.isSummaryOpen && this.props.ChildrenSummaries && this.props.ChildrenSummaries.length > 0 &&
                            <div className="p-2 mx-2 my-auto" style={{ width: '75px' }} >
                                <div className="row justify-content-center">
                                    <div className="px-2" onClick={() => { this.openSummaryFunction() }} style={{ cursor: 'pointer' }}>
                                        <p className="m-0 text-muted" >
                                            <em className="fas fa-chart-area mr-2 fa-lg" title={this.props.t(TRANS.ACTIONS.SHOW_REPORT)} ></em>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                        
                    </div>
                    <div className="SpecialArea plusesTop">
                        <div className="px-2" >
                            <p className="m-0 mr-4 text-muted" title={this.props.t(TRANS.PLANTPART.PLURAL)} style={{ display: "inline-block" }}>
                                    <a style={{ cursor: 'pointer' }} onClick={() => { this.props.changeClickedPlant_onClickPlantPart(guid, name, rtipPlantBganame ?? '') }}>
                                    <em className="fas fa-sitemap mr-2 fa-lg"></em> </a>{tabPlantParts.length}  </p>
                            <p className="m-0 text-muted" title={this.props.t(TRANS.PLANT.VISUALIZATION)} style={{ display: "inline-block" }}>
                                <a style={{ cursor: 'pointer', color: "inherit"  }} href="https://info-matic.de/guacamole/#/" target="_blank">
                                    <em className="far fa-eye mr-2 fa-lg"></em> </a>  </p>
                        </div>
                        
                    </div>
                    <div className="MpArea plusesX justify-content-center">
                        <div className="px-2" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                            <span className="badge badge-pill m-1 badge-success" title={this.props.t(TRANS.MAINTENANCEPLAN.ACTIVE)}>
                                {this.props.PlantStatistics && this.props.PlantStatistics[0]}
                            </span>
                            <span className="badge badge-pill m-1 badge-warning" title={this.props.t(TRANS.MAINTENANCEPLAN.VERYCLOSE)}>
                                {this.props.PlantStatistics && this.props.PlantStatistics[1]}
                            </span>
                            <span className="badge badge-pill m-1  badge-danger" title={this.props.t(TRANS.MAINTENANCEPLAN.PASSED)}>
                                {this.props.PlantStatistics && this.props.PlantStatistics[2]}
                            </span>
                        </div>
                    </div>
                    <div className="ControlArea plusesLeft">
                        <Can
                            type={MEMBERSHIP_TYPE.RIGHT}
                            permissionID={PORTAL_ACTIONS.DASH1.PLANT.DELETE}
                            plantID={guid}
                            yes={() => (
                                <div className="px-2" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                    <p className="m-0 text-muted" title={t(TRANS.ACTIONS.DELETE)}>
                                        <a style={{ cursor: 'pointer' }} onClick={() => { if (window.confirm('\'' + name + '\' ' + this.props.t(TRANS.MESSAGES.DELETE_ASK_TO_CONFIRM))) this.props.onClick_Delete_Plant(guid) }}>
                                            <em className="far fa-trash-alt mr-2 fa-lg"></em></a></p>
                                </div>)}
                        />
                    </div>

                </div>
            </Fade>
        );
    }
}


function mapStateToProps(state, ownProps) {

    return {

        //CurrentOH: state.mainContent.mainContent && state.mainContent.mainContent.currentOH,
        //DP2Display: state.mainContent.mainContent && state.mainContent.mainContent.dP2Display,
        ChildrenSummaries: state.mainContent.mainContent && state.mainContent.mainContent['Summaries_' + ownProps.plant.guid],
        //Details2Display: state.mainContent.mainContent && state.mainContent.mainContent['det_' + ownProps.plantpart.guid],
    }
}

function mapActionToProps(dispatch) {
    return {

        //calcCurrentOperatingHours: (bgaName, rtipNummer, startingDate) => dispatch(DataPointActions.datapoint_getCurrentOperatingHours(bgaName, rtipNummer, startingDate, 'currentOH')),
        //getDatapointsToDisplay: (bgaName, pointList) => dispatch(DataPointActions.datapoint_getListofDisplayedPWs_withTarget(bgaName, pointList, 'dP2Display')),
        getChildrenSummaries: (plant_id, comTypeID) => dispatch(TargetActualSummaryActions.summary_getListOfChildrenSummaries_PLANT_withTarget(plant_id, comTypeID, 'Summaries_' + plant_id)),
        //getPP_Details2Display: (guid) => dispatch(PPActions.plantpart_getDetailsToDisplay_withTarget(guid, 'det_' + guid)),
    }
}

export default connect(mapStateToProps, mapActionToProps)(PlantRow);



//<div className="list-group mb-3">
//    <div className={"corner-ribbon top-left sticky " + this.getRibbonColor(status) + " shadow"}> {t('PLANT.' + status)}</div>
//    <div className="list-group-item list-group-item-action">
//        <table className="wd-wide">
//            <tbody>
//                <tr>
//                    <td className="wd-xs">
//                        <a style={{ cursor: 'pointer' }} title={t(TRANS.ACTIONS.VIEW_DETAILS)} onClick={() => {
//                            this.props.onClick_View_Plant(guid, name, rtipPlantBganame);
//                        }}>
//                            <FaCard iconName="fas fa-industry" />
//                        </a>
//                    </td>
//                    <td>
//                        <div className="px-2">
//                            <h4 className="mb-2">{name}</h4>
//                            <small className="text-muted">{description}</small><br />
//                            {
//                                rtipPlantBganame !== undefined && rtipPlantBganame !== null &&
//                                <small style={{ color: 'darkgreen' }}>{t(TRANS.PLANT.CONNECTED_TO)} <b>{' ' + rtipPlantBganame}</b></small>
//                            }
//                            {
//                                (rtipPlantBganame === undefined || rtipPlantBganame === null) &&
//                                <small style={{ color: 'red' }}>{t(TRANS.PLANT.NOT_CONNECTED)}</small>
//                            }
//                            <br />
//                        </div>
//                    </td>
//                    <td className="wd-xs d-none d-lg-table-cell">
//                        <div className="row">

//                        </div>
//                        <div className="row">

//                        </div>
//                        <div className="row">

//                        </div>
//                    </td>
//                    {
//                        false &&
//                        <td className="wd-s px-2 d-none d-lg-table-cell">
//                            <div className="px-2">
//                                <div className="row my-3 justify-content-center" title={this.props.t(TRANS.PLANT.CONTACT_PERSON)}>
//                                    <em className="icon-user fa-lg"></em>
//                                </div>
//                                <div className="row justify-content-center" >
//                                    <p className="text-muted">{contactPerson}</p>
//                                </div>
//                            </div>
//                        </td>
//                    }
//                    {
//                        this.state.isSummaryOpen && this.props.ChildrenSummaries && this.props.ChildrenSummaries.length > 0 &&
//                        <td className="d-none d-lg-table-cell" >
//                            <TargetActualSummary
//                                bgaName={this.props.plant.rtipPlantBganame}
//                                summary={this.props.ChildrenSummaries}
//                                closeSummaryFunction={this.closeSummaryFunction}
//                                isSum={true}
//                                t={this.props.t}
//                            />
//                        </td>

//                    }
//                    {
//                        !this.state.isSummaryOpen && this.props.ChildrenSummaries && this.props.ChildrenSummaries.length > 0 &&
//                        <td className="wd-xs d-none d-lg-table-cell">
//                            <div className="row justify-content-center">
//                                <div className="px-2" onClick={() => { this.openSummaryFunction() }} style={{ cursor: 'pointer' }}>
//                                    <p className="m-0 text-muted" >
//                                        <em className="fas fa-chart-area mr-2 fa-lg" title={'Show Summary'} ></em>
//                                    </p>
//                                </div>
//                            </div>
//                        </td>
//                    }
//                    {
//                        tabSystemUserSystemUserGroupPlant &&
//                        tabSystemUserSystemUserGroupPlant.filter(x => x.systemUserId === this.props.user.UserID).length > 0 &&
//                        <td className="wd-s px-2  d-none d-lg-table-cell">
//                            <div className="px-2">
//                                <div className="row my-3 justify-content-center" title={this.props.t(TRANS.USERGROUP.PLURAL)}>
//                                    <em className="fas fa-users fa-lg"></em>
//                                </div>
//                                {
//                                    tabSystemUserSystemUserGroupPlant.filter(x => x.systemUserId === this.props.user.UserID)
//                                        .map((record, id) => {
//                                            return <div className="row justify-content-center" key={record.guid}>
//                                                <p className="text-muted">{record.systemUserGroup.name}</p>
//                                            </div>
//                                        })
//                                }
//                            </div>
//                        </td>
//                    }
//                    <td className="wd-xs d-none d-lg-table-cell">
//                        <div className="row justify-content-center">
//                            <div className="px-2" >
//                                <p className="m-0 text-muted" title={this.props.t(TRANS.PLANTPART.PLURAL)}>
//                                    <a style={{ cursor: 'pointer' }} onClick={() => { this.props.changeClickedPlant_onClickPlantPart(guid, name, rtipPlantBganame) }}>
//                                        <em className="fas fa-sitemap mr-2 fa-lg"></em> </a>{tabPlantPart.length}  </p>
//                            </div>
//                        </div>
//                    </td>
//                    <td className="wd-sm">
//                        <div className="row justify-content-center">
//                            <span className="badge badge-pill m-1 badge-success" title={this.props.t(TRANS.MAINTENANCEPLAN.ACTIVE)}>
//                                {this.props.PlantStatistics && this.props.PlantStatistics[0]}
//                            </span>
//                            <span className="badge badge-pill m-1 badge-warning" title={this.props.t(TRANS.MAINTENANCEPLAN.VERYCLOSE)}>
//                                {this.props.PlantStatistics && this.props.PlantStatistics[1]}
//                            </span>
//                            <span className="badge badge-pill m-1  badge-danger" title={this.props.t(TRANS.MAINTENANCEPLAN.PASSED)}>
//                                {this.props.PlantStatistics && this.props.PlantStatistics[2]}
//                            </span>
//                        </div>
//                        {
//                            false &&
//                            <div className="row  justify-content-center">
//                                <span className="badge badge-pill m-1  badge-secondary" title="Inactive plans">
//                                    {this.props.PlantStatistics && this.props.PlantStatistics[3]}
//                                </span>
//                                <span className="badge badge-pill m-1  badge-primary" title="Inactive Periodic plans">
//                                    {this.props.PlantStatistics && this.props.PlantStatistics[4]}
//                                </span>
//                            </div>
//                        }
//                    </td>
//                    <td className="wd-xs d-none d-lg-table-cell">
//                        <div className="row justify-content-center">
//                            <Can
//                                type={MEMBERSHIP_TYPE.RIGHT}
//                                permissionID={PORTAL_ACTIONS.DASH1.PLANT.DELETE}
//                                plantID={guid}
//                                yes={() => (
//                                    <div className="px-2">
//                                        <p className="m-0 text-muted" title={t(TRANS.ACTIONS.DELETE)}>
//                                            <a style={{ cursor: 'pointer' }} onClick={() => { if (window.confirm('\'' + name + '\' ' + this.props.t(TRANS.MESSAGES.DELETE_ASK_TO_CONFIRM))) this.props.onClick_Delete_Plant(guid) }}>
//                                                <em className="far fa-trash-alt mr-2 fa-lg"></em></a></p>
//                                    </div>)}
//                            />
//                        </div>
//                    </td>
//                </tr>
//            </tbody>
//        </table>
//    </div>
//</div>