import React, { Component, lazy } from 'react';
import { Route, Redirect } from 'react-router';
import { PAGES } from '../../pages.website';
import { connect } from 'react-redux';
import { USER_TOKEN } from '../../store/actions/general.actions';
import jwtDecode from 'jwt-decode';
const waitFor = Tag => props => <Tag {...props} />;
const Login = lazy(() => import('../Pages/Login'));
const DashboardV1 = lazy(() => import('../Dashboard/DashboardV1'));

class PrivateRoute extends Component {
    state = {
        logged: false
    }


    componentWillMount() {
        console.log("componentWillMount", this.state.logged);
        var userJWT = localStorage.getItem(USER_TOKEN);
        try {
            var { exp, user } = jwtDecode(userJWT);
            if (exp === null || user === null || Date.now() >= exp * 1000) {
                localStorage.removeItem(USER_TOKEN);
                return <Redirect to="/login" />;
            }
        } catch{
            localStorage.removeItem(USER_TOKEN);
            return <Redirect to="/login" />;
        }
        console.log("Header User__", user);
        console.log("Header User__", exp);
        this.setState({ logged: userJWT === null ? false : true });
    }

    render() {
        const { component, to, ...rest } = this.props;


        //return <Route {...rest} component={component} />;
        if (this.state.logged) {
            console.log("Private Route 1");
            //return <Redirect to="/login" />;
            return <Route {...rest} component={component} />;
        }
        else {
            console.log("Private Route 2");
            
            return <Redirect to="/login" />;
            
            
        }
    }
}


function mapStateToProps(state, ownProps) {

    return {
        activeUser: state.activeUser && state.activeUser.activeUser,
    }
}

function mapActionToProps(dispatch) {
    return {
        //login: user => dispatch(identityActions.login(user)),
    }
}

export default connect(mapStateToProps, mapActionToProps)(PrivateRoute);

