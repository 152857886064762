
import { baseUrl, FILESERVER, FIRMA } from './general.actions';
import { apiGet, apiAddNew, apiDeleteByID, apiGetFirmaMPStatistics, apiGetByGUID} from './api.actions';
import { apiEdit, apiGet_withTarget, apiGetByUserID, apiGetByUserID_withTarget, apiAddNew_Files, api_GetListofFiles_withTarget, apiDelete_Files, api_fetchNamesDic } from './actions.js';




export const firma_fetchall = () => {
    console.log("firma_fetchall ");
    return apiGet(
        FIRMA.ACTION_TYPES.FETCH_ALL,
        FIRMA.TAB_NAME
    );
}

export const firma_fetchall_ByUserID = userID => {
    console.log("test firma", FIRMA.TAB_NAME + "User/" + userID);
    return apiGetByUserID(
        FIRMA.ACTION_TYPES.FETCH_ALL,
        FIRMA.TAB_NAME,
        userID
    );
}


export const firma_fetchall_ByUserID_withTarget = (userID, TargetName) => {
    console.log("test firma", FIRMA.TAB_NAME + "User/" + userID);
    return apiGetByUserID_withTarget(
        FIRMA.ACTION_TYPES.FETCH_ALL,
        FIRMA.TAB_NAME,
        userID,
        TargetName
    );
}

// ActionType, tableName, newObject
export const firma_create = (firma) => {
    return apiAddNew(
        FIRMA.ACTION_TYPES.CREATE,
        FIRMA.TAB_NAME,
        firma
    );
}
//ActionType, tableName, guid ,editedFirma
export const firma_edit = (guid, firma) => {
    return apiEdit(
        FIRMA.ACTION_TYPES.UPDATE,
        FIRMA.TAB_NAME,
        guid,
        firma
    );
}

//apiDeleteByID = (ActionType, tableName, Id)
export const firma_delete = (guid) => {
    return apiDeleteByID(
        FIRMA.ACTION_TYPES.DELETE,
        FIRMA.TAB_NAME,
        guid
    );
}
//apiGetFirmaMPStatistics = (ActionType, tableName, firmaID) 
export const firma_getMPStatistics = firmaID => {
    return apiGetFirmaMPStatistics(
        FIRMA.ACTION_TYPES.GET_FIRMA_MP_STATISTICS,
        FIRMA.TAB_NAME,
        firmaID
    )
}
//apiGetByGUID = (ActionType, tableName, guid)
export const firma_getByGUID = guid => {
    return apiGetByGUID(
        FIRMA.ACTION_TYPES.FETCH_BY_GUID,
        FIRMA.TAB_NAME,
        guid
        )
}

export const firma_fetchall_withTarget = (TargetName) => {
    return apiGet_withTarget(
        FIRMA.ACTION_TYPES.FETCH_ALL,
        FIRMA.TAB_NAME,
        TargetName
    );
}

export const firma_add_new_file = (fileServer, firmaID) => {
    return apiAddNew_Files(
        FILESERVER.ACTION_TYPES.FIRMA.CREATE,
        FILESERVER.TAB_NAME,
        fileServer,
        FIRMA.TAB_NAME,
        firmaID
    )
}

export const firma_getListofFiles_withTarget = (guid, userID, TargetName) => {
    return api_GetListofFiles_withTarget(
        FIRMA.ACTION_TYPES.FETCH_ALL_FILES,
        FIRMA.TAB_NAME,
        guid,
        userID,
        TargetName
    );
}

export const firma_delete_file = (fileServerID, firmaID) => {
    console.log("Check lllll");
    return apiDelete_Files(
        FILESERVER.ACTION_TYPES.FIRMA.DELETE,
        FILESERVER.TAB_NAME,
        fileServerID,
        FIRMA.TAB_NAME,
        firmaID
    )
}

export const firma_fetchNames = (listOfGUID, TargetName) => {
    return api_fetchNamesDic(
        FIRMA.ACTION_TYPES.FETCH_NAMES_DICTIONARY,
        FIRMA.TAB_NAME,
        listOfGUID,
        TargetName
    )
}