import { createStore, applyMiddleware , compose } from 'redux';
import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares'

import { updateTheme, makeItGreen } from './middlewares/themes.middleware.js';

import { persistedState, saveState } from './persisted.store.js';

import thunk from 'redux-thunk';


const composeEnhancers =
    typeof window === 'object' &&
        process.env.NODE_ENV === 'development' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension�s options like name, actionsBlacklist, actionsCreators, serialize...
            trace : true
        }) : compose;


export default function configureStore() {

    const store = createStore(
        reducers,
        //persistedState, // second argument overrides the initial state
        composeEnhancers(applyMiddleware(
            ...middlewares //, thunk
        ))
    );


    // add a listener that will be invoked on any state change
    store.subscribe(() => {
        saveState(store.getState());
    });

    // Update the initial theme
    //updateTheme(store.getState())
    makeItGreen(store.getState());

    return store;

}