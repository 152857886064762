import { USERGROUP, ROLE } from "../actions/general.actions"



export const MEMBERSHIP_TYPE = {
    ROLE: 'ROLE',
    RIGHT: 'RIGHT',
    ACCESSALL:'ACCESSALL'
}
// FROM PORTAL CODE
//export const PORTAL_RIGHTS = {
//    DASHBOARDV1: {
//        MAINNAV: {
//            MAINPAGE: 'DASHBOARDV1_MAINPAGE',
//            USERMANAGER: 'DASHBOARDV1_USERMANAGER',
//        },
//        COMPANIES: {
//            LIST: 'DASHBOARDV1_COMPANIES_LIST',
//            VIEW: 'DASHBOARDV1_COMPANIES_VIEW',
//            ADD: 'DASHBOARDV1_COMPANIES_ADD',
//            DELETE: 'DASHBOARDV1_COMPANIES_DELETE',
//        },
//    }
//}

export const PORTAL_PAGES = {
    DASHBOARDV1: 'DASHBOARDV1',
    DASHBOARDV2: 'DASHBOARDV2',
    DASHBOARDV3: 'DASHBOARDV3',
    DASHBOARDV4: 'DASHBOARDV4',
    DASHBOARDV5: 'DASHBOARDV5',
    DASHBOARDV6: 'DASHBOARDV6',
    DASHBOARDV7: 'DASHBOARDV7',
    DASHBOARDV8: 'DASHBOARDV8',
    DASHBOARDV9: 'DASHBOARDV9',
    DASHBOARDV10: 'DASHBOARDV10',
    DASHBOARDV11: 'DASHBOARDV11',
    DASHBOARDV12: 'DASHBOARDV12',
}

const GUID_DASH1 = 'GUID_DASH1_';
const GUID_MAINNAV = 'GUID_MAINNAV_';
export const PORTAL_ACTIONS = {
    MAINNAV: {
        MAINPAGE: GUID_MAINNAV + 'MAINPAGE',
        USERMANAGER: GUID_MAINNAV + 'USERMANAGER',
        PERMISSIONS: GUID_MAINNAV + 'PERMISSIONS',
        PRODUCTMANAGER: GUID_MAINNAV + 'PRODUCTMANAGER',
        DAILYVALUESREPORTS: GUID_MAINNAV + 'DAILYVALUESREPORTS',
        WPLIST: GUID_MAINNAV + 'WPLIST',
        DOCUMENTSERVER: GUID_MAINNAV + 'DOCUMENTSERVER',
        TRENDS: GUID_MAINNAV + 'TRENDS',
        ADMINISTRATION: GUID_MAINNAV + 'ADMINISTRATION',
        HOWTO: GUID_MAINNAV + 'HOWTO',
        PORTALEMAIL: GUID_MAINNAV + 'PORTALEMAIL', 
    },
    DASH1: {
        COM: {
            VIEW: GUID_DASH1 +'COM_VIEW',
            ADD: GUID_DASH1 +'COM_ADD',
            DELETE: GUID_DASH1 +'COM_DELETE',
            EDIT: GUID_DASH1 +'COM_EDIT',
            SEARCH: GUID_DASH1 +'COM_SEARCH',
        },
        PLANT: {
            ADD: GUID_DASH1+'PLANT_ADD',
            DELETE: GUID_DASH1+'PLANT_DELETE',
            EDIT: GUID_DASH1+'PLANT_EDIT',
            SEARCH: GUID_DASH1+'PLANT_SEARCH',
            REPORT: GUID_DASH1 + 'PLANT_DAILYVALUE_REPORT',
            TARGETACTUAL: GUID_DASH1 + 'PLANT_TARGETACTUAL',
        }, 
        PLANTPART: {
            ADD: GUID_DASH1+'PLANTPART_ADD',
            DELETE: GUID_DASH1+'PLANTPART_DELETE',
            EDIT: GUID_DASH1+'PLANTPART_EDIT',
            SEARCH: GUID_DASH1 + 'PLANTPART_SEARCH',
            TARGETACTUAL: GUID_DASH1 + 'PLANTPART_TARGETACTUAL',
        }, 
        DEVICE: {
            ADD: GUID_DASH1 + 'DEVICE_ADD',
            DELETE: GUID_DASH1 + 'DEVICE_DELETE',
            EDIT: GUID_DASH1 + 'DEVICE_EDIT',
            SEARCH: GUID_DASH1 + 'DEVICE_SEARCH',
            TARGETACTUAL: GUID_DASH1 + 'DEVICE_TARGETACTUAL',
        }, 
        DATAPOINT: {
            ADD: GUID_DASH1 + 'DATAPOINT_ADD',
            DELETE: GUID_DASH1 + 'DATAPOINT_DELETE',
            EDIT: GUID_DASH1 + 'DATAPOINT_EDIT',
            SEARCH: GUID_DASH1 + 'DATAPOINT_SEARCH',
        },
        MAINTENANCEPLAN: {
            ADD: GUID_DASH1 + 'MAINTENANCEPLAN_ADD',
            DELETE: GUID_DASH1 + 'MAINTENANCEPLAN_DELETE',
            EDIT: GUID_DASH1 + 'MAINTENANCEPLAN_EDIT',
            CLOSE: GUID_DASH1 + 'MAINTENANCEPLAN_CLOSE',
            STOP: GUID_DASH1 + 'MAINTENANCEPLAN_STOP',
            PRINT: GUID_DASH1 + 'MAINTENANCEPLAN_PRINT',
            SEARCH: GUID_DASH1 + 'MAINTENANCEPLAN_SEARCH',
        },
    },
    DASH3: {
        USERS: {
            ADD: 'GUID_DASH3_USER_ADD',
            DELETE: 'GUID_DASH3_USER_DELETE',
            EDIT: 'GUID_DASH3_USER_EDIT',
            LIST: 'GUID_DASH3_USER_LIST',
            SEARCH: 'GUID_DASH3_USER_SEARCH',
        },
        CONSULTANTS: {
            LIST: 'GUID_DASH3_CONSULTANT_LIST',
        }
    },
    DASH2: {
        USERGROUP: {
            ADD: 'GUID_DASH2_USERGROUP_ADD',
            DELETE: 'GUID_DASH2_USERGROUP_DELETE',
            EDIT: 'GUID_DASH2_USERGROUP_EDIT',
            LIST: 'GUID_DASH2_USERGROUP_LIST',
            SEARCH: 'GUID_DASH2_USERGROUP_SEARCH',
        },
        ROLE: {
            ADD: 'GUID_DASH2_ROLE_ADD',
            DELETE: 'GUID_DASH2_ROLE_DELETE',
            EDIT: 'GUID_DASH2_ROLE_EDIT',
            LIST: 'GUID_DASH2_ROLE_LIST',
            SEARCH: 'GUID_DASH2_ROLE_SEARCH',
        },
        RIGHT: {
            ADD: 'GUID_DASH2_RIGHT_ADD',
            DELETE: 'GUID_DASH2_RIGHT_DELETE',
            EDIT: 'GUID_DASH2_RIGHT_EDIT',
            LIST: 'GUID_DASH2_RIGHT_LIST',
            SEARCH: 'GUID_DASH2_RIGHT_SEARCH',
            CHANGE_DISPLAY_MODE: 'GUID_DASH2_RIGHT_CHANGE_DISPLAY_MODE'
        },
    },
    DASH4: {
        PRODUCT: '-1',
    },
    DASH10: {
        ADD: 'GUID_DASH10_HOWTO_ADD',
        DELETE: "GUID_DASH10_HOWTO_DELETE",
        LIST:"GUID_DASH10_HOWTO_LIST"
    },
    DASH12: {
        REPORT: {
            ADD: 'GUID_DASH12_REPORT_ADD',
            DELETE: 'GUID_DASH12_REPORT_DELETE',
            LIST: 'GUID_DASH12_REPORT_LIST',
            EDIT:'GUID_DASH12_REPORT_EDIT',
        }
    }
}






//ROLES ==> ACCESS RULES 
export const PAGES_ACCESS_RULES = {
    //SUPER ADMIN -- not used
    [ROLE.IDs.SUPERADMIN_ROLE_ID]: [
        PORTAL_PAGES.DASHBOARDV1,
        PORTAL_PAGES.DASHBOARDV2,
        PORTAL_PAGES.DASHBOARDV3,
    ],
    //SYSTEM ADMIN -- used to give the permission to access: use RIGHT_ACCESS_RULES to give the permission to see the nav link
    [ROLE.IDs.SYSTEMADMIN_ROLE_ID]: [
        PORTAL_PAGES.DASHBOARDV1,
        //PORTAL_PAGES.DASHBOARDV2,
        PORTAL_PAGES.DASHBOARDV3,
        PORTAL_PAGES.DASHBOARDV4,
        PORTAL_PAGES.DASHBOARDV5,
        PORTAL_PAGES.DASHBOARDV7,
        PORTAL_PAGES.DASHBOARDV8,
        PORTAL_PAGES.DASHBOARDV10,
        PORTAL_PAGES.DASHBOARDV12,
    ],
    //PLANT ADMIN
    [ROLE.IDs.PLANTADMIN_ROLE_ID]: [
        PORTAL_PAGES.DASHBOARDV1,
        PORTAL_PAGES.DASHBOARDV3,
        PORTAL_PAGES.DASHBOARDV4,
        PORTAL_PAGES.DASHBOARDV5,
        PORTAL_PAGES.DASHBOARDV7,
        PORTAL_PAGES.DASHBOARDV8,
        PORTAL_PAGES.DASHBOARDV10,
        PORTAL_PAGES.DASHBOARDV12,
    ],
    //NORMAL USER
    [ROLE.IDs.NORMALUSER_ROLE_ID]: [
        PORTAL_PAGES.DASHBOARDV1,
        PORTAL_PAGES.DASHBOARDV7,
        PORTAL_PAGES.DASHBOARDV8,
        //PORTAL_PAGES.DASHBOARDV12,
    ],
    //CONSULTANT 
    [ROLE.IDs.CONSULTANT_ROLE_ID]: [
        PORTAL_PAGES.DASHBOARDV1,

    ]
}

export const RIGHT_ACCESS_RULES = {
    [USERGROUP.IDs.SYSTEMADMIN_USERGROUP_ID]: [
        PORTAL_ACTIONS.MAINNAV.MAINPAGE,
        PORTAL_ACTIONS.MAINNAV.USERMANAGER,
        //PORTAL_ACTIONS.MAINNAV.PERMISSIONS,
        PORTAL_ACTIONS.MAINNAV.DAILYVALUESREPORTS,
        PORTAL_ACTIONS.MAINNAV.WPLIST,
        PORTAL_ACTIONS.MAINNAV.DOCUMENTSERVER,
        PORTAL_ACTIONS.MAINNAV.TRENDS,
        PORTAL_ACTIONS.MAINNAV.HOWTO,

        PORTAL_ACTIONS.DASH1.PLANT.DELETE,
        PORTAL_ACTIONS.DASH1.PLANT.ADD,
        PORTAL_ACTIONS.DASH1.PLANT.EDIT,
        PORTAL_ACTIONS.DASH1.PLANT.TARGETACTUAL,

        PORTAL_ACTIONS.DASH1.PLANTPART.ADD,
        PORTAL_ACTIONS.DASH1.PLANTPART.DELETE,
        PORTAL_ACTIONS.DASH1.PLANTPART.EDIT,
        PORTAL_ACTIONS.DASH1.PLANTPART.TARGETACTUAL,

        PORTAL_ACTIONS.DASH1.DEVICE.ADD,
        PORTAL_ACTIONS.DASH1.DEVICE.DELETE,
        PORTAL_ACTIONS.DASH1.DEVICE.EDIT,
        PORTAL_ACTIONS.DASH1.DEVICE.TARGETACTUAL,

        PORTAL_ACTIONS.DASH1.DATAPOINT.ADD,
        PORTAL_ACTIONS.DASH1.DATAPOINT.DELETE,
        PORTAL_ACTIONS.DASH1.DATAPOINT.EDIT,

        PORTAL_ACTIONS.DASH1.MAINTENANCEPLAN.ADD,
        PORTAL_ACTIONS.DASH1.MAINTENANCEPLAN.DELETE,
        PORTAL_ACTIONS.DASH1.MAINTENANCEPLAN.EDIT,
        PORTAL_ACTIONS.DASH1.MAINTENANCEPLAN.CLOSE,
        PORTAL_ACTIONS.DASH1.MAINTENANCEPLAN.STOP,
        PORTAL_ACTIONS.DASH1.MAINTENANCEPLAN.PRINT,

        PORTAL_ACTIONS.DASH3.USERS.ADD,

        PORTAL_ACTIONS.DASH2.USERGROUP.ADD,
        PORTAL_ACTIONS.DASH2.USERGROUP.DELETE,
        PORTAL_ACTIONS.DASH2.USERGROUP.EDIT,
        PORTAL_ACTIONS.DASH2.USERGROUP.LIST,

        PORTAL_ACTIONS.DASH2.RIGHT.LIST,

        PORTAL_ACTIONS.DASH2.ROLE.LIST,

        PORTAL_ACTIONS.DASH3.USERS.EDIT,

        PORTAL_ACTIONS.DASH10.LIST,
        //PORTAL_ACTIONS.DASH10.DELETE,
        //PORTAL_ACTIONS.DASH10.ADD,
        //PORTAL_ACTIONS.DASH10.DELETE,

    ],
    [USERGROUP.IDs.CONSULTANT_USERGROUP_ID]: [
        PORTAL_ACTIONS.MAINNAV.MAINPAGE,
    ],
}



