import settings from './settings.middleware.js'
import themes from './themes.middleware.js';
import api from './api.middleware.js';
import identity from './identity.middleware';

export default [
    api,
    identity,
    themes,
    settings,
]