import axios from "axios";
import {
    baseUrl, FIRMA, PLANT, PLANTPART, MAINTENANCEPLAN, DEVICE, DATAPOINT, PRODUCT,
    MAINTENANCEPLANTASK, USER_MAINTENANCEPLAN, SYSTEMUSER, USERGROUP, ROLE, RIGHT, USERGROUP_RIGHT,
    USERGROUP_ROLE, USER_USERGROUP_PLANT, PROTOCOL, REPORT_PDF, LOGTABLE, USER_TOKEN, COMPONENTTYPE,
    TARGETACTUALSUMMARY, FILESERVER, SAVEDDAILYREPORT, SAVEDTRENDREPORT, PREDEFINEDMAINTENANCEPLAN,
    PREDEFINEDMAINTENANCEPLANTASK,
    HOWTO,
    MAIL,
    IDENTITY,
    REPORT,
    REPORT_DATAPOINT,
    REPORT_SET,
    REPORT_SET_DATAPOINT, 
} from "../actions/general.actions";
import jwtDecode from 'jwt-decode';
const { v4: uuidv4 } = require('uuid');

const FETCH_LIST = [
    FIRMA.ACTION_TYPES.FETCH_ALL,
    FIRMA.ACTION_TYPES.FETCH_ALL_FILES,

    PLANT.ACTION_TYPES.FETCH_ALL,
    PLANT.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID,
    PLANT.ACTION_TYPES.FETCH_ALL_FILES,
    PLANT.ACTION_TYPES.FETCH_DIFF_PROZESSWERT_PW_ALLE,
    PLANT.ACTION_TYPES.FETCH_DIFF_BETRIEBSSTUNDEN_BS_ALLE,
    PLANT.ACTION_TYPES.FETCH_ALL_PW_ALLE_FROM_RTIP,
    PLANT.ACTION_TYPES.FETCH_ALL_BS_ALLE_FROM_RTIP,
    PLANT.ACTION_TYPES.FETCH_ALL_RTIP_TAB_BW,
    PLANT.ACTION_TYPES.FETCH_ALL_RTIP_PW_HR,
    PLANT.ACTION_TYPES.FETCH_ALL_RTIP_PW_NOT_HR,

    PLANTPART.ACTION_TYPES.FETCH_ALL,
    PLANTPART.ACTION_TYPES.FETCH_ALL_BY_PLANT_ID,
    PLANTPART.ACTION_TYPES.FETCH_ALL_FILES,

    MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL,
    MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_MAINTENANCEPLAN_BY_PLANTPART_ID,
    MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_MAINTENANCEPLAN_BY_DEVICE_ID,
    MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_MAINTENANCEPLAN_BY_PLANT_ID,

    USER_MAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_BY_MAINTENANCEPLAN_ID,

    DEVICE.ACTION_TYPES.FETCH_ALL_DEVICE_BY_PLANTPART_ID,
    DEVICE.ACTION_TYPES.FETCH_ALL_FILES,

    DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_DEVICE_ID,
    DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_PLANTPART_ID,
    DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_PLANT_ID,
    DATAPOINT.ACTION_TYPES.FETCH_RELATED_RTIP_DATAPOINT,
    DATAPOINT.ACTION_TYPES.GET_DAILYVALUES_BETWEENTWODATES,
    DATAPOINT.ACTION_TYPES.FETCH_ALL_DATAPOINT_BY_REPORT_GUID_A_OR_G,

    PRODUCT.ACTION_TYPES.FETCH_ALL,
    PRODUCT.ACTION_TYPES.FETCHALL_FOR_ADD_DEVICE,

    SYSTEMUSER.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID,
    SYSTEMUSER.ACTION_TYPES.FETCH_ALL,
    SYSTEMUSER.ACTION_TYPES.COUNT_USERS_IN_COMPANY,

    USER_USERGROUP_PLANT.ACTION_TYPES.COUNT_COMPANIES_IN_USER,
    USER_USERGROUP_PLANT.ACTION_TYPES.FETCH_ALL,

    USERGROUP.ACTION_TYPES.FETCH_ALL,

    ROLE.ACTION_TYPES.FETCH_ALL,

    RIGHT.ACTION_TYPES.FETCH_ALL,

    REPORT_PDF.ACTION_TYPES.GENERATE,
    
    COMPONENTTYPE.ACTION_TYPES.FETCH_ALL,

    TARGETACTUALSUMMARY.ACTION_TYPES.GET_LIST_OF_CHILDREN,

    FILESERVER.ACTION_TYPES.DEVICE.GET_LIST_OF_DOCUMENTS,
    FILESERVER.ACTION_TYPES.GET_ALL_FILES_BY_FIRMA_GUID,

    SAVEDDAILYREPORT.ACTION_TYPES.FETCH_ALL_SAVED_REPORTS_BY_PLANTID,
    
    SAVEDTRENDREPORT.ACTION_TYPES.FETCH_ALL_SAVED_REPORTS_BY_PLANTID,


    PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.FETCH_ALL_BY_PRODUCT_GUID,
    PREDEFINEDMAINTENANCEPLANTASK.ACTION_TYPES.GET_ALL_BY_PREDEFINEDMAINTENANCEPLAN_GUID,

    HOWTO.ACTION_TYPES.FETCH_ALL_HOW_TOS_BY_LANG,
    HOWTO.ACTION_TYPES.GET_ALL_EXISTING_TAGS,

    REPORT.ACTION_TYPES.FETCH_ALL_BY_GUID_AND_USERID,
    
    REPORT_DATAPOINT.ACTION_TYPES.FETCH_BY_REPORT_GUID,

    REPORT_SET.ACTION_TYPES.FETCH_ALL_BY_REPORT_GUID,
    REPORT_SET_DATAPOINT.ACTION_TYPES.FETCH_BY_REPORT_SET_GUID,
];

const FETCH_ONE = [
    MAINTENANCEPLAN.ACTION_TYPES.FETCH_MAINTENANCEPLAN_BY_GUID,
    FIRMA.ACTION_TYPES.FETCH_BY_GUID,
    PLANT.ACTION_TYPES.FETCH_BY_GUID,
    PLANTPART.ACTION_TYPES.FETCH_BY_GUID,
    PLANTPART.ACTION_TYPES.FETCH_DETAILS_TO_DISPALY,
    DEVICE.ACTION_TYPES.FETCH_BY_GUID,
    DATAPOINT.ACTION_TYPES.FETCH_BY_GUID,
    USERGROUP.ACTION_TYPES.FETCH_BY_GUID,
    SYSTEMUSER.ACTION_TYPES.FETCH_BY_GUID,
    USER_USERGROUP_PLANT.ACTION_TYPES.FETCH_BY_GUID,

    //PROTOCOL.ACTION_TYPES.FETCH_BY_GUID,
    TARGETACTUALSUMMARY.ACTION_TYPES.FETCH_BY_DEVICE_ID,
    TARGETACTUALSUMMARY.ACTION_TYPES.FETCH_BY_PP_ID,
    TARGETACTUALSUMMARY.ACTION_TYPES.GET_IF_CAN_BE_BHKW,

    FILESERVER.ACTION_TYPES.PHYSICALLY.DOWNLOAD,
    FILESERVER.ACTION_TYPES.GET_FILES_TREE_BY_PLANT_GUID,

    PRODUCT.ACTION_TYPES.FETCH_BY_GUID,
    PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.FETCH_BY_GUID,

    HOWTO.ACTION_TYPES.FETCH_HOW_TO_BY_GUID,
    SAVEDDAILYREPORT.ACTION_TYPES.GET_IF_AUTO_GENERATED,

    REPORT.ACTION_TYPES.FETCH_BY_GUID,
    REPORT_SET.ACTION_TYPES.FETCH_BY_GUID,
]

const DELETE_LIST = [
    FIRMA.ACTION_TYPES.DELETE,
    PLANT.ACTION_TYPES.DELETE,
    PLANT.ACTION_TYPES.DELETE_PLANT_FROM_RTIP,
    PLANTPART.ACTION_TYPES.DELETE,
    DEVICE.ACTION_TYPES.DELETE,
    DATAPOINT.ACTION_TYPES.DELETE,
    MAINTENANCEPLAN.ACTION_TYPES.DELETE_FROM_TABLE,
    MAINTENANCEPLAN.ACTION_TYPES.DELETE_FROM_VIEW,

    USERGROUP_RIGHT.ACTION_TYPES.DELETE,
    USERGROUP_ROLE.ACTION_TYPES.DELETE,

    USERGROUP.ACTION_TYPES.DELETE,
    ROLE.ACTION_TYPES.DELETE,
    RIGHT.ACTION_TYPES.DELETE,

    USER_USERGROUP_PLANT.ACTION_TYPES.DELETE,

    SYSTEMUSER.ACTION_TYPES.DELETE,

    PRODUCT.ACTION_TYPES.DELETE,

    TARGETACTUALSUMMARY.ACTION_TYPES.DELETE,

    FILESERVER.ACTION_TYPES.DEVICE.DELETE,
    FILESERVER.ACTION_TYPES.PLANT.DELETE,
    FILESERVER.ACTION_TYPES.PLANTPART.DELETE,
    FILESERVER.ACTION_TYPES.FIRMA.DELETE,
    FILESERVER.ACTION_TYPES.PHYSICALLY.DELETE,

    SAVEDDAILYREPORT.ACTION_TYPES.DELETE,
    SAVEDTRENDREPORT.ACTION_TYPES.DELETE,

    PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.DELETE,
    HOWTO.ACTION_TYPES.DELETE,

    REPORT.ACTION_TYPES.DELETE,
    REPORT_DATAPOINT.ACTION_TYPES.DELETE,
    REPORT_SET.ACTION_TYPES.DELETE,
    REPORT_SET_DATAPOINT.ACTION_TYPES.DELETE,
];

const ADD_LIST = [
    MAINTENANCEPLAN.ACTION_TYPES.CREATE,
    MAINTENANCEPLAN.ACTION_TYPES.CREATE_AUTO,
    FIRMA.ACTION_TYPES.CREATE,
    PLANT.ACTION_TYPES.CREATE,
    PLANT.ACTION_TYPES.ADD_PLANT_TO_RTIP,
    PLANT.ACTION_TYPES.ADD_NEW_PW_HR_2_RTIP,
    PLANT.ACTION_TYPES.ADD_NEW_PW_NOT_HR_2_RTIP,
    PLANT.ACTION_TYPES.ADD_NEW_TAB_BW_2_RTIP,
    PLANT.ACTION_TYPES.ADD_NEW_KEYVALUE2_AND_INI_PWREAL_2_RTIP,
    PLANT.ACTION_TYPES.ADD_NEW_BW_KEYVALUE2_2_RTIP,
    PLANT.ACTION_TYPES.ADD_NEW_PW_TO_RTIP_PW_ALLE,
    PLANT.ACTION_TYPES.ADD_NEW_BS_TO_RTIP_BW_ALLE,
    PLANTPART.ACTION_TYPES.CREATE,
    DEVICE.ACTION_TYPES.CREATE,
    DATAPOINT.ACTION_TYPES.CREATE,
    USERGROUP.ACTION_TYPES.CREATE,
    ROLE.ACTION_TYPES.CREATE,
    RIGHT.ACTION_TYPES.CREATE,
    USER_USERGROUP_PLANT.ACTION_TYPES.CREATE,
    SYSTEMUSER.ACTION_TYPES.CREATE,
    PROTOCOL.ACTION_TYPES.CREATE,
    TARGETACTUALSUMMARY.ACTION_TYPES.CREATE,
    FILESERVER.ACTION_TYPES.DEVICE.CREATE,
    FILESERVER.ACTION_TYPES.PLANT.CREATE,
    FILESERVER.ACTION_TYPES.PLANTPART.CREATE,
    FILESERVER.ACTION_TYPES.FIRMA.CREATE,
    SAVEDDAILYREPORT.ACTION_TYPES.CREATE,
    SAVEDTRENDREPORT.ACTION_TYPES.CREATE,
    PRODUCT.ACTION_TYPES.CREATE,
    PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.CREATE,

    HOWTO.ACTION_TYPES.CREATE,
    REPORT.ACTION_TYPES.CREATE,
    REPORT_DATAPOINT.ACTION_TYPES.CREATE,
    REPORT_SET.ACTION_TYPES.CREATE,
    REPORT_SET_DATAPOINT.ACTION_TYPES.CREATE,
];

const ADDLIST_LIST = [
    MAINTENANCEPLANTASK.ACTION_TYPES.CREATE_LIST,
    PREDEFINEDMAINTENANCEPLANTASK.ACTION_TYPES.CREATE_LIST,
];

const DELETELIST_LIST = [
    MAINTENANCEPLANTASK.ACTION_TYPES.DELETE_LIST,
    PREDEFINEDMAINTENANCEPLANTASK.ACTION_TYPES.DELETE_LIST,
];

const ADDLIST_MANYMANY_LIST = [
    USER_MAINTENANCEPLAN.ACTION_TYPES.CREATE_LIST,
    USERGROUP_RIGHT.ACTION_TYPES.CREATE_LIST,
    USERGROUP_ROLE.ACTION_TYPES.CREATE_LIST,
];

const DELETELIST_MANYMANY_LIST = [
    USER_MAINTENANCEPLAN.ACTION_TYPES.DELETE_LIST,
];

const EDIT_LIST = [
    FIRMA.ACTION_TYPES.UPDATE,
    PLANT.ACTION_TYPES.UPDATE,
    PLANTPART.ACTION_TYPES.UPDATE,
    DEVICE.ACTION_TYPES.UPDATE,
    DATAPOINT.ACTION_TYPES.UPDATE,
    DATAPOINT.ACTION_TYPES.TOGGLE_IS_DISPLAY,
    MAINTENANCEPLAN.ACTION_TYPES.UPDATE,
    USERGROUP.ACTION_TYPES.UPDATE,
    ROLE.ACTION_TYPES.UPDATE,
    RIGHT.ACTION_TYPES.UPDATE,

    SYSTEMUSER.ACTION_TYPES.SET_AS_CONSULTANT,
    SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_CONSULTANT,
    SYSTEMUSER.ACTION_TYPES.SET_AS_SUPERADMIN,
    SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_SUPERADMIN,
    SYSTEMUSER.ACTION_TYPES.SET_AS_SYSTEMADMIN,
    SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_SYSTEMADMIN,
    SYSTEMUSER.ACTION_TYPES.UPDATE, 

    MAINTENANCEPLANTASK.ACTION_TYPES.TOGGLE_ISDONE,
    TARGETACTUALSUMMARY.ACTION_TYPES.UPDATE,
    SAVEDDAILYREPORT.ACTION_TYPES.UPDATE,
    SAVEDTRENDREPORT.ACTION_TYPES.UPDATE,
    PRODUCT.ACTION_TYPES.UPDATE,

    PREDEFINEDMAINTENANCEPLAN.ACTION_TYPES.UPDATE,
    HOWTO.ACTION_TYPES.UPDATE,
    REPORT.ACTION_TYPES.UPDATE,
]

const BGANAMES_AND_IDS = PLANT.ACTION_TYPES.FETCH_BGANAME_AND_IDS;

const GET_MP_STATISTICS = FIRMA.ACTION_TYPES.GET_FIRMA_MP_STATISTICS;

const GET_OPERATING_HOURS_FOR_DATAPOINT = DATAPOINT.ACTION_TYPES.GET_OPERATING_HOURS_FOR_DATAPOINT;
const GET_DATAPOINTS_TO_DISPLAY = DATAPOINT.ACTION_TYPES.GET_DATAPOINTS_TO_DISPLAY;

const USER_PASS_RECOVER = SYSTEMUSER.ACTION_TYPES.PASS_RECOVER;
const GET_VALUES_TARGETACTUALSUMMARY = TARGETACTUALSUMMARY.ACTION_TYPES.GET_VALUES_OF_TARGETACTUALSUMMAY;
//const DATAPOINT_GET_DAILYVALUESLIST = DATAPOINT.ACTION_TYPES.GET_DAILYVALUES_BETWEENTWODATES;
const SAVE_DOCUMENT_PHYSICALLY = FILESERVER.ACTION_TYPES.PHYSICALLY.SAVE;

const GET_DAILYVALUES_BETWEENTWODATES_FOR_DPLIST = DATAPOINT.ACTION_TYPES.GET_DAILYVALUES_BETWEENTWODATES_FOR_DPLIST;
const GET_TRENDVALUES_BETWEENTWODATES_FOR_DPLIST = DATAPOINT.ACTION_TYPES.GET_TRENDVALUES_BETWEENTWODATES_FOR_DPLIST;

const FETCH_EMAILS_BY_USERGROUP_AND_FIRMAS = SYSTEMUSER.ACTION_TYPES.FETCH_EMAILS_BY_USERGROUP_AND_FIRMA;

const SEND_EMAIL = MAIL.ACTION_TYPES.SEND_EMAIL;

const INSERT_INTO_EVENT_LOG = IDENTITY.ACTIONS.ADD_EVENT_LOG;

const PLANT_FETCH_NAMES_DICTIONARY = PLANT.ACTION_TYPES.FETCH_NAMES_DICTIONARY;
const FIRMA_FETCH_NAMES_DICTIONARY = FIRMA.ACTION_TYPES.FETCH_NAMES_DICTIONARY;

const api = ({ dispatch }) => next => async action => {
    //console.log('api middleware |' + action.type + '|' + FIRMA.ACTION_TYPES.FEATCH_ALL + '|' + PLANT.ACTION_TYPES.FEATCH_ALL);
    //console.log('api middleware ', action);
    if (ADD_LIST.indexOf(action.type) >= 0)
        registerUserAction(action);

    if (FETCH_LIST.indexOf(action.type) >= 0)
        await fetchFunc(action, dispatch);
    else if (ADD_LIST.indexOf(action.type) >= 0)
        await addFunc(action, dispatch);
    else if (DELETE_LIST.indexOf(action.type) >= 0)
        await deleteFunc(action, dispatch);
    else if (action.type === BGANAMES_AND_IDS)
        await fetchBGANamesAndIDs(action, dispatch);
    else if (action.type === GET_MP_STATISTICS)
        await fetchFunc(action, dispatch);
    else if (FETCH_ONE.indexOf(action.type) >= 0)
        await fetchFunc(action, dispatch);
    else if (ADDLIST_LIST.indexOf(action.type) >= 0)
        await addListFunc(action, dispatch);
    else if (DELETELIST_LIST.indexOf(action.type) >= 0)
        await deleteListFunc(action, dispatch);
    else if (ADDLIST_MANYMANY_LIST.indexOf(action.type) >= 0)
        await addList_Many2Many_Func(action, dispatch);
    else if (DELETELIST_MANYMANY_LIST.indexOf(action.type) >= 0)
        await deleteListFunc(action, dispatch);
    else if (EDIT_LIST.indexOf(action.type) >= 0)
        await editFunc(action, dispatch);
    else if (action.type === GET_OPERATING_HOURS_FOR_DATAPOINT)
        await fetchFunc(action, dispatch);
    else if (action.type === USER_PASS_RECOVER)
        await passRecover(action, dispatch);
    else if (action.type === GET_DATAPOINTS_TO_DISPLAY)
        await getDatapointsValue(action, dispatch);
    else if (action.type === GET_VALUES_TARGETACTUALSUMMARY)
        await getTargetActualSummaryValue(action, dispatch);
    else if (action.type === SAVE_DOCUMENT_PHYSICALLY)
        await saveDocumentPhysically(action, dispatch);
    else if (action.type === GET_DAILYVALUES_BETWEENTWODATES_FOR_DPLIST)
        await fetchFunc_1(action, dispatch);
    else if (action.type === GET_TRENDVALUES_BETWEENTWODATES_FOR_DPLIST)
        await fetchFunc_1(action, dispatch);
    else if (action.type === FETCH_EMAILS_BY_USERGROUP_AND_FIRMAS)
        await fetchFunc_fetchusers(action, dispatch);
    else if (action.type === SEND_EMAIL)
        await SendEmailFunc(action, dispatch);
    else if (action.type === INSERT_INTO_EVENT_LOG)
        await insertIntoEventLog(action, dispatch);
    else if (action.type === PLANT_FETCH_NAMES_DICTIONARY)
        await GetNamesDic(action, dispatch);
    else if (action.type === FIRMA_FETCH_NAMES_DICTIONARY)
        await GetNamesDic(action, dispatch);
    next(action);
}

async function getOperatingHoursForDataPoint(action, dispatch) {
    const { url, onSuccess, onError } = action.payload;

}

const REGISTER = {
    ADD: 'ADD'
}

async function registerUserAction(action, type) {
    try {
        var guid = uuidv4();
        const  jwt = localStorage.getItem(USER_TOKEN);
        const user = jwtDecode(jwt);
        console.log("LOGLOG", action);

        switch (type) {
            case REGISTER.ADD: {
                const { url, onSuccess, onError, data } = action.payload;
                await axios.post(
                    baseUrl + LOGTABLE.TAB_NAME,
                    {
                        guid: guid,
                        action: action.type,
                        NewValues: JSON.stringify(data),
                        OldValues: 'vv2',
                        Notes: 'note',
                        systemUserId: user.user.UserID
                    }, { 'Content-Type': 'multipart/form-data' }
                );
                break;
            }

        }
        
    } catch (error) {
        console.log("ERROR: ", error);
    }
}

async function fetchBGANamesAndIDs(action, dispatch) {
    const { url, onSuccess, onError } = action.payload;
    try {
        const response = await axios.get(url,
            {
                headers: { 'Access-Control-Allow-Origin': '*' },
            });
        dispatch({ type: onSuccess, payload: response.data });
        //console.log("NOT ERROR: ", response.data);
    } catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}

async function editFunc(action, dispatch) {
    console.log("edit edit-1->> ", action.payload);
    const { url, onSuccess, onError, targetName, data } = action.payload;
    try {
        const response = await axios.put(url, data, { 'Content-Type': 'multipart/form-data' });
        dispatch({ type: onSuccess, payload: response.data });
    } catch (error) {
        dispatch({ type: onError, payload: error });
    }
}

async function GetNamesDic(action, dispatch) {
    console.log("GetPlantNamesDic -1->> ", action.payload);
    const { url, onSuccess, onError, targetName, data } = action.payload;
    try {
        
        const response = await axios.post(url, data, { 'Content-Type': 'multipart/form-data' });
        dispatch({ type: onSuccess, payload: response.data, targetName: targetName });
    } catch (error) {
        dispatch({ type: onError, payload: error });
    }
}

async function SendEmailFunc(action, dispatch) {
    console.log("SendEmailFunc SendEmailFunc-1->> ", action.payload);
    const { url, onSuccess, onError, targetName, title,emailBody, to,cc } = action.payload;
    try {
        const data = { title, emailBody, to, cc };
        const response = await axios.post(url, data, { 'Content-Type': 'multipart/form-data' });
        dispatch({ type: onSuccess, payload: response.data });
    } catch (error) {
        dispatch({ type: onError, payload: error });
    }
}

async function passRecover(action, dispatch) {
    const { url, onSuccess, onError } = action.payload;
    try {
        const response = await axios.get(url, { 'Content-Type': 'multipart/form-data' });
        dispatch({ type: onSuccess, payload: response.data });
    } catch (error) {
        dispatch({ type: onError, payload: error });
    }
}

async function fetchFunc(action, dispatch) {
    console.log("action.type-1->> ", action);
    const { url, onSuccess, onError, targetName } = action.payload;
    try {
        const response = await axios.get(url,
            {
                headers: { 'Access-Control-Allow-Origin': '*', },
                
            });
        console.log("fetch function data:", response.data);
        if (targetName)
            dispatch({ type: onSuccess, payload: response.data, targetName: targetName });
        else
            dispatch({ type: onSuccess, payload: response.data });
        //console.log("NOT ERROR: ", response.data);
    } catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}

async function insertIntoEventLog(action, dispatch) {
    const { url, onSuccess, onError, } = action.payload;
    try {
        const response = await axios.get(url ,
            {
                //headers: {
                //    'Access-Control-Allow-Origin': '*',
                //    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, X-Access-Token,x-auth-token',
                //    'Access-Control-Allow-Methods': 'GET',
                //    'Content-Type': 'application/json',
                //},
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Headers': "Content-Type, Authorization"
                },
                mode: "cors",
            });
        console.log("responseee", response);
        dispatch({ type: onSuccess, payload: response.data });
    } catch (error) {
        dispatch({ type: onError, payload: error });
    }
}

async function fetchFunc_fetchusers(action, dispatch) {
    console.log("action.type-1->> ", action);
    const { url, onSuccess, onError, targetName, listOfComs } = action.payload;
    try {
        const listt = JSON.stringify(listOfComs);
        console.log("listt", listt);
        const response = await axios.get(url, {
            params: {
                listOfComs : listt
            }
        },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    //'Content-Type': "application/json;charset=utf-8"
                },
            });
        if (targetName)
            dispatch({ type: onSuccess, payload: response.data, targetName: targetName });
        else
            dispatch({ type: onSuccess, payload: response.data });
        //console.log("NOT ERROR: ", response.data);
    } catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}

async function fetchFunc_1(action, dispatch) {
    console.log("fetchFunc_1->> ", action);
    const { url, onSuccess, onError, targetName, dpIDs } = action.payload;
    try {
        const response = await axios.post(url,
            JSON.stringify(dpIDs),
            {
                headers: { 'Access-Control-Allow-Origin': '*', "Content-Type": "application/json"},
                mode: "cors",
            });
        if (targetName)
            dispatch({ type: onSuccess, payload: response.data, targetName: targetName });
        else
            dispatch({ type: onSuccess, payload: response.data });
        //console.log("NOT ERROR: ", response.data);
    } catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}

async function addFunc(action, dispatch) {
    //console.log("action.type-2-> ", action.type);
    const { url, onSuccess, onError , data } = action.payload;
    try {
        
        const response = await axios.post(
            url , data, { 'Content-Type': 'multipart/form-data' });

        dispatch({ type: onSuccess, payload: response.data });
        //console.log("NOT ERROR: ", response.data);
    } catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}

async function addListFunc(action, dispatch) {
    //console.log("action.type-2-> ", action.type);
    const { url, onSuccess, onError, data } = action.payload;
    const { listToAdd, relatedID , relationField } = data;
    try {
        //console.log("addListFunc", "url: " + url + " - relatedID:" + relatedID + " - relationField:" + relationField);

        for (var i = 0; i < listToAdd.length; i++) {
            const response = await axios.post(
                url, { ...listToAdd[i], [relationField]: relatedID }, { 'Content-Type': 'multipart/form-data' });
        }
        dispatch({ type: onSuccess, payload: "ALL LIST HAVE BEEN ADDED!" });
        //console.log("NOT ERROR: ", response.data);
    } catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}
//
async function addList_Many2Many_Func(action, dispatch) {
    //console.log("action.type-2-> ", action.payload.data);
    const { url, onSuccess, onError, data } = action.payload;
    const { listToAdd, relationField1_InObjectList, relationField1_InManyManyTable, related2ID, relationField2_InManyManyTable } = data;
    try {
        for (var i = 0; i < listToAdd.length; i++) {
            console.log("data data ", { [relationField1_InManyManyTable]: listToAdd[i][relationField1_InObjectList], [relationField2_InManyManyTable]: related2ID });
            const response = await axios.post(
                url, { [relationField1_InManyManyTable]: listToAdd[i][relationField1_InObjectList], [relationField2_InManyManyTable]: related2ID }, { 'Content-Type': 'multipart/form-data' });
        }
        dispatch({ type: onSuccess, payload: "ALL LIST HAVE BEEN ADDED!" });
        //console.log("NOT ERROR: ", response.data);
    } catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}

async function deleteList_Many2Many_Func(action, dispatch) {
    //console.log("action.type-2-> ", action.payload.data);
    const { url, onSuccess, onError, data } = action.payload;
    const { listToAdd, relationField1_InObjectList, relationField1_InManyManyTable, related2ID, relationField2_InManyManyTable } = data;
    try {
        for (var i = 0; i < listToAdd.length; i++) {
            console.log("data data ", { [relationField1_InManyManyTable]: listToAdd[i][relationField1_InObjectList], [relationField2_InManyManyTable]: related2ID });
            const response = await axios.post(
                url, { [relationField1_InManyManyTable]: listToAdd[i][relationField1_InObjectList], [relationField2_InManyManyTable]: related2ID }, { 'Content-Type': 'multipart/form-data' });
        }
        dispatch({ type: onSuccess, payload: "ALL LIST HAVE BEEN ADDED!" });
        //console.log("NOT ERROR: ", response.data);
    } catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}


async function deleteFunc(action, dispatch) {
    const { url, onSuccess, onError, guid } = action.payload;
    console.log("DELETE action", action);
    try {
        const response = await axios.delete(url,
            {
                headers: { 'Access-Control-Allow-Origin': '*' },
                
            });
        dispatch({ type: onSuccess, payload: guid });
        console.log("NOT ERROR deleteFunc: ", response.data);
    } catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}

async function deleteListFunc(action, dispatch) {
    //console.log("action.type-2-> ", action.type);
    const { url, onSuccess, onError, data } = action.payload;
    //const { relatedID, relationField } = data;
    //console.log("deleteListFunc", "url: " + url + " - relatedID:" + relatedID + " - relationField:" + relationField);
    try {
        //for (var i = 0; i < listToAdd.length; i++) {
            const response = await axios.delete(
                url , { 'Content-Type': 'multipart/form-data' });
        //}
        dispatch({ type: onSuccess, payload: "ALL LIST HAVE BEEN DELETED!" });
        //console.log("NOT ERROR: ", response.data);
    } catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}

async function getDatapointsValue(action, dispatch) {
    const { url, onSuccess, onError, data, targetName } = action.payload;
    try {
        //console.log("getDatapointsValue data ", data[0]);
        var res = [];
        for (var i = 0; i < data.length; i++) {
            const response = await axios.get(
                url + "/" + data[i].rtipNummer + "/" + data[i].usedFunc + "/" + data[i].type + "/",
                {
                    headers: { 'Access-Control-Allow-Origin': '*' },

                });
            res.push({ ...response.data, dpName: data[i].name, type: data[i].type, factor: data[i].factor, modifiedUnit: data[i].modifiedUnit });
        }

        if (targetName)
            dispatch({ type: onSuccess, payload: res, targetName: targetName });
        else {
            dispatch({ type: onSuccess, payload: res });
            console.log("NOT ERROR: ", res);
        }
    }
    catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}

async function saveDocumentPhysically(action, dispatch) {
    const { url, onSuccess, onError,  data } = action.payload;
    console.log("saveDocumentPhysically - api middleware",data);

    try {
        const response = await axios.post(url, //JSON.stringify(data),
            //JSON.stringify({
            //    file: data.file,
            //    dbObj: data.dbObj
            //}),

            data,
            //data,
            { "Content-Type": "multipart/form-data" }
            //{
            //    headers: {
            //       'Access-Control-Allow-Origin': '*',
            //        'Content-Type': 'application/json'
            //    }
            //}
            )
            .then(res => { // then print response status
                console.log("res.statusText", res.statusText);
            })
            ;

        dispatch({ type: onSuccess, payload: 'Doc has been saved successfully!'});
        console.log("NOT ERROR: saveDocumentPhysically");

    }
    catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}

async function getTargetActualSummaryValue(action, dispatch) {
    const { url, onSuccess, onError, targetName } = action.payload;
    try {
        //console.log("getDatapointsValue data ", data[0]);
        var res = {};
        //current IST
        const response = await axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' } });

        //to load more information from input if needed 
        //res = {}.push({ ...response.data, dpName: data[i].name, type: data[i].type });


        if (targetName)
            dispatch({ type: onSuccess, payload: response.data, targetName: targetName });
        else {
            dispatch({ type: onSuccess, payload: res });
            console.log("NOT ERROR: ", res);
        }
    }
    catch (error) {
        console.log("ERROR: ", error);
        dispatch({ type: onError, payload: error });
    }
}



export default api;