import * as React from 'react';
import CardWithHeader from '../Common/CardWithHeader';
import { connect } from 'react-redux';
import * as PlantActions from '../../store/actions/plants.actions';
import * as FirmaActions from '../../store/actions/firma.actions';
import PlantRow from './PlantRow';
import { MAIN_CONTENT_TYPES, BREADCRUMB_LEVELS, CONTROLBUTTON_TYPES, USERGROUP, TRANS } from '../../store/actions/general.actions';
import { Breadcrumb, BreadcrumbItem, Fade } from 'reactstrap';
import { MEMBERSHIP_TYPE, PORTAL_ACTIONS } from '../../store/identity/identity';
import ScrollUpButton from "react-scroll-up-button"
import '../../styles/blink.css'; 

import { AllPlantsTable_Props, AllPlantsTable_State } from '../PortalTypes/PortalTypes';
import { PlantObj } from '../PortalTypes/PortalObjs';

class AllPlantsTable extends React.Component<AllPlantsTable_Props, AllPlantsTable_State> {

    state = {
        PlantToDelete: '',
        searchText: '',
        PlantList: [] as PlantObj[],
        PlantStatistics: {}
    }


    constructor(props) {
        super(props)
        //this.props.fetchAllPlant();
        //console.log("this.props.clickedFirmaID ", this.props.clickedFirmaID);
    }
    async componentDidMount() {
        //two cases: (1) Super Admin => All plants | (2) other: only linked plants
        if (this.props.user.isSuperAdmin) {
            console.log("plant1", this.props.user); 
            await this.props.fetchAllPlant(this.props.clickedFirmaID);
        }
        else if (this.props.clickedFirmaID !== '-1' && this.props.user) {
            console.log("plant12222222", this.props);
            await this.props.fetchAllPlant_with_UserID(this.props.clickedFirmaID, this.props.user.UserID);
        }

        await this.props.getMPStatistics(this.props.clickedFirmaID);
        this.setState({
            PlantList: this.props.PlantList,
            PlantStatistics: this.props.PlantStatistics,
        });
        
        console.log("plant3");
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.PlantToDelete !== '') {
            console.log("delete plant1", this.state);    
            var toDelete = this.state.PlantToDelete;
            this.props.deletePlant(this.state.PlantToDelete);
            console.log("delete plant2", this.state);    
            this.setState({
                PlantList: this.state.PlantList.filter(x => x.guid !== toDelete),
                PlantToDelete: ''
            });
        }
        if (prevState.searchText !== this.state.searchText) {
            this.setState({
                PlantList: !this.state.PlantList ? [] :
                    this.props.PlantList.filter(x =>
                        ( 
                            (x.name && x.name.toUpperCase().includes(this.state.searchText.toUpperCase())) ||
                            (x.description && x.description.toUpperCase().includes(this.state.searchText.toUpperCase()))
                            //(x.addressCity && x.addressCity.toUpperCase().includes(this.state.searchText.toUpperCase())) ||
                            
                        )
                    )
            });
        }
        
    }

    onClick_Delete_Plant = guid => {
        this.setState({
            PlantToDelete: guid
        })
    }

    setSearchText = txt => { this.setState({ searchText: txt }) }

    render() {

        const { onClick_Breadcrumb_GoTo_FirmaList, clickedFirmaName, clickedFirmaID } = this.props;
        const ob = { onClick_Breadcrumb_GoTo_FirmaList, clickedFirmaName, clickedFirmaID };
        
        if (this.props.ContentType === MAIN_CONTENT_TYPES.PLANT_LIST && this.state.PlantList)
            if (this.state.PlantList.length === 0)
                return (
                    <CardWithHeader
                        level={BREADCRUMB_LEVELS.PLANT}
                        breadcrumb_object={ob}
                        controlButtons={{
                            contentType: MAIN_CONTENT_TYPES.PLANT_LIST,
                            buttons: [CONTROLBUTTON_TYPES.ADD, CONTROLBUTTON_TYPES.SEARCH],
                            metaData: [
                                { title: this.props.t(TRANS.PLANT.ADD_NEW), onClick: this.props.onClick_AddNew_Plant, type: MEMBERSHIP_TYPE.RIGHT, permissionID: PORTAL_ACTIONS.DASH1.PLANT.ADD, plantID: null },
                                { title: this.props.t(TRANS.ACTIONS.SEARCH), onChange: this.setSearchText, permissionID: PORTAL_ACTIONS.DASH1.PLANT.SEARCH, type: MEMBERSHIP_TYPE.RIGHT, plantID: null }]
                        }}
                        activeCompanyTag={this.props.activeCompanyTag}
                    >
                        <h4> {this.props.t(TRANS.PLANT.NO_PLANTS_TO_DISPLAY)}</h4>
                    </CardWithHeader>
                );
            else
                return (
                    <CardWithHeader
                        level={BREADCRUMB_LEVELS.PLANT}
                        breadcrumb_object={ob}
                        controlButtons={{
                            contentType: MAIN_CONTENT_TYPES.PLANT_LIST,
                            buttons: [CONTROLBUTTON_TYPES.ADD, /* CONTROLBUTTON_TYPES.LINKTO, */  CONTROLBUTTON_TYPES.SEARCH],
                            metaData: [
                                { title: this.props.t(TRANS.PLANT.ADD_NEW), onClick: this.props.onClick_AddNew_Plant, type: MEMBERSHIP_TYPE.RIGHT, permissionID: PORTAL_ACTIONS.DASH1.PLANT.ADD, plantID: null },
                                //{ title: this.props.t(TRANS.PLANT.MP_LIST), onClick: this.props.onClick_PlantReporting, icon: 'far fa-file-alt', type: MEMBERSHIP_TYPE.RIGHT, permissionID: PORTAL_ACTIONS.DASH1.PLANT.REPORT, plantID: null },
                                { title: this.props.t(TRANS.ACTIONS.SEARCH), onChange: this.setSearchText, permissionID: PORTAL_ACTIONS.DASH1.PLANT.SEARCH, type: MEMBERSHIP_TYPE.RIGHT, plantID: null }]
                        }}
                        activeCompanyTag={this.props.activeCompanyTag}
                    >
                        {
                            this.state.PlantList.map((record: PlantObj, id) => {
                                //console.log("record:==> ", record)
                                if (record.guid)
                                    return (
                                        <Fade key={'Fade' + record.guid}>
                                            <PlantRow
                                                key={record.guid}
                                                plant={record}
                                                changeClickedPlant_onClickPlantPart={this.props.changeClickedPlant_onClickPlantPart}
                                                PlantStatistics={this.state.PlantStatistics && this.state.PlantStatistics[record.guid]}
                                                onClick_Delete_Plant={this.onClick_Delete_Plant}
                                                onClick_View_Plant={this.props.onClick_View_Plant}
                                                user={this.props.user}
                                                t={this.props.t}
                                            />
                                        </Fade>
                                    );
                            })
                        }
                        <div>
                            <ScrollUpButton />
                        </div>
                    </CardWithHeader>
                );
        else
            return (
                <CardWithHeader
                    level={BREADCRUMB_LEVELS.PLANT}
                    breadcrumb_object={ob}
                    controlButtons={{
                        contentType: MAIN_CONTENT_TYPES.PLANT_LIST,
                        buttons: [CONTROLBUTTON_TYPES.ADD],
                        metaData: [{ title: this.props.t(TRANS.PLANT.ADD_NEW), onClick: this.props.onClick_AddNew_Plant }]
                    }}
                    activeCompanyTag={this.props.activeCompanyTag}
                >
                    <h4 className="blink_me">{this.props.t(TRANS.ACTIONS.LOADING)}</h4>
                </CardWithHeader>
            );
    }
}


function mapStateToProps(state, ownProps) {
    //console.log("allPlantsTable mapStateToProps ", state.plant.list);
    console.log("ownProps", ownProps);
    return {
        PlantList: state.mainContent.mainContent && state.mainContent.mainContent.content,
        ContentType: state.mainContent.mainContent && state.mainContent.mainContent.type,
        PlantStatistics: state.mainContent.mainContent && state.mainContent.mainContent.secondaryContent,
        clickedFirmaID: ownProps.clickedFirmaID,
        clickedFirmaName: ownProps.clickedFirmaName
    }
}

function mapActionToProps(dispatch, ownProps) {
    //console.log("allPlantsTable mapActionToProps", PlantActions.fetchAll);
    //console.log("ownProps 222 ", ownProps);
    if (ownProps.clickedFirmaID && ownProps.clickedFirmaID !== -1)
        return {
            fetchAllPlant: FirmaID => dispatch(PlantActions.plant_fetchallByFirmaID_withTarget(FirmaID,'')),
            fetchAllPlant_with_UserID: (firmaID, userID) => dispatch(PlantActions.plant_fetchallByFirmaID_And_UserID(firmaID, userID)),
            deletePlant: guid => dispatch(PlantActions.plant_delete(guid)),
            getMPStatistics: (FirmaID) => dispatch(FirmaActions.firma_getMPStatistics(FirmaID))
        }
    else
        return {
            fetchAllPlant: () => dispatch(PlantActions.plant_fetchall()),
            deletePlant: guid => dispatch(PlantActions.plant_delete(guid)),
            getMPStatistics: (FirmaID) => dispatch(FirmaActions.firma_getMPStatistics(FirmaID))
        }
}

export default connect(mapStateToProps, mapActionToProps)(AllPlantsTable);