import React, { Component, Fragment } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { BREADCRUMBCUSTOMIZED_TYPES } from '../../store/actions/general.actions';

class BreadcrumbCustomized extends Component {
    state = {}
    render() {
        const { children, title, type } = this.props;
        return (
            <Fragment>
                <h3>{title}</h3>
                <Breadcrumb>
                    {children}
                    {
                        type === BREADCRUMBCUSTOMIZED_TYPES.LIST &&
                        <BreadcrumbItem active></BreadcrumbItem>
                    }
                </Breadcrumb>
                <hr />
            </Fragment>
        );
    }
}

export default BreadcrumbCustomized;