import React, { Component } from 'react';
import AllPlantsTable from '../Plants/AllPlantsTable';
import AllFirmaTable from '../Firmas/AllFirmaTable';
//import Now from '../Common/Now';

class Footer extends Component {

    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                <span>&copy; InfOmatiC Portal V{process.env.REACT_APP_VERSION} - {year}</span>
            </footer>
        );
    }

}

export default Footer;
 