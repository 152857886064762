import React, { Component } from 'react';
import { CardHeader, Card, CardBody, Col, Button, BreadcrumbItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import ContentWrapper from '../Layout/ContentWrapper';
import { BREADCRUMB_LEVELS, CONTROLBUTTON_TYPES, MAINTENANCEPLAN, BREADCRUMBCUSTOMIZED_TYPES, GENERAL, TRANS} from '../../store/actions/general.actions';
import BreadcrumbCustomized from './BreadcrumbCustomized';
import { Trans, withTranslation, getI18n } from 'react-i18next';
import Can from '../../store/identity/Can';
import { MEMBERSHIP_TYPE } from '../../store/identity/identity';
import 'font-awesome/css/font-awesome.min.css';
import { CSVLink } from "react-csv";


class CardWithHeader extends Component {
    state = {
        
    }
    render() {
        //console.log('tttt', this.props);
        const { children, level, breadcrumb_object = null, controlButtons = null, activeCompanyTag = true, t } = this.props;
        return (
            <ContentWrapper>
                <Col md={12}>
                    <Card className="card-default">
                        <CardHeader>
                            {
                                breadcrumb_object && breadcrumb_object !== null &&
                                getBreadcrumb(level, breadcrumb_object, activeCompanyTag, t)
                            }
                            <div className='input-group'>
                                {
                                    controlButtons && controlButtons !== null &&
                                    getControlButtons(controlButtons.contentType, controlButtons.buttons, controlButtons.metaData)
                                }
                            </div>
                            
                        </CardHeader>
                        <CardBody>{children}</CardBody>
                    </Card>
                </Col>
            </ContentWrapper>
        );
    }
}
export default withTranslation('translations')(CardWithHeader);
//export default CardWithHeader;

//const getTextWithTrans = (text) => {
//    const i18n = getI18n();

//    switch (text) {
//        case 'COMPANIES': return i18n.t('COMPANY.PLURAL');
//        case 'COMPANY': return i18n.t('COMPANY.SINGULAR');
//        case 'ADD_NEW_COMPANY': return i18n.t('COMPANY.ADD_NEW');
//        case 'PLANTS': return i18n.t('PLANT.PLURAL');
//        case 'PLANT': return i18n.t('PLANT.SINGULAR');
//        case 'ADD_NEW_PLANT': return i18n.t('PLANT.ADD_NEW');
//        case 'PLANTPARTS': return i18n.t('PLANTPART.PLURAL');
//        case 'PLANTPART': return i18n.t('PLANTPART.SINGULAR');
//        case 'ADD_NEW_PLANTPART': return i18n.t('PLANTPART.ADD_NEW');

//        case 'DEVICES': return i18n.t('DEVICE.PLURAL');
//        case 'DEVICE': return i18n.t('DEVICE.SINGULAR');
//        case 'ADD_NEW_DEVICE': return i18n.t('DEVICE.ADD_NEW');

//        case 'USERS': return i18n.t('general.HEADER.USER.USERS');
//        case 'USER': return i18n.t('general.HEADER.USER.USER');
//        case 'ADD_NEW_USER': return i18n.t('general.HEADER.USER.ADD_NEW_USER');
//        case 'USERGROUPS': return i18n.t('general.HEADER.USERGROUP.USERGROUPS');
//        case 'USERGROUP': return i18n.t('general.HEADER.USERGROUP.USERGROUP');
//        case 'DATAPOINT': return i18n.t('general.HEADER.DATAPOINT.DATAPOINT');
//        case 'ADD_NEW_DATAPOINT': return i18n.t('general.HEADER.DATAPOINT.ADD_NEW_DATAPOINT');
//        case 'MAINTENANCEPLAN': return i18n.t('general.HEADER.MAINTENANCEPLAN.MAINTENANCEPLAN');
//        case 'ADD_NEW_MAINTENANCEPLAN': return i18n.t('general.HEADER.MAINTENANCEPLAN.ADD_NEW_MAINTENANCEPLAN');
//        case 'MAINTENANCEPLAN_OF_DEVICE': return i18n.t('general.HEADER.MAINTENANCEPLAN.MAINTENANCEPLAN_OF_DEVICE');
//    }

//}

const getBreadcrumb = (level, breadcrumb_object, activeCompanyTag, t) => {
   
    //console.log("level__", t('dashboard.HELLO'));
    //const i18n = getI18n();
    //console.log("breadcrumb_object1", i18n.t('general.HEADER.USER.USERS'));
    const userGroupsTitle = () => <Trans i18nKey="" >User Groups</Trans>;
    const usersTitle = () => <Trans i18nKey="" >Users</Trans>;
    const rolesTitle = () => <Trans i18nKey="" >Roles</Trans>;
    const rightsTitle = () => <Trans i18nKey="" >Rights</Trans>;
    console.log("levellevel", level);

    const backToAllPreMPTag = <BreadcrumbItem> <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_PreMPList(breadcrumb_object.clickedProductID, breadcrumb_object.clickedProductName); return false; }}>
        {t(TRANS.PREDEFINEDMAINTENANCEPLAN.BACK_TO_ALL)}</a></BreadcrumbItem>;
    
    const backToAllHowToTag = <BreadcrumbItem> <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_HowToList(); return false; }}>
        {t(TRANS.HOWTO.BACK_TO_ALL)}</a></BreadcrumbItem>;

    const backToAllReportTag = <BreadcrumbItem > <a href='#'
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_ReportList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName); return false; }}>
        {t(TRANS.REPORT_MANAGER.BACK_TO_ALL)}</a></BreadcrumbItem>;

    const backToAllProductTag = <BreadcrumbItem> <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_ProductList(); return false; }}>
        {t(TRANS.PRODUCT.BACK_TO_ALL)}</a></BreadcrumbItem>;

    const productTag = <BreadcrumbItem> <a href="#" onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_ProductList(); return false; }}>
        {t(TRANS.PRODUCT.SINGULAR)}: </a>{breadcrumb_object.clickedProductName}</BreadcrumbItem>;

    const backToAllUserGroupTag = <BreadcrumbItem> <a href='#' onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_UserGroupList(); return false; }}>
        {t(TRANS.USERGROUP.BACK_TO_ALL)}</a></BreadcrumbItem>;

    const backToAllUserTag = <BreadcrumbItem> <a href='#' onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_UserList(); return false; }}>
        {t(TRANS.USER.BACK_TO_USER)}</a></BreadcrumbItem>;

    const backToMainAdminPage = <BreadcrumbItem> <a href='#' onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_MainAdminPage(); return false; }}>
        {t(TRANS.ADMINISTRATION.BACK_TO_MAIN_ADMIN_PAGE)}</a></BreadcrumbItem>;
    
    const emptyTag = <BreadcrumbItem active></BreadcrumbItem>;
    const firmaTag = <BreadcrumbItem >
        {activeCompanyTag &&
            <a href='#' onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_FirmaList(); return false; }}>
                {t(TRANS.COMPANY.SINGULAR)}: </a>
        }
        {!activeCompanyTag && <a>{t(TRANS.COMPANY.SINGULAR)}: </a>}
        {breadcrumb_object.clickedFirmaName}</BreadcrumbItem>;

    const firmaTag2 = <BreadcrumbItem >
        {
            activeCompanyTag &&
            <span style={{ color: '#0000EE' }}>{t(TRANS.COMPANY.SINGULAR) + ' : '}</span>
        }
        {
            !activeCompanyTag && <span style={{ color: '#0000EE' }}>{t(TRANS.COMPANY.SINGULAR) + ' : '} </span>
        }
        {breadcrumb_object.clickedFirmaName}</BreadcrumbItem>;

    const backToAllFirmaTag = <BreadcrumbItem > <a href='#' onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_FirmaList(); return false; }}>
        {t(TRANS.COMPANY.BACK_TO_ALL)}</a></BreadcrumbItem>;

    //const backToAllReportTag = <BreadcrumbItem > <a href='#' onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_ReportList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName); return false; }}>
    //    {t(TRANS.REPORT_MANAGER.BACK_TO_ALL)}</a></BreadcrumbItem>;

    const backToAllUsersTag = <BreadcrumbItem > <a href='#' onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_UserList(); return false; }}>
        {t(TRANS.USER.BACK_TO_USER)}</a></BreadcrumbItem>;

    const plantTag = <BreadcrumbItem> <a href="#" onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_PlantList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName); return false; }}>
        {t(TRANS.PLANT.SINGULAR)}: </a>{breadcrumb_object.clickedPlantName}</BreadcrumbItem>;
    const plantTag2 = <BreadcrumbItem> <span style={{ color: '#0000EE' }}>
        {t(TRANS.PLANT.SINGULAR) + ' : '} </span>{breadcrumb_object.clickedPlantName}</BreadcrumbItem>;
    const backToAllPlantTag = <BreadcrumbItem> <a href="#" onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_PlantList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName); return false; }}>
        {t(TRANS.PLANT.BACK_TO_ALL)} </a></BreadcrumbItem>;
    const plantPartTag = <BreadcrumbItem> <a href="#" onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_PlantPartList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName, breadcrumb_object.clickedPlantID, breadcrumb_object.clickedPlantName, breadcrumb_object.clickedBGAPlantName); return false; }}>
        {t(TRANS.PLANTPART.SINGULAR)}: </a> {breadcrumb_object.clickedPlantPartName}</BreadcrumbItem>;

    const maintenancePlanViewTag = <BreadcrumbItem> <a href="#" onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_View_MaintenancePlan(breadcrumb_object.clickedMaintenancePlanID, breadcrumb_object.clickedMaintenancePlanName, breadcrumb_object.clickedDeviceID, breadcrumb_object.clickedDeviceName, breadcrumb_object.clickedDeviceID === -1 ? BREADCRUMB_LEVELS.MAINTENANCEPLAN_PLANTPART_DETAILS : BREADCRUMB_LEVELS.MAINTENANCEPLAN_DEVICE_DETAILS); return false; }}>
        {t(TRANS.MAINTENANCEPLAN.SINGULAR)}: </a> {breadcrumb_object.clickedMaintenancePlanName}</BreadcrumbItem>;

    const backToAllPlantPartTag = <BreadcrumbItem> <a href="#" onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_PlantPartList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName, breadcrumb_object.clickedPlantID, breadcrumb_object.clickedPlantName, breadcrumb_object.clickedBGAPlantName); return false; }}>
        {t(TRANS.PLANTPART.BACK_TO_ALL)} </a></BreadcrumbItem>;
    const deviceTag = <BreadcrumbItem> <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_DeviceList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName, breadcrumb_object.clickedPlantID, breadcrumb_object.clickedPlantName, breadcrumb_object.clickedBGAPlantName, breadcrumb_object.clickedPlantPartID, breadcrumb_object.clickedPlantPartName); return false; }}>
        {t(TRANS.DEVICE.SINGULAR)}: </a> {breadcrumb_object.clickedDeviceName}</BreadcrumbItem>;
    const backToAllDeviceTag = <BreadcrumbItem> <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_DeviceList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName, breadcrumb_object.clickedPlantID, breadcrumb_object.clickedPlantName, breadcrumb_object.clickedBGAPlantName, breadcrumb_object.clickedPlantPartID, breadcrumb_object.clickedPlantPartName); return false; }}>
        {t(TRANS.DEVICE.BACK_TO_ALL)}</a></BreadcrumbItem>;
    const backToAllDatapointTag = <BreadcrumbItem> <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_DataPointList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName, breadcrumb_object.clickedPlantID, breadcrumb_object.clickedPlantName, breadcrumb_object.clickedBGAPlantName, breadcrumb_object.clickedPlantPartID, breadcrumb_object.clickedPlantPartName, breadcrumb_object.clickedDeviceID, breadcrumb_object.clickedDeviceName); return false; }}>
        {t(TRANS.DATAPOINT.BACK_TO_ALL)}
                </a></BreadcrumbItem>;
    const backToAllDatapointTag_PlantPart = <BreadcrumbItem> <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_DataPointList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName, breadcrumb_object.clickedPlantID, breadcrumb_object.clickedPlantName, breadcrumb_object.clickedBGAPlantName, breadcrumb_object.clickedPlantPartID, breadcrumb_object.clickedPlantPartName, breadcrumb_object.clickedDeviceID, breadcrumb_object.clickedDeviceName); return false; }}>
        {t(TRANS.DATAPOINT.BACK_TO_ALL)}
                </a></BreadcrumbItem>;
    const allPlantPartDevicesTag = <BreadcrumbItem> <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_DeviceList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName, breadcrumb_object.clickedPlantID, breadcrumb_object.clickedPlantName, breadcrumb_object.clickedBGAPlantName, breadcrumb_object.clickedPlantPartID, breadcrumb_object.clickedPlantPartName); return false; }}>
        {t(TRANS.DEVICE.ALL_DEVICE_IN_PLANTPART)}</a></BreadcrumbItem>;
    const backToAllMP_PP = <BreadcrumbItem>  <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_MPList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName, breadcrumb_object.clickedPlantID, breadcrumb_object.clickedPlantName, breadcrumb_object.clickedBGAPlantName, breadcrumb_object.clickedPlantPartID, breadcrumb_object.clickedPlantPartName, breadcrumb_object.maintenancePlanType); return false; }}>
        {t(TRANS.MAINTENANCEPLAN.BACK_ALL_MP_PLANTPART)}
    </a></BreadcrumbItem>;
    const backToAllMP_AllDevices = <BreadcrumbItem>  <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_MPList(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName, breadcrumb_object.clickedPlantID, breadcrumb_object.clickedPlantName, breadcrumb_object.clickedBGAPlantName, breadcrumb_object.clickedPlantPartID, breadcrumb_object.clickedPlantPartName, breadcrumb_object.maintenancePlanType); return false; }}>
        {t(TRANS.MAINTENANCEPLAN.BACK_ALL_MP_ALL_DEVICE)}
    </a></BreadcrumbItem>;
    const backToAllMP_Device = <BreadcrumbItem>  <a href="#"
        onClick={(event) => { event.preventDefault(); breadcrumb_object.onClick_Breadcrumb_GoTo_MPList_OfDevice(breadcrumb_object.clickedFirmaID, breadcrumb_object.clickedFirmaName, breadcrumb_object.clickedPlantID, breadcrumb_object.clickedPlantName, breadcrumb_object.clickedBGAPlantName, breadcrumb_object.clickedPlantPartID, breadcrumb_object.clickedPlantPartName, breadcrumb_object.maintenancePlanType, breadcrumb_object.clickedDeviceID, breadcrumb_object.clickedDeviceName); return false; }}>
        {t(TRANS.MAINTENANCEPLAN.BACK_ALL_MP_DEVICE)}
    </a></BreadcrumbItem>;

    switch (level) {
        //1,2,3
        case BREADCRUMB_LEVELS.FIRMA:
            return <BreadcrumbCustomized title={t(TRANS.COMPANY.PLURAL)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.FIRMA_ADD:
            return <BreadcrumbCustomized title={t(TRANS.COMPANY.ADD_NEW)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{emptyTag}{backToAllFirmaTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.FIRMA_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.COMPANY.SINGULAR) + ": " + breadcrumb_object.clickedFirmaName} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>{emptyTag}{backToAllFirmaTag}</BreadcrumbCustomized>;
        //4,5,6
        case BREADCRUMB_LEVELS.PLANT:
            return <BreadcrumbCustomized title={t(TRANS.PLANT.PLURAL)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{firmaTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PLANT_ADD:
            return <BreadcrumbCustomized title={t(TRANS.PLANT.ADD_NEW)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{firmaTag}{backToAllPlantTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PLANT_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.PLANT.SINGULAR) + ": " + breadcrumb_object.clickedPlantName} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>{firmaTag}{backToAllPlantTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PLANT_REPORTING:
            return <BreadcrumbCustomized title={t(TRANS.PLANT.MP_LIST)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        //7,8,9
        case BREADCRUMB_LEVELS.PLANTPART:
            return <BreadcrumbCustomized title={t(TRANS.PLANTPART.PLURAL)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{firmaTag}{plantTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PLANTPART_ADD:
            return <BreadcrumbCustomized title={t(TRANS.PLANTPART.ADD_NEW)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{firmaTag}{plantTag}{backToAllPlantPartTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PLANTPART_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.PLANTPART.SINGULAR) + ": " + breadcrumb_object.clickedPlantPartName
            } type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS} > {firmaTag}{plantTag} {backToAllPlantPartTag}</BreadcrumbCustomized >;
        //10,11,12 & 22,23,24
        case BREADCRUMB_LEVELS.DATAPOINT_PLANTPART_LIST:
            return <BreadcrumbCustomized title={t(TRANS.DATAPOINT.DATAPOINTS_OF_PLANTPART)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST} > {firmaTag}{plantTag}{plantPartTag}</ BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.DATAPOINT_PLANTPART_ADD:
            return <BreadcrumbCustomized title={t(TRANS.DATAPOINT.ADD_NEW_TO_PLANTPART)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{firmaTag}{plantTag}{plantPartTag}{backToAllDatapointTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.DATAPOINT_PLANTPART_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.DATAPOINT.SINGULAR) + ": " + breadcrumb_object.clickedDataPointName
    } type = { BREADCRUMBCUSTOMIZED_TYPES.DETAILS } > { firmaTag }{ plantTag } { plantPartTag } { backToAllDatapointTag }</BreadcrumbCustomized >;

        case BREADCRUMB_LEVELS.DATAPOINT_DEVICE_LIST:
            return <BreadcrumbCustomized title={t(TRANS.DATAPOINT.DATAPOINTS_OF_DEVICE)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST} > {firmaTag}{plantTag}{plantPartTag}{deviceTag}</ BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.DATAPOINT_DEVICE_ADD:
            return <BreadcrumbCustomized title={t(TRANS.DATAPOINT.ADD_NEW_TO_DEVICE)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{firmaTag}{plantTag}{plantPartTag}{deviceTag}{backToAllDatapointTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.DATAPOINT_DEVICE_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.DATAPOINT.SINGULAR) + ": " + breadcrumb_object.clickedDataPointName
    } type = { BREADCRUMBCUSTOMIZED_TYPES.DETAILS } > { firmaTag }{ plantTag } { plantPartTag } { deviceTag } { backToAllDatapointTag }</BreadcrumbCustomized >;

        //13,14,15 & 16,17,18 & 25,26,27
        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_PLANTPART_LIST:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.PLANTPART_MAINTENANCEPLANS)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST} > {firmaTag}{plantTag}{plantPartTag}</ BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_PLANTPART_ADD:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.ADD_MAINTENANCEPLAN_TO_PLANTPART)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD} > {firmaTag}{plantTag}{plantPartTag}{backToAllMP_PP}</ BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_PLANTPART_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.SINGULAR) + ": " + breadcrumb_object.maintenancePlan.name} type = { BREADCRUMBCUSTOMIZED_TYPES.DETAILS } >
                {firmaTag}{plantTag}{plantPartTag}{backToAllMP_PP}
            </BreadcrumbCustomized>;

        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_ALLDEVICE_LIST:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.ALL_DEVICES_MAINTENANCEPLANS)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>
                {firmaTag}{plantTag}{plantPartTag}{allPlantPartDevicesTag}
            </ BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_ALLDEVICE_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.SINGULAR) + ": " + breadcrumb_object.maintenancePlan.name} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>
                {firmaTag}{plantTag}{plantPartTag}{deviceTag}{backToAllMP_AllDevices}
            </BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_ALLDEVICE_ADD:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.ADD_NEW_MAINTENANCEPLAN)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>
                {firmaTag}{plantTag}{plantPartTag}{backToAllMP_AllDevices}</BreadcrumbCustomized>;

        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_DEVICE_LIST:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.DEVICE_MAINTENANCEPLANS)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST} > {firmaTag}{plantTag}{plantPartTag}{deviceTag}</ BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_DEVICE_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.SINGULAR) + ": " + breadcrumb_object.maintenancePlan.name} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>
                {firmaTag}{plantTag}{plantPartTag}{deviceTag}{backToAllMP_Device}
            </BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_DEVICE_ADD:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.ADD_MAINTENANCEPLAN_TO_DEVICE)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>
                {firmaTag}{plantTag}{plantPartTag}{deviceTag}{backToAllMP_Device}</BreadcrumbCustomized>;

        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_DEVICE_CLOSE:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.SINGULAR) + ": " + breadcrumb_object.maintenancePlan.name} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>
                {firmaTag}{plantTag}{plantPartTag}{deviceTag}{maintenancePlanViewTag}
            </BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_DEVICE_STOP:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.SINGULAR) + ": " + breadcrumb_object.maintenancePlan.name} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>
                {firmaTag}{plantTag}{plantPartTag}{deviceTag}{maintenancePlanViewTag}
            </BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_PLANTPART_CLOSE:
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.SINGULAR) + ": " + breadcrumb_object.maintenancePlan.name} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>
                {firmaTag}{plantTag}{plantPartTag}{maintenancePlanViewTag}
            </BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.MAINTENANCEPLAN_PLANTPART_STOP: 
            return <BreadcrumbCustomized title={t(TRANS.MAINTENANCEPLAN.SINGULAR) + ": " + breadcrumb_object.maintenancePlan.name} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>
                {firmaTag}{plantTag}{plantPartTag}{maintenancePlanViewTag}
            </BreadcrumbCustomized>;

        //19,20,21
        case BREADCRUMB_LEVELS.DEVICE:
            return <BreadcrumbCustomized title={t(TRANS.DEVICE.PLURAL)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST} > {firmaTag}{plantTag}{plantPartTag}</ BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.DEVICE_ADD:
            return <BreadcrumbCustomized title={t(TRANS.DEVICE.ADD_NEW)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{firmaTag}{plantTag}{plantPartTag}{backToAllDeviceTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.DEVICE_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.DEVICE.SINGULAR) + ": " + breadcrumb_object.clickedDeviceName} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>{firmaTag}{plantTag}{plantPartTag}{backToAllDeviceTag}</BreadcrumbCustomized>;
        //user group 
        case BREADCRUMB_LEVELS.CONTROLS_USERGROUP:
            return <BreadcrumbCustomized title={t(TRANS.USERGROUP.PLURAL)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.CONTROL_USERGROUP_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.USERGROUP.SINGULAR) + ": " + breadcrumb_object.clickedUserGroupName} type = { BREADCRUMBCUSTOMIZED_TYPES.DETAILS } > { emptyTag }{ backToAllUserGroupTag }</BreadcrumbCustomized >;
        case BREADCRUMB_LEVELS.ROLE_LIST:
            return <BreadcrumbCustomized title={t(TRANS.ROLE.PLURAL)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.RIGHT_LIST:
            return <BreadcrumbCustomized title={t(TRANS.RIGHT.PLURAL)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        //users 
        case BREADCRUMB_LEVELS.CONTROLS_USER:
            return <BreadcrumbCustomized title={t(TRANS.USER.PLURAL)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.CONTROL_USER_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.USER.SINGULAR) + ": " + breadcrumb_object.clickedUserName} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>{emptyTag}{backToAllUserTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.CONTROL_USER_ADD:
            return <BreadcrumbCustomized title={t(TRANS.USER.ADD_NEW)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{emptyTag}{backToAllUserTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.DAILY_VALUES_REPORTS:
            return <BreadcrumbCustomized title={t(TRANS.DAILYVALUES.TITLE)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.DOCUMENT_SERVER:
            return <BreadcrumbCustomized title={t(TRANS.FILESERVER.TITLE)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.TRENDS:
            return <BreadcrumbCustomized title={t(TRANS.TRENDS.TITLE)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.REPORT_MANAGER:
            return <BreadcrumbCustomized title={t(TRANS.REPORT_MANAGER.TITLE)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{firmaTag2}{plantTag2}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.ADMINISTRATION:
            return <BreadcrumbCustomized title={t(TRANS.ADMINISTRATION.TITLE)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{ }</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.RTIP_ADDNEW_PLANT:
            return <BreadcrumbCustomized title={t(TRANS.ADMINISTRATION.RTIP_ADDNEW_PLANT)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{backToMainAdminPage}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.RTIP_ADDNEW_PW:
            return <BreadcrumbCustomized title={t(TRANS.ADMINISTRATION.RTIP_ADDNEW_PW)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{backToMainAdminPage}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.RTIP_ADDNEW_PTW:
            return <BreadcrumbCustomized title={t(TRANS.ADMINISTRATION.RTIP_ADDNEW_PTW)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{backToMainAdminPage}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.RTIP_ADDNEW_BS:
            return <BreadcrumbCustomized title={t(TRANS.ADMINISTRATION.RTIP_ADDNEW_BS)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{backToMainAdminPage}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.HOWTO:
            return <BreadcrumbCustomized title={t(TRANS.HOWTO.TITLE)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.HOWTO_ADD:
            return <BreadcrumbCustomized title={t(TRANS.HOWTO.ADD_NEW)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{backToAllHowToTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.HOWTO_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.HOWTO.SINGULAR) + ": " + breadcrumb_object.clickedHowToTitle} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>{backToAllHowToTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PRODUCT:
            return <BreadcrumbCustomized title={t(TRANS.PRODUCT.PLURAL)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{emptyTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PRODUCT_ADD:
            return <BreadcrumbCustomized title={t(TRANS.PRODUCT.ADD_NEW)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{backToAllProductTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PRODUCT_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.PRODUCT.SINGULAR) + ": " + breadcrumb_object.clickedProductName} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>{backToAllProductTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PREDEFINEDMAINTENANCEPLAN:
            return <BreadcrumbCustomized title={t(TRANS.PREDEFINEDMAINTENANCEPLAN.PLURAL)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST}>{productTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PREDEFINEDMAINTENANCEPLAN_ADD:
            return <BreadcrumbCustomized title={t(TRANS.PREDEFINEDMAINTENANCEPLAN.ADD_NEW)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{productTag}{backToAllPreMPTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PREDEFINEDMAINTENANCEPLAN_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.PREDEFINEDMAINTENANCEPLAN.SINGULAR) + ": " + breadcrumb_object.clickedPreMPName} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>{productTag}{backToAllPreMPTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.PORTALEMAIL:
            return <BreadcrumbCustomized title={t(TRANS.PORTALEMAIL.TITLE)} type={BREADCRUMBCUSTOMIZED_TYPES.LIST} > {emptyTag}</BreadcrumbCustomized >;
        case BREADCRUMB_LEVELS.REPORT_ADD:
            return <BreadcrumbCustomized title={t(TRANS.REPORT_MANAGER.ADD_NEW)} type={BREADCRUMBCUSTOMIZED_TYPES.ADD}>{emptyTag}{backToAllReportTag}</BreadcrumbCustomized>;
        case BREADCRUMB_LEVELS.REPORT_DETAILS:
            return <BreadcrumbCustomized title={t(TRANS.REPORT_MANAGER.SINGULAR) + ": " + breadcrumb_object.clickedReportName} type={BREADCRUMBCUSTOMIZED_TYPES.DETAILS}>{backToAllReportTag}</BreadcrumbCustomized>;
        default:
            return <p>No Breadcrumb!</p>; 
    }
}

const getControlButtons = (ContentType, ListOfButtons, ListOfButtonMetaData) => {
    if (!ListOfButtons || ListOfButtons.length === 0)
        return [];
    const list = ListOfButtons.map((record, index) => {
        if (record === CONTROLBUTTON_TYPES.ADD)
            return getButton(index, record, ListOfButtonMetaData[index]);
        if (record === CONTROLBUTTON_TYPES.FILTER)
            return getCombo(index, record, ListOfButtonMetaData[index]);
        if (record === CONTROLBUTTON_TYPES.EDIT)
            return getButton(index, record, ListOfButtonMetaData[index]);
        if (record === CONTROLBUTTON_TYPES.DELETE)
            return getButton(index, record, ListOfButtonMetaData[index]);
        if (record === CONTROLBUTTON_TYPES.LIST_OF) {
            console.log("CONTROLBUTTON_TYPES.LIST_OF");
            return getButton(index, record, ListOfButtonMetaData[index]);
        }
        if (record === CONTROLBUTTON_TYPES.LINKTO)
            return getButton_LinkTo(index, ListOfButtonMetaData[index]);
        if (record === CONTROLBUTTON_TYPES.SEARCH)
            return getTextBox(index, ListOfButtonMetaData[index]);
        if (record === CONTROLBUTTON_TYPES.SAVE_AS_CSV)
            return getButton_CSV_Save(index, ListOfButtonMetaData[index]);
    })
    return list;
}

const getButton_CSV_Save = (index, MetaData) => {
    console.log("MetaDataMetaData", MetaData);
    console.log("MetaDataMetaData", MetaData.headers.csv_header);
    return <CSVLink
        key={index}
        data={MetaData.data}
        filename={MetaData.filename}
        headers={MetaData.headers}
        className="btn btn-outline-secondary mx-2"
        title={MetaData.title}
    >
        <i className="fas fa-save fa-2x"></i>
    </CSVLink>
}

const getButton = (index, name, MetaData) => {
    return <Can
        type={MetaData.type}
        permissionID={MetaData.permissionID}
        plantID={MetaData.plantID}
        key={index}
        yes={() => (
            <Button type="button"
                className="btn btn-outline-secondary mx-2"
                onClick={() => MetaData.onClick()}
                title={MetaData.title}>
                <i className={getIcon(name)}></i>
            </Button>)}
        
    />
}

//<span className={"fa-stack"} style={{ left: 0 }}>
//    <i className={MetaData.icon + " fa-stack-1x"} ></i>
//    <i className={"fas fa-slash fa-stack-1x"} style={{ color: "Tomato" }} ></i>
//</span>
const getButton_LinkTo = (index, MetaData) => {
    return <Button type="button"
        key={index}
        className="btn btn-outline-secondary mx-2"
        onClick={() => MetaData.onClick()}
        title={MetaData.title}>
        <i className={MetaData.icon + '  fa-2x'}></i>
    </Button>
        ;
}

const getTextBox = (index, MetaData) => {
    return <Input type="text"
        style={{ fontFamily: 'FontAwesome, system-ui' }}
        key={"search" + index}
        id={"search" + index}
        placeholder="&#xF002;"
        
        onChange={e => MetaData.onChange(e.currentTarget.value)}
        bsSize="lg" />;
}

const getCombo = (index, name, MetaData) => {
    const FilterText_All = GENERAL.MAINTENANCEPLAN.getFilterText(MAINTENANCEPLAN.FILTER.ALL, MetaData.Active + MetaData.VeryClose + MetaData.Passed + MetaData.InactivePeriod + MetaData.InactiveNonPeriod);
    const FilterText_Active = GENERAL.MAINTENANCEPLAN.getFilterText(MAINTENANCEPLAN.FILTER.ACTIVE, MetaData.Active);
    const FilterText_VeryClose = GENERAL.MAINTENANCEPLAN.getFilterText(MAINTENANCEPLAN.FILTER.VERYCLOSE, MetaData.VeryClose);
    const FilterText_Passed = GENERAL.MAINTENANCEPLAN.getFilterText(MAINTENANCEPLAN.FILTER.PASSED, MetaData.Passed);
    const FilterText_InactivePer = GENERAL.MAINTENANCEPLAN.getFilterText(MAINTENANCEPLAN.FILTER.INACTIVE_PERIODIC, MetaData.InactivePeriod);
    const FilterText_InactiveNonPer = GENERAL.MAINTENANCEPLAN.getFilterText(MAINTENANCEPLAN.FILTER.INACTIVE_NON_PERIODIC, MetaData.InactiveNonPeriod);

    return <Dropdown isOpen={MetaData.IsStatusFilterOpen} toggle={MetaData.toggleFilterStatus} key={index}
        className="mx-2 h-100">
        <DropdownToggle style={{ fontSize: '20px' }}>
            {MetaData.FilterStatusSelectedText}
        </DropdownToggle>
        <DropdownMenu
        >
            <DropdownItem key="1" onClick={() => MetaData.onChange(FilterText_All, MAINTENANCEPLAN.FILTER.ALL)}>
                {FilterText_All}
            </DropdownItem>
            <DropdownItem key="2" onClick={() => MetaData.onChange(FilterText_Active, MAINTENANCEPLAN.STATUS.ACTIVE)}>
                {FilterText_Active}
            </DropdownItem>
            <DropdownItem key="3" onClick={() => MetaData.onChange(FilterText_VeryClose, MAINTENANCEPLAN.STATUS.VERYCLOSE)}>
                {FilterText_VeryClose}
            </DropdownItem>
            <DropdownItem key="4" onClick={() => MetaData.onChange(FilterText_Passed , MAINTENANCEPLAN.STATUS.PASSED)}>
                {FilterText_Passed}
            </DropdownItem>
            <DropdownItem key="5" onClick={() => MetaData.onChange(FilterText_InactivePer, MAINTENANCEPLAN.STATUS.INACTIVE_PERIODIC)}>
                {FilterText_InactivePer}
            </DropdownItem>
            <DropdownItem key="6" onClick={() => MetaData.onChange(FilterText_InactiveNonPer, MAINTENANCEPLAN.STATUS.INACTIVE_NON_PERIODIC)}>
                {FilterText_InactiveNonPer}
            </DropdownItem>
        </DropdownMenu>
    </Dropdown>;
}

const getIcon = (name) => {
    switch (name) {
        case CONTROLBUTTON_TYPES.ADD:
            return 'fas fa-plus fa-2x';
        case CONTROLBUTTON_TYPES.FILTER:
            return 'ERROR IN ICON';
        case CONTROLBUTTON_TYPES.EDIT:
            return 'far fa-edit fa-2x';
        case CONTROLBUTTON_TYPES.DELETE:
            return 'far fa-trash-alt fa-2x';
        case CONTROLBUTTON_TYPES.SAVE_AS_CSV:
            return 'fas fa-save fa-2x';
        case CONTROLBUTTON_TYPES.LIST_OF:
            return 'fas fa-save fa-2x';
        default:
            return '';
    }
}