import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';
import mainContentReducer from './mainContent.reducer';
import acUser from './activeUser.reducer';

export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    mainContent: mainContentReducer,
    activeUser: acUser,
});
