import { apiAddNew, apiDeleteByID, apiEdit, apiGetFieldByGUID_withTargetName, api_getIfCannBeBHKW, api_GetSammariesOfChildren, api_getTargetActualSummary_values } from "./actions"
import { PLANT, PLANTPART, TARGETACTUALSUMMARY } from "./general.actions"





//(ActionType, tableName, guid, fieldName, TargetName)
export const summary_getByDeviceGUID_withTarget = (relatedGuid, TargetName ) => {
    return apiGetFieldByGUID_withTargetName(
        TARGETACTUALSUMMARY.ACTION_TYPES.FETCH_BY_DEVICE_ID,
        TARGETACTUALSUMMARY.TAB_NAME,
        relatedGuid,
        'DeviceID',
        TargetName
    )
}


export const summary_getByPlantPartGUID_withTarget = (relatedGuid, TargetName) => {
    return apiGetFieldByGUID_withTargetName(
        TARGETACTUALSUMMARY.ACTION_TYPES.FETCH_BY_DEVICE_ID,
        TARGETACTUALSUMMARY.TAB_NAME,
        relatedGuid,
        'PlantPartID',
        TargetName
    )
}

//(ActionType, tableName, fatherType, fatherGUID, comTypeID, TargetName)
export const summary_getListOfChildrenSummaries_PLANTPART_withTarget = (fatherGUID, comTypeID, TargetName) => {
    return api_GetSammariesOfChildren(
        TARGETACTUALSUMMARY.ACTION_TYPES.GET_LIST_OF_CHILDREN,
        TARGETACTUALSUMMARY.TAB_NAME,
        PLANTPART.NAME,
        fatherGUID,
        comTypeID,
        TargetName
    )
}

export const summary_getListOfChildrenSummaries_PLANT_withTarget = (fatherGUID, comTypeID,TargetName) => {
    return api_GetSammariesOfChildren(
        TARGETACTUALSUMMARY.ACTION_TYPES.GET_LIST_OF_CHILDREN,
        TARGETACTUALSUMMARY.TAB_NAME,
        PLANT.NAME,
        fatherGUID,
        comTypeID,
        TargetName
    )
}


export const summary_create = (summary) => {
    return apiAddNew(
        TARGETACTUALSUMMARY.ACTION_TYPES.CREATE,
        TARGETACTUALSUMMARY.TAB_NAME,
        summary
    );
}


export const summary_edit = (guid, summary) => {
    return apiEdit(
        TARGETACTUALSUMMARY.ACTION_TYPES.UPDATE,
        TARGETACTUALSUMMARY.TAB_NAME,
        guid,
        summary
    );
}

export const summary_delete = (guid) => {
    return apiDeleteByID(
        TARGETACTUALSUMMARY.ACTION_TYPES.DELETE,
        TARGETACTUALSUMMARY.TAB_NAME,
        guid
    );
}
// api_getTargetActualSummary_values = (ActionType, tableName, bgaName, listOfDataPoints , TargetName)
export const summary_getValuesOfSummary = (bgaName, summaryGUID, TargetName) => {
    return api_getTargetActualSummary_values(
        TARGETACTUALSUMMARY.ACTION_TYPES.GET_VALUES_OF_TARGETACTUALSUMMAY,
        TARGETACTUALSUMMARY.TAB_NAME,
        bgaName,
        summaryGUID,
        TargetName
    )
}
//api_getIfCannBeBHKW = (ActionType, tableName, type, guid, TargetName) 
export const summary_getIfCannBeBHKW = (type, guid, TargetName) => {
    return api_getIfCannBeBHKW(
        TARGETACTUALSUMMARY.ACTION_TYPES.GET_IF_CAN_BE_BHKW,
        TARGETACTUALSUMMARY.TAB_NAME,
        type,
        guid,
        TargetName
    )
}