import { baseUrl, SYSTEMUSER, USERGROUP } from './general.actions';
import { apiGet, apiAddNew, apiDeleteByID, apiGetFirmaMPStatistics, apiGetByGUID } from './api.actions';
import {
    apiGetByID_withTarget, apiGetAllUsers_By_UserID, apiGet_EmailList_withTarget,
    apiGetAllUsers_By_UserID_And_FirmaID, apiGetAllUsersCount_By_UserID_And_FirmaID_WithTarget, apiGetAllFirmasCount_ForUsers_Dic_WithTarget, apiUserAsSuperAdmin, apiUser_SuperAdmin, apiUser_SystemAdmin, apiUser_Consultant, apiEdit, api_passRecover, apiGetFieldByGUID_withTargetName, apiGet_withTarget
} from './actions.js';



export const user_getEmailList_withTarget = (userGroup, includeSuperAdmin,includeConsultants, listOfFirmaGUID, TargetName) => {
    return apiGet_EmailList_withTarget(
        SYSTEMUSER.ACTION_TYPES.FETCH_EMAILS_BY_USERGROUP_AND_FIRMA,
        SYSTEMUSER.TAB_NAME,
        userGroup,
        includeSuperAdmin,
        includeConsultants,
        listOfFirmaGUID,
        TargetName
    );
}


export const user_fetchallByFirmaID_withTarget = (FirmaID, TargetName) => {
    console.log("user_fetchallByFirmaID_withTarget ", TargetName);
    return apiGetByID_withTarget(
        SYSTEMUSER.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID,
        SYSTEMUSER.TAB_NAME,
        FirmaID,
        SYSTEMUSER.NAME,
        TargetName
    );
}

export const user_fetchall_ByUserIDPermissions = userID => {
    return apiGetAllUsers_By_UserID(
        SYSTEMUSER.ACTION_TYPES.FETCH_ALL,
        SYSTEMUSER.TAB_NAME,
        userID
    );
}

export const user_getUsernameByGUID_withTarget = (guid,TargetName) => {
    return apiGetFieldByGUID_withTargetName(
        SYSTEMUSER.ACTION_TYPES.FETCH_BY_GUID,
        SYSTEMUSER.TAB_NAME,
        guid,
        "username",
        TargetName
    )
}
//export const user_fetchall_ByUserIDPermissions_forOneFirma = (userID,firmaID) => {
//    return apiGetAllUsers_By_UserID_And_FirmaID(
//        SYSTEMUSER.ACTION_TYPES.FETCH_ALL,
//        SYSTEMUSER.TAB_NAME,
//        userID,
//        firmaID
//    );
//}

//apiGetAllUsersCount_By_UserID_And_FirmaID_WithTarget = (ActionType, tableName, userID, firmaID, TargetName) => ({
export const user_fetchall_ByUserIDPermissions_forOneFirma_Count = (userID, TargetName) => {
    return apiGetAllUsersCount_By_UserID_And_FirmaID_WithTarget(
        SYSTEMUSER.ACTION_TYPES.COUNT_USERS_IN_COMPANY,
        SYSTEMUSER.TAB_NAME,
        userID,
        TargetName
    );
}




//export const plant_fetchallByFirmaID_withTarget = (FirmaID, TargetName) => {
//    return apiGetByID_withTarget(
//        PLANT.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID,
//        PLANT.TAB_NAME,
//        FirmaID,
//        PLANT.NAME,
//        TargetName
//    );
//}

export const user_create = user => {
    return apiAddNew(
        SYSTEMUSER.ACTION_TYPES.CREATE,
        SYSTEMUSER.TAB_NAME,
        user
    );
}


export const user_fetchall = TargetName => {
    return apiGet_withTarget(
        SYSTEMUSER.ACTION_TYPES.FETCH_ALL,
        SYSTEMUSER.TAB_NAME,
        TargetName
    );
}


export const user_getByGUID = guid => {
    return apiGetByGUID(
        SYSTEMUSER.ACTION_TYPES.FETCH_BY_GUID,
        SYSTEMUSER.TAB_NAME,
        guid
    )
}

export const user_delete = guid => {
    return apiDeleteByID(
        SYSTEMUSER.ACTION_TYPES.DELETE,
        SYSTEMUSER.TAB_NAME,
        guid
    );
}


export const user_setUserAsSuperAdmin = userID => {
    return apiUser_SuperAdmin(
        SYSTEMUSER.ACTION_TYPES.SET_AS_SUPERADMIN,
        SYSTEMUSER.TAB_NAME,
        userID,
        true
    );
}
export const user_setUserAsNOTSuperAdmin = userID => {
    return apiUser_SuperAdmin(
        SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_SUPERADMIN,
        SYSTEMUSER.TAB_NAME,
        userID,
        false
    );
}

export const user_setUserAsSystemAdmin = (userID, firmaID) => {
    return apiUser_SystemAdmin(
        SYSTEMUSER.ACTION_TYPES.SET_AS_SYSTEMADMIN,
        SYSTEMUSER.TAB_NAME,
        userID,
        firmaID
    );
}

export const user_setUserAsNOTSystemAdmin = (userID) => {
    return apiUser_SystemAdmin(
        SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_SYSTEMADMIN,
        SYSTEMUSER.TAB_NAME,
        userID,
        null
    );
}

export const user_setUserAsConsultant = userID => {
    return apiUser_Consultant(
        SYSTEMUSER.ACTION_TYPES.SET_AS_CONSULTANT,
        SYSTEMUSER.TAB_NAME,
        userID,
        USERGROUP.IDs.CONSULTANT_USERGROUP_ID
    )
}

export const user_setUserAsNOTConsultant = userID => {
    return apiUser_Consultant(
        SYSTEMUSER.ACTION_TYPES.SET_AS_NOT_CONSULTANT,
        SYSTEMUSER.TAB_NAME,
        userID,
        null
    )
}


//ActionType, tableName, guid ,editedFirma
export const user_edit = (guid, user) => {
    return apiEdit(
        SYSTEMUSER.ACTION_TYPES.UPDATE,
        SYSTEMUSER.TAB_NAME,
        guid,
        user
    );
}

export const user_passRecover = email => {
    return api_passRecover(
        SYSTEMUSER.ACTION_TYPES.PASS_RECOVER,
        SYSTEMUSER.TAB_NAME,
        email
    );
}