import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next  } from 'react-i18next';


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next )
    .init({
        fallbackLng: 'en',
        backend: {
            loadPath: 'locales/{{lng}}/{{ns}}.json',
            //addPath: 'locales/{{lng}}/{{ns}}.missing.json',
            addPath: 'locales/{{lng}}/{{ns}}.json',
        },
        saveMissing: true,
        saveMissingTo: 'all',
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
        debug: false,
        react: {
            wait: true,
            useSuspense: false
        },
        
        missingKeyHandler: (ng, ns, key, fallbackValue) => {
            //throw new Error(`Key not found ${key}, ${ng}, ${ns}, ${fallbackValue}`);

            //console.log("====>>>>", i18n.Backend, key, fallbackValue)
        },
            
    });


export default i18n;