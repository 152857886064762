
import { FILESERVER, PLANT} from './general.actions';
import { apiGet, apiGetByID, apiAddNew, apiDeleteByID, apiGetBGANamesAndIDs, api_addNewPlant2RTIP, api_deletePlant2RTIP} from './api.actions';
import {
    apiGetByGUID, apiGetByID_withTarget, apiEdit, apiGetByID_And_UserID,
    apiGet_withTarget, apiAddNew_Files, api_GetListofFiles_withTarget, apiDelete_Files,
    api_getDifference_ProzessWert_PWALLE_WithTarget, api_fetch_AllPW_ALLE_from_RTIP,
    api_fetch_All_RTIP_PW_HR, api_addNew_PW_HR_2_RTIP, api_addNew_PW_NOT_HR_2_RTIP, api_addNew_Tab_BW_2_RTIP, api_addNew_KeyVal2_and_PWReal,
    api_fetch_All_RTIP_PW_NOT_HR,
    api_addNew_PW_2_RTIP_PW_ALLE, api_addNew_BS_2_RTIP_BW_ALLE,
    api_getDifference_Betriebsstunden_BSALLE_WithTarget,
    api_fetch_AllBS_ALLE_from_RTIP,
    api_fetch_All_RTIP_Tab_BW,
    api_addNew_BW_KeyVal2,
    api_fetchNamesDic
} from './actions';

export const plant_fetchall = () => {
    return apiGet(
        PLANT.ACTION_TYPES.FETCH_ALL,
        PLANT.TAB_NAME);
}

export const plant_fetch_all_withTarget = TargetName => {
    return apiGet_withTarget(
        PLANT.ACTION_TYPES.FETCH_ALL,
        PLANT.TAB_NAME,
        TargetName
    )
}

// actionType, tableName, Id, name
export const plant_fetchallByFirmaID = (FirmaID) => {
    return apiGetByID(
        PLANT.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID,
        PLANT.TAB_NAME,
        FirmaID,
        PLANT.NAME
    );
}

export const plant_fetchallByFirmaID_And_UserID = (FirmaID, userID) => {
    return apiGetByID_And_UserID(
        PLANT.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID,
        PLANT.TAB_NAME,
        FirmaID,
        PLANT.NAME,
        userID
    );
}

export const plant_fetchallByFirmaID_withTarget = (FirmaID, TargetName) => {
    return apiGetByID_withTarget(
        PLANT.ACTION_TYPES.FETCH_ALL_BY_FIRMA_ID,
        PLANT.TAB_NAME,
        FirmaID,
        PLANT.NAME,
        TargetName
    );
}

//ActionType, tableName, guid ,editedFirma
export const plant_edit = (guid, plant) => {
    return apiEdit(
        PLANT.ACTION_TYPES.UPDATE,
        PLANT.TAB_NAME,
        guid,
        plant
    );
}

// ActionType, tableName, newObject
export const plant_create = (plant) => {
    return apiAddNew(
        PLANT.ACTION_TYPES.CREATE,
        PLANT.TAB_NAME,
        plant
    );
}
//apiDeleteByID = (ActionType, tableName, Id)
export const plant_delete = (guid) => {
    return apiDeleteByID(
        PLANT.ACTION_TYPES.DELETE,
        PLANT.TAB_NAME,
        guid
    );
}

export const plant_getListOfBGANamesAndIDs = () => {
    return apiGetBGANamesAndIDs(
        PLANT.ACTION_TYPES.FETCH_BGANAME_AND_IDS,
        PLANT.TAB_NAME
    )
}

export const plant_fetchByGuid = guid => {
    return apiGetByGUID(
        PLANT.ACTION_TYPES.FETCH_BY_GUID,
        PLANT.TAB_NAME,
        guid
    )
}

export const plant_add_new_file = (fileServer, plantID) => {
    return apiAddNew_Files(
        FILESERVER.ACTION_TYPES.PLANT.CREATE,
        FILESERVER.TAB_NAME,
        fileServer,
        PLANT.TAB_NAME,
        plantID
    )
}

export const plant_getListofFiles_withTarget = (guid, userID, TargetName) => {
    return api_GetListofFiles_withTarget(
        PLANT.ACTION_TYPES.FETCH_ALL_FILES,
        PLANT.TAB_NAME,
        guid,
        userID,
        TargetName
    );
}

export const plant_delete_file = (fileServerID, plantID) => {
    console.log("Check lllll");
    return apiDelete_Files(
        FILESERVER.ACTION_TYPES.PLANT.DELETE,
        FILESERVER.TAB_NAME,
        fileServerID,
        PLANT.TAB_NAME,
        plantID
    )
}

export const plant_addNewPlant2RTIP = bgaName => {

    return api_addNewPlant2RTIP(
        PLANT.ACTION_TYPES.ADD_PLANT_TO_RTIP,
        PLANT.TAB_NAME,
        bgaName
    )
}

export const plant_deletePlant2RTIP = bgaName => {

    return api_deletePlant2RTIP(
        PLANT.ACTION_TYPES.DELETE_PLANT_FROM_RTIP ,
        PLANT.TAB_NAME,
        bgaName
    )
}
//DiffProzessWertPWALLE
export const plant_getDifference_ProzessWert_PWALLE = (bgaName, TargetName) => {
    return api_getDifference_ProzessWert_PWALLE_WithTarget(
        PLANT.ACTION_TYPES.FETCH_DIFF_PROZESSWERT_PW_ALLE,
        PLANT.TAB_NAME,
        bgaName,
        TargetName
    )
}
//plant_getDifference_ProzessWert_BSALLE
export const plant_getDifference_ProzessWert_BSALLE = (bgaName, TargetName) => {
    return api_getDifference_Betriebsstunden_BSALLE_WithTarget(
        PLANT.ACTION_TYPES.FETCH_DIFF_BETRIEBSSTUNDEN_BS_ALLE,
        PLANT.TAB_NAME,
        bgaName,
        TargetName
    )
}

export const plant_fetch_AllPW_ALLE = (bgaName, TargetName) => {
    return api_fetch_AllPW_ALLE_from_RTIP(
        PLANT.ACTION_TYPES.FETCH_ALL_PW_ALLE_FROM_RTIP ,
        PLANT.TAB_NAME,
        bgaName,
        TargetName
    )
}
//plant_fetch_AllBS_ALLE
export const plant_fetch_AllBS_ALLE = (bgaName, TargetName) => {
    return api_fetch_AllBS_ALLE_from_RTIP(
        PLANT.ACTION_TYPES.FETCH_ALL_BS_ALLE_FROM_RTIP,
        PLANT.TAB_NAME,
        bgaName,
        TargetName
    )
}

export const plant_fetch_All_RTIP_Tab_BW = (TargetName) => {
    return api_fetch_All_RTIP_Tab_BW(
        PLANT.ACTION_TYPES.FETCH_ALL_RTIP_TAB_BW,
        PLANT.TAB_NAME,
        TargetName
    )
}

export const plant_fetch_All_RTIP_PW_HR = (TargetName) => {
    return api_fetch_All_RTIP_PW_HR(
        PLANT.ACTION_TYPES.FETCH_ALL_RTIP_PW_HR,
        PLANT.TAB_NAME,
        TargetName
    )
}

export const plant_fetch_All_RTIP_PW_NOT_HR = (TargetName) => {
    return api_fetch_All_RTIP_PW_NOT_HR(
        PLANT.ACTION_TYPES.FETCH_ALL_RTIP_PW_NOT_HR,
        PLANT.TAB_NAME,
        TargetName
    )
}


export const plant_addNew_PW_HR_2_RTIP = (obj) => {
    return api_addNew_PW_HR_2_RTIP(
        PLANT.ACTION_TYPES.ADD_NEW_PW_HR_2_RTIP,
        PLANT.TAB_NAME,
        obj
    )
}

export const plant_addNew_PW_NOT_HR_2_RTIP = (obj) => {
    return api_addNew_PW_NOT_HR_2_RTIP(
        PLANT.ACTION_TYPES.ADD_NEW_PW_NOT_HR_2_RTIP,
        PLANT.TAB_NAME,
        obj
    )
}

export const plant_addNew_Tab_BW_2_RTIP = (obj) => {
    return api_addNew_Tab_BW_2_RTIP(
        PLANT.ACTION_TYPES.ADD_NEW_TAB_BW_2_RTIP ,
        PLANT.TAB_NAME,
        obj
    )
}

export const plant_addNew_KeyVal2_and_PWReal = (keyValObj) => {
    return api_addNew_KeyVal2_and_PWReal(
        PLANT.ACTION_TYPES.ADD_NEW_KEYVALUE2_AND_INI_PWREAL_2_RTIP,
        PLANT.TAB_NAME,
        keyValObj
    )
}

export const plant_addNew_BW_KeyVal2 = (keyValObj) => {
    return api_addNew_BW_KeyVal2(
        PLANT.ACTION_TYPES.ADD_NEW_BW_KEYVALUE2_2_RTIP,
        PLANT.TAB_NAME,
        keyValObj
    )
}

export const plant_addNew_PW_2_RTIP_PW_ALLE = (obj) => {
    return api_addNew_PW_2_RTIP_PW_ALLE(
        PLANT.ACTION_TYPES.ADD_NEW_PW_TO_RTIP_PW_ALLE,
        PLANT.TAB_NAME,
        obj
    )
}

export const plant_addNew_BS_2_RTIP_BW_ALLE = (obj) => {
    return api_addNew_BS_2_RTIP_BW_ALLE(
        PLANT.ACTION_TYPES.ADD_NEW_BS_TO_RTIP_BW_ALLE,
        PLANT.TAB_NAME,
        obj
    )
}


export const plant_fetchNames = (listOfGUID, TargetName) => {
    return api_fetchNamesDic(
        PLANT.ACTION_TYPES.FETCH_NAMES_DICTIONARY,
        PLANT.TAB_NAME,
        listOfGUID,
        TargetName
    )
}


