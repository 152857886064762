import { stat } from 'fs';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Badge, Col, Container, Fade, Row } from 'reactstrap';
import { getSmallIcon, SMALL_ICON, TRANS } from '../../store/actions/general.actions';
import * as SummaryActions from '../../store/actions/targetactualsummary.actions';
import Sparkline from '../Common/Sparklines';
import ContentWrapper from '../Layout/ContentWrapper';
import './targetSummary.css';

class TargetActualSummary extends Component {

    state = {
        readyToUpdate: false,
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!prevState.readyToUpdate && this.state.readyToUpdate) {
            await this.props.getSummaryValues(this.props.bgaName, this.props.summary[0].guid);

            const { curr_ist_value, curr_soll_value,   gest_ist_value, gest_soll_value,   } = this.props['values_' + this.props.summary[0].guid];

            let curr_ptg = '--';
            let gest_ptg = '--';
            let curr_ptg_number = 0;
            let gest_ptg_number = 0;
            if (curr_ist_value !== 'NaN' && curr_soll_value !== 'NaN' && curr_soll_value !== '0') {
                curr_ptg_number = (parseFloat(curr_ist_value) * 100 / parseFloat(curr_soll_value));
                curr_ptg = curr_ptg_number + '';
                curr_ptg = this.trunc1Decimal(curr_ptg) + '%';
            }
            if (gest_ist_value !== 'NaN' && gest_soll_value !== 'NaN' && gest_soll_value !== '0') {
                gest_ptg_number = (parseFloat(gest_ist_value) * 100 / parseFloat(gest_soll_value));
                gest_ptg = gest_ptg_number + '';
                gest_ptg = this.trunc1Decimal(gest_ptg) + '%';
            }

            this.setState({
                values: {
                    ...this.props['values_' + this.props.summary[0].guid],
                    curr_ptg, gest_ptg, curr_ptg_number, gest_ptg_number,
                    curr_ist_value: this.trunc2Decimals(curr_ist_value),
                    curr_soll_value: this.trunc2Decimals(curr_soll_value),
                    gest_ist_value: this.trunc2Decimals(gest_ist_value),

                },
                readyToUpdate: false,
            })
        }

    }

    trunc2Decimals = val => {
        if (!val) return 'NaN';
        if (!val.includes('.') && !val.includes(',')) return val;
        var sep = '.';
        if (val.includes(','))
            sep = ',';

        let pos = val.indexOf(sep);
        if (val.length > pos + 3)
            val = val.substring(0, pos + 3);
        if (sep === ',')
            val = val.replace(',', '.');
        return val;
    }

    trunc1Decimal = val => {
        if (!val) return 'NaN';
        if (!val.includes('.')) return val;

        let pos = val.indexOf('.');
        if (val.length > pos + 2)
            val = val.substring(0, pos + 2);
        return val;
    }

    getPtgNumber = (value, ofValue) => {
        let res = NaN;
        if (value !== 'NaN' && ofValue !== 'NaN') {
            res = (parseFloat(value) * 100 / parseFloat(ofValue));
        }
        return res;
    }

    getPtg = (val, numOfDigits) => {
        if (val === 0) return '0%';
        if (!val) return 'NaN';
        console.log("getPtg 2", val);
        if (val === 'NaN' || val === NaN) return 'NaN';
        console.log("getPtg 3", val);
        if (numOfDigits === 1)
            return this.trunc1Decimal(val + '') + '%';
        console.log("getPtg 4", val);
        if (numOfDigits === 2)
            return this.trunc2Decimals(val + '') + '%';
    }

    async componentDidMount() {
        // Easy pie
        console.log("this.props.summary", this.props);

        if (!this.props.isSum) {
            console.log("this.isSum",);
            await this.props.getSummaryValues(this.props.bgaName, this.props.summary[0].guid);
            const { curr_ist_value, curr_soll_value, gest_ist_value, gest_soll_value, } = this.props['values_' + this.props.summary[0].guid];

            let curr_ptg_number = this.getPtgNumber(curr_ist_value, curr_soll_value);
            let curr_ptg = this.getPtg(curr_ptg_number, 1);

            let gest_ptg_number = this.getPtgNumber(gest_ist_value, gest_soll_value);
            let gest_ptg = this.getPtg(gest_ptg_number, 1);
            
            this.setState({
                values: {
                    ...this.props['values_' + this.props.summary[0].guid],
                    curr_ptg, gest_ptg, curr_ptg_number, gest_ptg_number,
                    curr_ist_value: this.trunc2Decimals(this.props['values_' + this.props.summary[0].guid].curr_ist_value),
                    curr_soll_value: this.trunc2Decimals(this.props['values_' + this.props.summary[0].guid].curr_soll_value),
                    gest_ist_value: this.trunc2Decimals(this.props['values_' + this.props.summary[0].guid].gest_ist_value),
                }
            })
        }
        else { //if(isSum)
            console.log("this.isNotSum",);
            var summList = [];

            for (var i = 0; i < this.props.summary.length; i++) {
                await this.props.getSummaryValues(this.props.bgaName, this.props.summary[i].guid);
                //console.log('------ded', this.props['values_' + this.props.summary[i].guid]);
                summList = [...summList, this.props['values_' + this.props.summary[i].guid]];
            }

            if (summList && summList.length > 0 && !summList.includes(undefined)) {
                console.log('summList', summList);
                var temp = {
                    curr_ist_unit: summList && summList[0] ? summList[0].curr_ist_unit : "NaN",
                    curr_ist_value: summList ? Object.values(summList).reduce((total, { curr_ist_value }) => total + parseFloat(curr_ist_value), 0) : "NaN",
                    curr_soll_unit: summList && summList[0] ? summList[0].curr_soll_unit : "NaN",
                    curr_soll_value: summList ? Object.values(summList).reduce((total, { curr_soll_value }) => total + parseFloat(curr_soll_value), 0) : "NaN",
                    gest_ist_unit: summList && summList[0] ? summList[0].gest_ist_unit : "NaN",
                    gest_ist_value: summList ? Object.values(summList).reduce((total, { gest_ist_value }) => total + parseFloat(gest_ist_value), 0) : "NaN",
                    gest_soll_value: summList ? Object.values(summList).reduce((total, { gest_soll_value }) => total + parseFloat(gest_soll_value), 0) : "NaN",
                };

                let curr_ptg_number = this.getPtgNumber(temp.curr_ist_value, temp.curr_soll_value);
                let curr_ptg = this.getPtg(curr_ptg_number, 1);

                let gest_ptg_number = this.getPtgNumber(temp.gest_ist_value, temp.gest_soll_value);
                let gest_ptg = this.getPtg(gest_ptg_number, 1);

                temp = {
                    ...temp, curr_ptg, gest_ptg, curr_ptg_number, gest_ptg_number,
                    curr_ist_value: this.trunc2Decimals(temp.curr_ist_value + ''),
                    curr_soll_value: this.trunc2Decimals(temp.curr_soll_value + ''),
                    gest_ist_value: this.trunc2Decimals(temp.gest_ist_value + ''),
                }

                this.setState({
                    summList,
                    FinalValuesTotal: temp
                })

            }
            console.log('summList', summList);
        }
        //let pieOptions1 = {
        //    animate: {
        //        duration: 800,
        //        enabled: true
        //    },
        //    barColor: '#2b957a',
        //    trackColor: 'rgba(200,200,200,0.4)',
        //    scaleColor: false,
        //    lineWidth: 6,
        //    lineCap: 'round',
        //    size: 50
        //};
        //let pieOptions2 = {
        //    animate: {
        //        duration: 800,
        //        enabled: true
        //    },
        //    barColor: '#6c757d',
        //    trackColor: 'rgba(200,200,200,0.4)',
        //    scaleColor: false,
        //    lineWidth: 6,
        //    lineCap: 'round',
        //    size: 50
        //};
        //new EasyPieChart(this.refs.easypie, pieOptions1);
        //new EasyPieChart(this.refs.easypie1, pieOptions2);

    }


    updateSummary = () => {
        this.setState({ readyToUpdate : true})
    }

    getFormattedDate = (date,withTime) => {
        var d = new Date(date);
        var res = '';
        res = (d.getDate() < 10 ? '0' + d.getDate() : d.getDate()) + '.' +
            (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '.' +
            d.getFullYear();
        if (withTime) {
            res += ' ' + (d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) + ':' +
                (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) + ':' +
                (d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds());
        }
        return res;
    }


    //className="wd-s d-none d-lg-table-cell"
    render() {

        let tipText = !this.props.isSum && this.props.summary && this.props.summary[0] && this.props.summary[0].statringDate ? this.props.t(TRANS.TARGETACTUALSUMMARY.SINCE) + this.props.summary[0].statringDate.substring(0, 10) : "";
        console.log('Heelloo!!', tipText);
        if ((!this.props.isSum && this.state.values) || (this.props.isSum && this.state.FinalValuesTotal)) {
            const { fixedTargetUnit, name, componentType, } = this.props.summary[0];
            const { curr_ptg, curr_ist_value, curr_soll_value, curr_ptg_number, curr_ist_unit, curr_soll_unit, curr_ist_date, curr_soll_date
                , gest_ptg, gest_ptg_number, gest_ist_date, gest_soll_value, gest_ist_unit, gest_ist_value } =
                this.props.isSum ? this.state.FinalValuesTotal : this.state.values;

            return (
                <Fade in={true} style={{ width: '100%', height: '100%' }}>
                    <div className="targetSummary" style={!this.props.isSum ? { borderColor: "#2b957a" } : {}}>
                        <div className="SumControl m-1">
                            <div onClick={() => { this.props.closeSummaryFunction() }} className="mr-2" style={{ display: 'inline' }}>
                                <i className="fas fa-times" title={this.props.t(TRANS.TARGETACTUALSUMMARY.HIDE)} style={{ cursor: 'pointer' }}></i>
                            </div>
                            <div onClick={() => { this.updateSummary() }} className="mx-2" style={{ display: 'inline' }}>
                                <i className="fas fa-redo" title={this.props.t(TRANS.TARGETACTUALSUMMARY.REFRESH)} style={{ cursor: 'pointer' }}></i>
                            </div>
                        </div>
                        <div className="SumName m-1">
                            <em className="fas fa-chart-line fa-fw"></em>
                            <span>{' '+name}</span>
                        </div>
                        <div className="SumSmalTxt m-1">
                            <p className="text-muted">
                                {
                                    !this.props.isSum &&
                                    <span className="text-dark">{'  (' + componentType.name + ')'}</span>
                                }
                                {
                                    this.props.isSum &&
                                    <span className="text-dark">{this.props.t(TRANS.TARGETACTUALSUMMARY.TOTAL_OF) + (this.state.summList.length === 1 ? this.props.t(TRANS.TARGETACTUALSUMMARY.ONE)+'BHKW' : this.state.summList.length + ' BHKW') + ' '}</span>
                                }
                            </p>
                        </div>
                        <div className="SumUpdate m-1">
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize: '10px' }} className='text-muted' >{this.props.t(TRANS.TARGETACTUALSUMMARY.LASTUPDATE) + ' ' + this.getFormattedDate(new Date(), true)}</span>
                        </div>
                        <div className="SumVal1Name">
                            <div className="d-flex" style={{ height: '100%', alignItems: 'center' }}>
                                <div style={{ color: 'gray', fontWeight: '900', display: 'inline', cursor: 'pointer', }} title={this.props.t(TRANS.TARGETACTUALSUMMARY.POWER_TIP)}>
                                    {this.props.t(TRANS.TARGETACTUALSUMMARY.POWER)}
                                </div>
                                <div className="mx-auto">
                                    <span style={{ fontFamily: 'Gill Sans, sans-serif', fontWeight: '900', fontStyle: 'oblique',  }} >
                                    {curr_ptg}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="SumVal2Name">
                            <div className="d-flex" style={{ height: '100%', alignItems: 'center' }}>
                                <div className="text-center align-middle" style={{ color: 'gray', fontWeight: '900', display: 'inline', cursor: 'pointer', }} title={this.props.t(TRANS.TARGETACTUALSUMMARY.ENERGY_TIP)}>
                                    {this.props.t(TRANS.TARGETACTUALSUMMARY.ENERGY)}
                                </div>
                                <div className="mx-auto">
                                    <span style={{ fontFamily: 'Gill Sans, sans-serif', fontWeight: '900', fontStyle: 'oblique', }}  >
                                    {gest_ptg}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="SumVal1 m-1">
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize: '1vw' }} className="text-dark"><Badge color="success" style={{ fontSize: '1vw', cursor:'pointer' }} title={this.props.isSum ? "" : curr_ist_date}>{curr_ist_value}</Badge></span>
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize:'1vw' }} className="text-muted">{' ' + curr_ist_unit}</span>
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize: '1vw' }}> {' / '}</span>
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize: '1vw' }} className="text-dark"> <Badge color="success" style={{ fontSize: '1vw', cursor: 'pointer' }} title={this.props.isSum ? "" : curr_soll_date}>{curr_soll_value}</Badge></span>
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize:'1vw' }} className="text-muted">{' ' + curr_soll_unit}</span>
                        </div>
                        <div className="SumVal2 m-1">
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize: '1vw' }} className="text-dark"><Badge color="secondary" style={{ fontSize: '1vw', cursor: 'pointer'}} title={this.props.isSum ? "" : gest_ist_date}>{gest_ist_value}</Badge></span>
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize:'1vw' }} className="text-muted">{' ' + gest_ist_unit}</span>
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize: '1vw' }}> {' / '}</span>
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize: '1vw' }} className="text-dark"><Badge color="secondary" style={{ fontSize: '1vw', cursor: 'pointer' }} title={tipText}> {gest_soll_value}</Badge></span>
                            <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize: '1vw' }} className="text-muted">{fixedTargetUnit ? ' ' + fixedTargetUnit : ''}</span>
                        </div>
                    </div>
                </Fade>
            );
        }
        else
            return <p> {this.props.t(TRANS.ACTIONS.LOADING)}</p>;
    }
}
function mapStateToProps(state, ownProps) {
    if (ownProps.summary && ownProps.summary.length > 1) {
        let res = {}
        ownProps.summary.map((elem, ind) => {
            res = { ...res, ['values_' + elem.guid]: state.mainContent.mainContent && state.mainContent.mainContent[elem.guid],}
        });
        return res;
    }
    if (ownProps.summary && ownProps.summary.length === 1 )
        return {
            ['values_' + ownProps.summary[0].guid]: state.mainContent.mainContent && state.mainContent.mainContent[ownProps.summary[0].guid],
            //CurrentOH: state.mainContent.mainContent && state.mainContent.mainContent.currentOH,
            //DP2Display: state.mainContent.mainContent && state.mainContent.mainContent.dP2Display,
            
        }
    else
        return {
        }
}

function mapActionToProps(dispatch) {
    return {
        getSummaryValues: (bgaName, SummaryGUID) => dispatch(SummaryActions.summary_getValuesOfSummary(bgaName, SummaryGUID, SummaryGUID)),
        //calcCurrentOperatingHours: (bgaName, rtipNummer, startingDate) => dispatch(DataPointActions.datapoint_getCurrentOperatingHours(bgaName, rtipNummer, startingDate, 'currentOH')),
        //getDatapointsToDisplay: (bgaName, pointList) => dispatch(DataPointActions.datapoint_getListofDisplayedPWs_withTarget(bgaName, pointList, 'dP2Display'))
        

    }
}

export default connect(mapStateToProps, mapActionToProps)(TargetActualSummary);


//<div className="card card-default" style={!this.props.isSum ? { borderColor: '#2b957a' } : {}} >
//    <div className="card-body p-0">
//        <ContentWrapper style={{ paddingBottom: '0px !important' }} className="py-0 !important">
//            <Row style={{ marginBottom: '15px', }} >
//                <Col xl={3} style={{ display: 'table', margin: 'auto', }}>
//                    <div className="text-center align-middle" style={{ fontSize: '1.2vw', color: 'gray', fontWeight: '900' }}>
//                        {"Actual"}</div>
//                </Col>
//                <Col xl={3} style={{ display: 'table', margin: 'auto', }}>
//                    <span style={{ fontFamily: 'Gill Sans, sans-serif', fontWeight: '900', fontStyle: 'oblique', fontSize: '1.2vw' }} className="px-2">
//                        {curr_ptg}
//                    </span>
//                </Col>
//                <Col xl={6} style={{ display: 'table', margin: 'auto', }} >
//                    {/*<div className="progress" style={{ height: 'auto' }}>*/}
//                    {/*    <div role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style={{ width: curr_ptg_number + '%', height: '5px' }} className="progress-bar progress-bar-info progress-bar-striped bg-success">*/}
//                    {/*    </div>*/}

//                    {/*</div>*/}
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }} className="text-dark"><Badge color="success">{curr_ist_value}</Badge></span>
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }} className="text-muted">{' ' + curr_ist_unit}</span>
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }}> {' of '}</span>
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }} className="text-dark"> <Badge color="success">{curr_soll_value}</Badge></span>
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }} className="text-muted">{' ' + curr_soll_unit}</span>
//                    <br />
//                    {
//                        !this.props.isSum &&
//                        <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }} className="text-muted">{this.getFormattedDate(curr_ist_date, true)}</span>
//                    }
//                </Col>
//            </Row>
//            <Row>
//                <Col xl={3} style={{ display: 'table', margin: 'auto', }}>
//                    <div className="text-center align-middle" style={{ fontSize: '1.2vw', color: 'gray', fontWeight: '900' }}>{"Past"}</div>
//                </Col>
//                <Col xl={3} style={{ display: 'table', margin: 'auto', }}>
//                    <span style={{ fontFamily: 'Gill Sans, sans-serif', fontWeight: '900', fontStyle: 'oblique', fontSize: '1.2vw' }} className="px-2" >
//                        {gest_ptg}
//                    </span>
//                </Col>
//                <Col xl={6} style={{ display: 'table', margin: 'auto', }}>
//                    {/*<div className="progress " style={{ height: 'auto' }} >*/}
//                    {/*    <div role="progressbar" aria-valuenow={20} aria-valuemin="0" aria-valuemax="100" style={{ width: gest_ptg_number + "%", height: '5px' }} className="progress-bar progress-bar-info progress-bar-striped bg-secondary">*/}
//                    {/*    </div>*/}
//                    {/*</div>*/}
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }} className="text-dark"><Badge color="secondary">{gest_ist_value}</Badge></span>
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }} className="text-muted">{' ' + gest_ist_unit}</span>
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }}> {' of '}</span>
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }} className="text-dark"><Badge color="secondary"> {gest_soll_value}</Badge></span>
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }} className="text-muted">{' ' + fixedTargetUnit}</span>

//                    <br />
//                    {
//                        !this.props.isSum &&
//                        <span style={{ fontFamily: 'Source Sans Pro, sans-serif' }} className="text-muted">{this.getFormattedDate(gest_ist_date, false)}</span>
//                    }
//                </Col>
//            </Row>
//            <Row>
//                <Col>

//                </Col>
//                <Col md={'auto'}>
//                    <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontSize: '10px' }} className='text-muted' >{'Last Update: ' + this.getFormattedDate(new Date(), true)}</span>
//                </Col>
//            </Row>
//        </ContentWrapper>
//    </div>
//    <div className="card-footer">
//        <Container>
//            <Row>
//                <Col>
//                    <p className="text-muted">
//                        <em className="fas fa-chart-area fa-fw"></em>
//                        <span>{name}</span>
//                        {
//                            !this.props.isSum &&
//                            <span className="text-dark">{'  (' + componentType.name + ')'}</span>
//                        }
//                        {
//                            this.props.isSum &&
//                            <span className="text-dark">{'  (Total of ' + (this.state.summList.length === 1 ? 'one BHKW' : this.state.summList.length + ' BHKWs') + ')'}</span>
//                        }
//                    </p>
//                </Col>
//                <Col md='auto' >
//                    <div onClick={() => { this.updateSummary() }}>
//                        <i className="fas fa-redo" title="Update" style={{ cursor: 'pointer' }}></i>
//                    </div>
//                </Col>
//                <Col md='auto' >
//                    <div onClick={() => { this.props.closeSummaryFunction() }}>
//                        <i className="fas fa-times" title="Hide" style={{ cursor: 'pointer' }}></i>
//                    </div>
//                </Col>

//            </Row>
//        </Container>
//    </div>
//</div>