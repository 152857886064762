import * as React  from 'react';
import FaCard from '../Common/FaCard';
import { Fade } from 'reactstrap';
import { GENERAL, TRANS } from '../../store/actions/general.actions';
import Can from '../../store/identity/Can';
import { MEMBERSHIP_TYPE, PORTAL_ACTIONS } from '../../store/identity/identity';
import '../../styles/special/FirmaStyle.css';
import { FirmaRow_Props, FirmaRow_State } from '../PortalTypes/PortalTypes';

class FirmaRow extends React.Component<FirmaRow_Props, FirmaRow_State> {
    state = {}
    render() {
        const { guid, name, description, addressStreet, addressNumber, addressPzl, addressCity, addressLand, tabPlants } = this.props.firma;
        const { user, userCount, t } = this.props;

        return (
            <Fade in={true} timeout={500}>
                <div className="containerFirma">
                    <div className="LogoArea">
                        <div className="px-2" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                            {
                                guid &&
                                <a style={{ cursor: 'pointer' }} title={t(TRANS.ACTIONS.VIEW_DETAILS)} onClick={() => {
                                    this.props.onClick_View_Firma(guid, name)
                                }}>
                                    <FaCard iconName="far fa-building" />
                                </a>
                            }
                        </div>
                    </div>
                    <div className="NameArea">
                        <div className="px-2" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                            <h4 className="mb-2">{name}</h4>
                            <small className="text-muted">{description}</small><br />
                            <small>{addressStreet}, {addressNumber}</small><br />
                            <small>{addressPzl}, {addressCity}, {addressLand}</small>
                        </div>
                    </div>
                    <div className="RestArea">

                    </div>
                    <div className="SpecialArea plusesTop">
                        <div className="px-2">
                            <p className="m-0 text-muted" title={t(TRANS.PLANT.PLURAL)}>
                                {
                                    guid &&
                                    <a style={{ cursor: 'pointer' }} onClick={() => this.props.changeClickedFirma_onClickPlants(guid, name)}>
                                        <em className="fas fa-industry mr-2 fa-lg"></em></a>
                                }
                                {
                                    tabPlants &&
                                    GENERAL.PLANT.getPlantCount(tabPlants, user)
                                }
                            </p>
                        </div>
                    </div>
                    <div className="ControlArea plusesLeft">
                        <Can
                            type={MEMBERSHIP_TYPE.RIGHT}
                            permissionID={PORTAL_ACTIONS.DASH1.COM.DELETE}
                            plantID={null}
                            yes={() => (
                                <div className="px-2" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                    <p className="m-0 text-muted">
                                        {
                                            guid &&
                                            <a style={{ cursor: 'pointer' }} title={t(TRANS.ACTIONS.DELETE)} onClick={() => { if (window.confirm('\'' + name + '\' ' + this.props.t(TRANS.MESSAGES.DELETE_ASK_TO_CONFIRM))) this.props.onClick_Delete_Company(guid) }}>
                                                <em className="far fa-trash-alt mr-2 fa-lg"></em></a>
                                        }
                                    </p>
                                </div>)}
                        />
                    </div>
                </div>
            </Fade>
        );
    }
}

export default FirmaRow;
