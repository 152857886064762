


import React, { Component } from 'react';

import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAws } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare } from '@fortawesome/fontawesome-free-solid';
import { faAward, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import '../Common/FaCard.css';

fontawesome.library.add(faCheckSquare, faAward);


class FaCard extends Component {

    render() {
        //console.log("FaCard",this.props);
        if (!this.props.special) {
            return (
                <div className="d-flex align-items-center justify-content-center" title={this.props.title}>
                    <p className="m-0 ">
                        <em className={"fa-3x p-6 " + this.props.iconName}></em></p>
                </div>
            );
        } else {
            switch (this.props.special) {
                case SPECIALICON.USER_SUPERUSER:
                    return (
                        <div className="d-flex align-items-center justify-content-center" title={this.props.title}>
                            <p className="m-0 ">
                                <em className={"fa-3x p-6 fas fa-user-tie"}></em></p>
                        </div>
                    );
                case SPECIALICON.USER_SYSADMIN:
                    return (
                        <div className="d-flex align-items-center justify-content-center" title={this.props.title}>
                            <span className="fa-layers" style={{ width: '100%' }}>
                                <FontAwesomeIcon icon="user" className=" fa-3x" />
                                <FontAwesomeIcon icon="award" transform="shrink-1 down-12 left-12" inverse />
                            </span>

                        </div>
                    );
                case SPECIALICON.USER_BERATER:
                    return (
                        <div className="d-flex align-items-center justify-content-center" title={this.props.title}>

                            <span className="fa-layers" style={{ width: '100%' }}>
                                <FontAwesomeIcon icon="user" className=" fa-3x" />
                                <FontAwesomeIcon icon="shield-alt" transform="shrink-1 down-12 left-10" inverse />
                            </span>

                        </div>
                    );
                case SPECIALICON.USER_BERATER_CHECK:
                    return (
                        <div className="d-flex align-items-center justify-content-center" title={this.props.title}>

                            <span className="fa-layers" style={{ width: '100%' }}>
                                <FontAwesomeIcon icon="user" className=" fa-3x" />
                                <FontAwesomeIcon icon="shield-alt" transform="shrink-1 down-12 left-10" inverse />
                                <FontAwesomeIcon icon="check" transform="shrink-1 down-12 right-10" inverse />
                            </span>

                        </div>
                        
                    );
                case SPECIALICON.MP_PP_PERIODIC:
                    return (
                        <div className="d-flex align-items-center justify-content-center" title={this.props.title}>
                            <span className="fa-layers" style={{ width: '100%' }}>
                                <FontAwesomeIcon icon="stethoscope" className=" fa-3x" />
                                <FontAwesomeIcon icon="circle" className=" fa-2x" transform="down-10 left-10" color="white" />
                                <FontAwesomeIcon icon="sync" className=" fa-1x spinner" transform="shrink-1 down-20 left-20" />
                            </span>

                        </div>
                    ); 
                case SPECIALICON.MP_DV_PERIODIC:
                    return (
                        <div className="d-flex align-items-center justify-content-center" title={this.props.title}>
                            <span className="fa-layers" style={{ width: '100%' }}>
                                <FontAwesomeIcon icon="wrench" className=" fa-3x" />
                                <FontAwesomeIcon icon="circle" className=" fa-2x" transform="down-10 right-10" color="white" />
                                <FontAwesomeIcon icon="sync" className=" fa-1x spinner" transform="shrink-1 down-20 right-20" />
                            </span>

                        </div>
                    ); 
                case SPECIALICON.MP_PP_DONE:
                    return (
                        <div className="d-flex align-items-center justify-content-center" title={this.props.title}>
                            <span className="fa-layers" style={{ width: '100%' }}>
                                <FontAwesomeIcon icon="stethoscope" className=" fa-3x" />
                                <FontAwesomeIcon icon="circle" className=" fa-2x" transform="down-10 left-10" color="white" />
                                <FontAwesomeIcon icon="thumbs-up" className=" fa-1x" transform="shrink-1 down-20 left-20" />
                            </span>
                        </div>
                    ); 
                case SPECIALICON.MP_DV_DONE:
                    return (
                        <div className="d-flex align-items-center justify-content-center" title={this.props.title}>
                            <span className="fa-layers" style={{ width: '100%' }}>
                                <FontAwesomeIcon icon="wrench" className=" fa-3x" />
                                <FontAwesomeIcon icon="circle" className=" fa-2x" transform="down-10 right-10" color="white" />
                                <FontAwesomeIcon icon="thumbs-up" className=" fa-1x" transform="shrink-1 down-20 right-20" />
                            </span>

                        </div>
                    );
                case SPECIALICON.MP_PP_STOP:
                    return (
                        <div className="d-flex align-items-center justify-content-center" title={this.props.title}>
                            <span className="fa-layers" style={{ width: '100%' }}>
                                <FontAwesomeIcon icon="stethoscope" className=" fa-3x" />
                                <FontAwesomeIcon icon="circle" className=" fa-2x" transform="down-10 left-10" color="white" />
                                <FontAwesomeIcon icon="hand-paper" className=" fa-1x" transform="shrink-1 down-20 left-20" />
                            </span>
                        </div>
                    );
                case SPECIALICON.MP_DV_STOP:
                    return (
                        <div className="d-flex align-items-center justify-content-center" title={this.props.title}>
                            <span className="fa-layers" style={{ width: '100%' }}>
                                <FontAwesomeIcon icon="wrench" className=" fa-3x" />
                                <FontAwesomeIcon icon="circle" className=" fa-2x" transform="down-10 right-10" color="white" />
                                <FontAwesomeIcon icon="hand-paper" className=" fa-1x" transform="shrink-1 down-20 right-20" />
                            </span>
                        </div>
                        );
            }
            return null;
        }
    }
}

export default FaCard;


export const SPECIALICON = {
    USER_SUPERUSER: 'USER_SUPERUSER',
    USER_SYSADMIN: 'USER_SYSADMIN',
    USER_BERATER: 'USER_BERATER', 
    USER_BERATER_CHECK: 'USER_BERATER_CHECK', 

    MP_PP_PERIODIC: 'MP_PP_PERIODIC',
    MP_PP_DONE: 'MP_PP_DONE',
    MP_DV_PERIODIC: 'MP_DV_PERIODIC',
    MP_DV_DONE: 'MP_DV_DONE',

    MP_DV_STOP: 'MP_DV_STOP',
    MP_PP_STOP: 'MP_PP_STOP',
}


    //< div className = "d-flex align-items-center justify-content-center" title = { this.props.title } >

    //    <span className="fa-layers" style={{ width: '100%' }}>
    //        <FontAwesomeIcon icon="user-check" className=" fa-3x" />
    //    </span>

    //                    </div >




//<FontAwesomeIcon icon="circle" className=" fa-2x" color="red" transform="shrink-6 down-8" />

//<FontAwesomeIcon className={"fa-3x p-6 "} icon={faCoffee} />

//<div className="d-flex align-items-center justify-content-center">

//    <p className="m-0 ">
//        <div className="fa-3x p-6">
//            <span className="fa-layers fa-fw" style={{ background: 'MistyRose' }} >
//                <i className="fas fa-calendar"></i>
//                <span className="fa-layers-text fa-inverse" data-fa-transform="shrink-8 down-3" style={{ fontWeight: 800 }}>27</span>
//            </span>
//        </div>
//    </p>

//</div>

//<span className="fa-layers fa-fw"  >
//    <em className="fas fa-bookmark"></em>
//    <em className="fa-inverse fas fa-heart" data-fa-transform="shrink-10 up-2" style={{ color: 'Tomato' }}></em>
//</span>