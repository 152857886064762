import React, { Component } from 'react';
import { MEMBERSHIP_TYPE, PAGES_ACCESS_RULES, RIGHT_ACCESS_RULES } from './identity';
import jwtDecode from 'jwt-decode';
import { Redirect } from 'react-router';
import { USERGROUP, ROLE, PLANT, USER_TOKEN } from '../actions/general.actions';
import { PAGES } from '../../pages.website';


class Can extends Component {

    componentWillMount() {
        const jwt = localStorage.getItem(USER_TOKEN);
        if (jwt !== null) {
            const user = jwtDecode(jwt);
            //console.log("Can === ", user);
            this.setState({ user: user.user });
        } else {
            console.log(this.props);
        }
    }

    state = {}
    //props: 
    //      type: ROLE | RIGHT 
    //      user 
    //      current caller permissionID
    // the check if this user have a permession to access this caller 

    check = (type, plantID, targetID) => {
        //console.log("check 1", this.state.user.isSuperAdmin);
        if (type === MEMBERSHIP_TYPE.ACCESSALL)
            return true;

        // reda: you should add checking in "else" cases ... the problem is to enter plantID as input
        //console.log("check type", plantID);

        //CHECK ROLES
        if (type === MEMBERSHIP_TYPE.ROLE) {
            //super admin
            if (this.state.user.isSuperAdmin)
                return true;
            //system admin
            if (this.state.user.AdministratedFirmaId !== null) {
                console.log("PAGES_ACCESS_RULES", PAGES_ACCESS_RULES);
                if (PAGES_ACCESS_RULES[ROLE.IDs.SYSTEMADMIN_ROLE_ID].some(x => x === targetID))
                    return true;
                return false;
            }
            //berater 
            if (this.state.user.SpecialRole === USERGROUP.IDs.CONSULTANT_USERGROUP_ID) {
                //console.log("berater CAN", PAGES_ACCESS_RULES[ROLE.IDs.CONSULTANT_ROLE_ID].some(x => x === targetID));
                if (PAGES_ACCESS_RULES[ROLE.IDs.CONSULTANT_ROLE_ID].some(x => x === targetID))
                    return true;
                return false;
            }
            //initial case
            if (plantID === null) {
                if (Object.keys(this.state.user.Memberships).length === 1) {
                    var plant_id = Object.keys(this.state.user.Memberships)[0];
                    for (var i = 0; i < this.state.user.Memberships[plant_id].Roles.length; i++) {
                        if (PAGES_ACCESS_RULES[this.state.user.Memberships[plant_id].Roles[i]].some(x => x === targetID))
                            return true;
                    }
                }
                //should be checked in case of more than one normal UG 
                else if (Object.keys(this.state.user.Memberships).length > 1) {
                    var plant_id = Object.keys(this.state.user.Memberships)[0];
                    for (var i = 0; i < this.state.user.Memberships[plant_id].Roles.length; i++) {
                        if (PAGES_ACCESS_RULES[this.state.user.Memberships[plant_id].Roles[i]].some(x => x === targetID))
                            return true;
                    }
                }
                return false;
            }
                
            //else
            if (this.state.user && this.state.user.Memberships
                && this.state.user.Memberships[plantID] && this.state.user.Memberships[plantID].Roles) {
                //console.log("check 2");
                for (var i = 0; i < this.state.user.Memberships[plantID].Roles.length; i++) {
                    //console.log("check 3");
                    if (PAGES_ACCESS_RULES[this.state.user.Memberships[plantID].Roles[i]].some(x => x === targetID)) {
                        //console.log("check 4");
                        return true;
                    }
                }
            }
            //console.log("check 5");
            return false;
        }
        //CHECK RIGHTS
        else if (type === MEMBERSHIP_TYPE.RIGHT) {
            //console.log("check 5.5");
            //super admin
            if (this.state.user.isSuperAdmin)
                return true;
            //system admin
            if (this.state.user.AdministratedFirmaId && this.state.user.AdministratedFirmaId !== null)
                return RIGHT_ACCESS_RULES[USERGROUP.IDs.SYSTEMADMIN_USERGROUP_ID].some(x => x === targetID);
            //berater
            if (this.state.user.SpecialRole && this.state.user.SpecialRole !== null && this.state.user.SpecialRole === USERGROUP.IDs.CONSULTANT_USERGROUP_ID)
                return RIGHT_ACCESS_RULES[USERGROUP.IDs.CONSULTANT_USERGROUP_ID].some(x => x === targetID);
            //initial case
            
            if (plantID === null) {
                if (Object.keys(this.state.user.Memberships).length === 1) {
                    var plant_id = Object.keys(this.state.user.Memberships)[0];
                    return this.state.user.Memberships[plant_id].Rights.some(x => x === targetID)
                }
                //should be checked in case of more than one normal UG 
                if (Object.keys(this.state.user.Memberships).length > 1) {
                    var plant_id = Object.keys(this.state.user.Memberships)[0];
                    return this.state.user.Memberships[plant_id].Rights.some(x => x === targetID)
                }
                return false;
            }

            //other cases
            if (this.state.user && this.state.user.Memberships && this.state.user.Memberships[plantID] && this.state.user.Memberships[plantID].Rights)
                return this.state.user.Memberships[plantID].Rights.some(x => x === targetID);
            return false;
        }
        //console.log("check 6");
        //if (plantID === null)
        //    return true;
        return false;

    }

    render() {
        const { type, plantID, permissionID } = this.props;
        //console.log("this.state", this.state.user);
        if (this.state.user) {
            var res = this.check(type, plantID, permissionID);
            //console.log("check res", res);
            //console.log("check res type", type);
            //console.log("check res plantID", plantID);
            //console.log("check res permissionID", permissionID);
            if (type === MEMBERSHIP_TYPE.ROLE) {
                if (res)
                    return (this.props.yes());
                else
                    return (<Redirect to="/accessdenied" />);
            }
            else if (type === MEMBERSHIP_TYPE.RIGHT) {
                if (res)
                    return (this.props.yes());
                else
                    return null;
            }
        }
        return null;
        
    }

    
}

Can.defaultProps = {
    yes: () => null,
    no: () => null
}

export default Can;