import { IDENTITY } from "../actions/general.actions";
import jwtDecode from 'jwt-decode';

const initialState = null

const LOGIN_SUCC = IDENTITY.ACTIONS.LOGIN + IDENTITY.RESULTS.SUCCESSED;
const LOGIN_FAIL = IDENTITY.ACTIONS.LOGIN + IDENTITY.RESULTS.FAILED;

export const activeUser = (state = initialState, action) => {
    //switch (action.type) {
    //    case FIRMA_ACTION_TYPES.FEATCH_ALL:
    //        return {
    //            ...state,
    //            list: [...action.payload]
    //        }
    //    default:
    //        return state;
    //}

    switch (action.type) {
        case LOGIN_SUCC: {
            const { user } = jwtDecode(action.payload.token);
            console.log("action.payload", user);
            return {
                ...state,
                activeUser: action.payload.token,
                failedToLogin: 0
            }
        } 
        case LOGIN_FAIL: {
            return {
                ...state,
                activeUser: null,
                loginError: action.payload,
                failedToLogin: 1
            }
        }
        
    }
    return state;
}

export default activeUser;