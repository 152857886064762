/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.7.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
//import './styles/bootstrap.min.css';
import './styles/app.scss';
import './styles/bootstrap-multiselect.css';


class App extends Component {
    render() {

        // specify base href from env varible 'PUBLIC_URL'
        // use only if application isn't served from the root
        // for development it is forced to root only
        /* global PUBLIC_URL */
        const basename = process.env.NODE_ENV === 'development' ? '/' : (process.env.PUBLIC_URL || '/');
        //const basename = '/';
        //const basename = process.env.NODE_ENV === 'development' ? '/' : ('http://192.168.201.20:8081/NewPortal/' || '/');
        console.log("PUBBBBBBBBL", process.env.PUBLIC_URL);
        return (
                <BrowserRouter basename={basename}>
                    <Routes />
                </BrowserRouter>
        );

    }
}

export default App;
